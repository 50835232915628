// Configuration object for AWS Amplify
const awsmobile = {
  // Region where your AWS project is hosted
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,

  // AWS Cognito identity pool ID for authentication
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,

  // Region where your AWS Cognito is hosted
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,

  // AWS Cognito user pools ID
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,

  // Client ID for the user pool web client
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,

  // OAuth settings for authentication (currently empty)
  oauth: {},

  // AWS AppSync GraphQL API endpoint
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,

  // Region where your AWS AppSync is hosted
  aws_appsync_region: process.env.REACT_APP_AWS_APPSYNC_REGION,

  // Authentication type for AWS AppSync
  aws_appsync_authenticationType: process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE
};

export default awsmobile;

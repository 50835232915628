import React from 'react';
import '../css/extras.css';
import { useNavigate } from 'react-router-dom';
import { signOut } from "aws-amplify/auth";

const HeaderBar = () => {
    const navigate = useNavigate();

    const handleLogout = async() => {
        await signOut();
        console.log("Candidate logged out");
        navigate('/');
    }

    return(
    <div className='headerBar'>
        <span>GenieHire</span>
        <button onClick={handleLogout} id='headerLogoutBtn'>Logout</button>
    </div>
  );
};

export default HeaderBar;
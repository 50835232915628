// src/components/Header.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/newLandingPage.css';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../../aws-exports';

const Header = () => {
    const navigate = useNavigate();

    const handleAdminLogin = () => {
        navigate('/admin/login');
    };

    const handleBookFreeTrial = () => {
        navigate('/demo');
    }

    return (
        <>

        <div className='LPheader'>
            <div className='headerMenu'>
                <ul>
                    <img src='/GenieHire-logo-white.png'onClick={() => navigate('/')} alt='logo'></img>
                    {/* <li onClick={() => navigate('/products')}>Products</li> */}
                    <li onClick={() => navigate('/features')}>Features</li>
                    <li onClick={() => navigate('/pricing')}>Pricing</li>
                    <li onClick={() => navigate('/support')}>Support</li>
                </ul>
            </div>
            <div className='headerBtns'>
                <button id='bookFreeTrialBtn' onClick={handleBookFreeTrial}>Book Free Trial</button>
                <button id='LPloginBtn' onClick={handleAdminLogin}>Login</button>
            </div>
        </div>

        </>
    );
};

export default Header;

import React, { useState,useEffect } from 'react';
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; 
import host from '../../global';

const Header = ({ isSidebarOpen, setIsSidebarOpen ,hr_id}) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isProfilePopupVisible, setIsProfilePopupVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [notification, setNotification] = useState([]);
  const navigate = useNavigate();
  const [HRData,setHRData] = useState([])

  useEffect(() => {
    const fetchBulkResumeUploadResult  = async() =>{
      try {
        const response = await axios.get(`${host}/${hr_id}/bulkuploadresult`);
        setNotification(response.data.results);
      } catch (error) {
        console.error('Error fetching pending accounts', error);
      }
    }
    
    const fetchProfileData = async() =>{
      try {
        const response = await axios.get(`${host}/gethrdetails/${hr_id}`);
        setHRData(response.data);
      } catch (error) {
        console.error('Error fetching HR Data', error);
      }
  }

  fetchProfileData();
  fetchBulkResumeUploadResult();
  },[hr_id]);

  const handleNotificationBellClick = async (event) => {
    if(isProfilePopupVisible){
      setIsProfilePopupVisible(false)
    }
    const { top, left, height } = event.currentTarget.getBoundingClientRect();
    setPopupPosition({ top: top + height, left: left });
    setIsPopupVisible(!isPopupVisible);
  };

  const handlenotificationClick = (upload_id) => {
    navigate(`/hr/${hr_id}/BulkResume/Bulkresumemodel/${upload_id}`);
  };

  const handleProfileClick = async () =>{
    if(isPopupVisible){
      setIsPopupVisible(false)
    }
    setIsProfilePopupVisible(!isProfilePopupVisible);
  }

  return (
    <>
      <div className='header'>
        <div className='leftheaderchild'>
          <div className='menu-bar' onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            ☰
          </div>
          {/* <h1>GenieHire</h1> */}
          <img src='/GenieHire-logo-black.png' alt='Notification' style={{position: 'absolute',height:'40px',marginLeft:'40px'}}/>
        </div>
        <div className='rightheaderchild'>
          <button 
            className='notification-btn' 
            onClick={handleNotificationBellClick}
          >
            <img 
              src='/notificationbell.png' 
              alt='Notification' 
              className='notification-icon' 
            />
          </button>
          {isPopupVisible && (
            <div className='notification-popup'>
              {notification.length > 0 ? (
        <ul>
          {notification.map(({ upload_id, job_role }, index) => (
            <li key={index} onClick={(event) => {
    handleNotificationBellClick(event);
    handlenotificationClick(upload_id);
}}>
              Bulk Resume Upload for {job_role} completed.
            </li>
          ))}
        </ul>
      ) : (
        <p>No New Notifications.</p>
      )}
            </div>
          )}
                    <img 
            src= {HRData.profile ||'/dummyprofile.png' }
            alt='Profile' 
            className='profile-icon' 
            style={{ height: '40px' , width:'40px', cursor: 'pointer',border:"solid 1px white"  }} 
            onClick={handleProfileClick}
          />
          {isProfilePopupVisible && (
        <div className="profile-popup">
          <h3>{HRData.name}</h3>
          <p>{HRData.email}</p>
          <button onClick={() => navigate(`/hr/${hr_id}/editprofile`)}>
            View Complete Profile
          </button>
        </div>
      )}
        </div>
      </div>
    </>
  );
};

export default Header;
import React, { useEffect, useState } from 'react';
import Header from './header'; 
import Sidebar from './sidebar';
import '../../css/CompanyAdmin.css';
import { Authenticator } from '@aws-amplify/ui-react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import host from '../../global';

const Analytics = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [totalCandidates, setTotalCandidates] = useState(null);
  const [mcqPassed, setMcqPassed] = useState(null);
  const [codingPassed, setCodingPassed] = useState(null);
  const [interviewPassed, setInterviewPassed] = useState(null);
  const {orgadminId} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  
    useEffect(() => {
      const checkSession = async () => {
        try {
          const session = await fetchAuthSession();
          const { accessToken } = session.tokens ?? {};
          const groups = accessToken.payload['cognito:groups'] ?? [];
          const { userId } = await getCurrentUser();
          
          if (groups.includes('Company_Admin') && userId === orgadminId) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        } catch (error) {
          setIsAuthenticated(false);
        } finally {
          setIsLoading(false);
        }
      };
      
      checkSession();
      }, []);

  useEffect(() => {
    const fetchTotalCandidates = async () => {
      try {
        console.log(orgadminId)
        const response = await axios.get(`${host}/admin/candidate-stats/${orgadminId}`);
        setTotalCandidates(response.data.total_candidates);
        setMcqPassed(response.data.mcq_passed);
        setCodingPassed(response.data.coding_passed);
        setInterviewPassed(response.data.interview_passed);
      } catch (error) {
        console.error('Error fetching total candidates:', error);
      }
    };

    fetchTotalCandidates();
  }, [orgadminId]);

  const handleViewReport = () =>{
    navigate(`/org-admin/${orgadminId}/checkreport`);
  }
  return (
    <>
    {isLoading ? (
      <div className='loader'></div>
    ): !isAuthenticated ?(
      <div className="AuthenticationMessageContainer">
        <div className="AuthenticationMessage">
      <p>
        You are not Authorized to access this Page!
      </p>
      <button onClick={() => navigate('/admin/login')}> Login Page</button>
      </div>
      </div>
    ):(
      <Authenticator>
        <div>
          <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} orgadminId={orgadminId}/>
          <div className="company-admin-container">
            <Sidebar isSidebarOpen={isSidebarOpen} orgadminId={orgadminId} />
            <div className={`content ${isSidebarOpen ? 'sidebarIsOpen' : ''}`}>
              <h1>Analytics</h1>
              <p>Welcome to the Analytics page!</p>
              <div className='charts'>
              <p>Total Candidates: </p>
              <p>Candidates who passed MCQ: </p>
              <p>Candidates who passed Coding: </p>
              <p>Candidates who passed Interview: </p>
              </div>
              <div className=' charts'>
              <div className="hr-profiles-circle">
                {totalCandidates !== null ? totalCandidates : 'Loading...'}
              </div>
              
              <div className="hr-profiles-circle">
                {mcqPassed !== null ? mcqPassed : 'Loading...'}
              </div>
              
              <div className="hr-profiles-circle">
                {codingPassed !== null ? codingPassed : 'Loading...'}
              </div>
              
              <div className="hr-profiles-circle">
                {interviewPassed !== null ? interviewPassed : 'Loading...'}
              </div>
              </div>
              <button onClick={handleViewReport} className="addhr">View Reports</button>
            </div>
          </div>
        </div>
      </Authenticator>
        )};
        </>
  );
};

export default Analytics;
import React from 'react';

function UserDetails({ interviewId, candidateName, contact, totalScore, mcq_score, percentInterviewScore, numberOfQuestion, finalRemark, mcq_status, coding_status, interview_status }) {
  const slice1 = {
    height: `${100 - mcq_score}%`
  };
  const slice2 = {
    height: `${mcq_score}%`
  };
  const slice3 = {
    height: `${100 - 50}%`
  };
  const slice4 = {
    height: `${50}%`
  };
  const slice5 = {   
    height: `${100 - parseInt(percentInterviewScore)}%`
  };
  const slice6 = {
    height: `${parseInt(percentInterviewScore)}%`
  };
  
  return (
    <div className="interview-details">
      <div className='candi-details'>
        <div className="detail-item">
          <span className="label">Interview ID:</span> <span className="value">{interviewId}</span>
        </div>
        <div className="detail-item">
          <span className="label">Candidate Name:</span> <span className="value">{candidateName}</span>
        </div>
        <div className="detail-item">
          <span className="label">Candidate Contact:</span> <span className="value">{contact}</span>
        </div>
      </div>

      <div className='smart-results'>

        <div className='allScores'>
          
          {/* <div id='mcqscore' className='score'>
            {(mcq_status === "Complete" || mcq_status === "Passed") ? <span>{mcq_score}%</span> : <span className='hide'>0</span>}
          </div> */}
          
          
          {/* <div id='codingscore' className='score'>
            {(coding_status === "Complete") ? <span>50%</span> : <span className='hide'>0</span>}
          </div> */}
          
          
          {/* <div id='interviewscore' className='score'>
            {(interview_status === "Complete") ? <span>{percentInterviewScore? percentInterviewScore+"%": "NA"} </span> : <span className='hide'>0</span>}
            </div> */}
          
        </div>

          <div className='score-card'>
          {(mcq_status === "Complete" || mcq_status === "Passed") &&
          <>
            {/* <div className='graph'> */}
              {/* <div style={slice1}></div> */}
              <div style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'28px', borderRadius:'50%', height:'100px',width:'100px',border:'solid 1px white'}}>
            {(mcq_status === "Complete" || mcq_status === "Passed") ? <span>{mcq_score}%</span> : <span className='hide'>0</span>}
          </div>
              {/* <div style={slice2} className='greenSlice'></div> */}
            {/* </div> */}
            <p>MCQ</p>
            </>
          }
          </div>
        
          <div className='score-card'>
          {(coding_status === "Complete") &&
          <>
            {/* <div className='graph'> */}
              {/* <div style={slice3}></div> */}
              <div style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'28px', borderRadius:'50%', height:'100px',width:'100px',border:'solid 1px white'}}>
            {(coding_status === "Complete") ? <span>50%</span> : <span className='hide'>0</span>}
          </div>
              {/* <div style={slice4} className='greenSlice'></div> */}
            {/* </div> */}
            <p>Coding</p>
            </>
          }
          </div>

          <div className='score-card'>
          {(interview_status === "Complete") &&
          <>
            {/* <div className='graph'> */}

              {/* <div style={slice5}></div> */}
              <div style={{display:'flex',justifyContent:'center',alignItems:'center',fontSize:'28px', borderRadius:'50%', height:'100px',width:'100px',border:'solid 1px white'}}>
            {(interview_status === "Complete") ? <span>{percentInterviewScore? percentInterviewScore+"%": "NA"} </span> : <span className='hide'>0</span>}
            </div>
              {/* <div style={slice6} className='greenSlice'></div> */}
            {/* </div> */}
            <p>Interview</p>
            </>
          }
          </div>

      </div>
    </div>
  );
}

export default UserDetails;
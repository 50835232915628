// import React from 'react';
// import '../../css/newLandingPage.css';
// import { useNavigate } from 'react-router-dom';
// import Header from './Header';
// import Footer from './Footer';

// const FeaturesPage = () => {
//     const navigate = useNavigate();
//     return (
//         <div className='LP'>
//             <div className='LP-bkg' style={{ height: '70vh' }}></div>
//             <div className='Section1' style={{ height: '70vh' }}>
//             <Header/>
//                 <div className='welcomeText'>
//                     <h1>Our Features</h1>
//                     <p className='description'>Explore the cutting-edge features of GenieHire, designed to streamline your recruitment process.</p>
//                 </div>
//             </div>
//             <div className='Section2'>
//                 <h2>AI-Powered Resume Parsing</h2>
//                 <p>Our advanced AI technology automatically parses resumes, extracting key information to match candidates with job openings.</p>
//                 <hr />

//                 <h2>Automated Interview Scheduling</h2>
//                 <p>Seamlessly schedule interviews with candidates, avoiding conflicts and ensuring a smooth hiring process.</p>
//                 <hr />

//                 <h2>AI-Driven Candidate Evaluation</h2>
//                 <p>Use AI to assess candidate responses in real-time, providing you with valuable insights and recommendations.</p>
//                 <hr />
//             </div>
//             <Footer/>
//         </div>
//     );
// };

// export default FeaturesPage;


import React from 'react';
import '../../css/newLandingPage.css';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

const FeaturesPage = () => {
    const navigate = useNavigate();
    return (
        <div className='LP'>
            <div className='LP-bkg' style={{ height: '70vh' }}></div>
            <div className='Section1' style={{ height: '70vh' }}>
                <Header/>
                <div className='welcomeText'>
                    <h1 className='pageTitle'>Our Features</h1>
                    <p className='description'>Explore the cutting-edge features of GenieHire, designed to streamline your recruitment process.</p>
                </div>
            </div>
            <div className='FeaturesContent'>
                <div className='FeatureCard'>
                    <h2 className='featureTitle'>AI-Powered Resume Parsing</h2>
                    <p className='featureDescription'>Our advanced AI technology automatically parses resumes, extracting key information to match candidates with job openings. This ensures a faster and more accurate recruitment process, saving you valuable time and effort.</p>
                </div>
                <div className='FeatureCard'>
                    <h2 className='featureTitle'>Automated Interview Scheduling</h2>
                    <p className='featureDescription'>Seamlessly schedule interviews with candidates through our automated system. It eliminates scheduling conflicts and provides a smooth, efficient experience for both recruiters and candidates.</p>
                </div>
                <div className='FeatureCard'>
                    <h2 className='featureTitle'>AI-Driven Candidate Evaluation</h2>
                    <p className='featureDescription'>Utilize our AI technology to evaluate candidate responses in real-time. Get detailed insights and recommendations that help you make informed hiring decisions with ease.</p>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default FeaturesPage;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../../css/CompanyAdmin.css'; // Ensure this CSS file is correct for your layout
import host from '../../global';
import { getCurrentUser } from 'aws-amplify/auth';
import Header from './header';
import Sidebar from './sidebar';
import { Authenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from 'aws-amplify/auth';

const CheckReport = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const {hr_id} = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [itemsPerPage] = useState(10);
  const [filters, setFilters] = useState({
    jobPosition: [],
    mcqStatus: [],
    codingStatus: [],
    interviewStatus: []
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [areCandidatesLoading, setAreCandidatesLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
useEffect(() => {
  const checkSession = async () => {
    try {
      const session = await fetchAuthSession();
      const { accessToken } = session.tokens ?? {};
      const groups = accessToken.payload['cognito:groups'] ?? [];
      const { userId } = await getCurrentUser();
      
      if (groups.includes('HR') && userId === hr_id) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  checkSession();
}, []);

  useEffect(() => {
    async function fetchData() {
      try {
        setAreCandidatesLoading(true);
        const { userId } = await getCurrentUser();
        const response = await axios.get(`${host}/get_candidate_data/${userId}`);
        setCandidates(response.data);
        setFilteredCandidates(response.data);
        setAreCandidatesLoading(false);
      } catch (error) {
        console.error('Error fetching candidate data:', error);
        if (error.message === 'UserUnAuthenticatedException') {
          navigate('/admin/login');
        }
      }
    }
    fetchData();
  }, [navigate]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => {
      const currentValues = prevFilters[name];
      if (currentValues.includes(value)) {
        // Remove value if already selected
        return { ...prevFilters, [name]: currentValues.filter(item => item !== value) };
      } else {
        // Add value if not selected
        return { ...prevFilters, [name]: [...currentValues, value] };
      }
    });
  };

  const applyFilters = (candidate) => {
    return (
      candidate.candidate_name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (filters.jobPosition.length === 0 || filters.jobPosition.includes(candidate.jdposition)) &&
      (filters.mcqStatus.length === 0 || filters.mcqStatus.includes(candidate.mcq_status)) &&
      (filters.codingStatus.length === 0 || filters.codingStatus.includes(candidate.coding_status)) &&
      (filters.interviewStatus.length === 0 || filters.interviewStatus.includes(candidate.interview_status))
    );
  };

  const paginatedCandidates = candidates
    .filter(applyFilters)
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = Math.ceil(candidates.filter(applyFilters).length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleViewCandidate = (interviewId) => {
    navigate(`/hr/evaluationpage/${hr_id}/${interviewId}`);
  };

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handleApplyFilters = () => {
    setCurrentPage(1);
    setIsPopupOpen(false);
  };

  const handleResetFilters = () => {
    setFilters({
      jobPosition: [],
      mcqStatus: [],
      codingStatus: [],
      interviewStatus: []
    });
    setSearchQuery('');
    setCurrentPage(1);
  };

  if (isLoading) {
    return <div className="loader"></div>;
  }

  if (!isAuthenticated) {
    return (
      <div className="AuthenticationMessageContainer">
        <div className="AuthenticationMessage">
      <p>
        You are not Authorized to access this Page!
      </p>
      <button onClick={() => navigate('/admin/login')}> Login Page</button>
      </div>
      </div>
    );
  }

  return (
    <Authenticator>
      <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} hr_id={hr_id}/>
      <div className="company-admin-container">
        <Sidebar isSidebarOpen={isSidebarOpen} hr_id={hr_id}/>
        <div className={`content ${isSidebarOpen ? 'sidebarIsOpen' : ''}`}>
          { areCandidatesLoading ? (
            <div className="loader"></div>
          ):(
            <>
          <h1>Check Report</h1>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search by candidate name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button className="filter-button" onClick={handlePopupOpen}>Filter</button>
          </div>

          {isPopupOpen && (
            <div className="filter-popup">
              <div className="filter-popup-content">
                <span className="close" onClick={handlePopupClose}>&times;</span>
                <div className="filter-group">
                  <h3>Filter by Job Position</h3>
                  {Array.from(new Set(candidates.map(c => c.jdposition))).map((position, index) => (
                    <label key={index}>
                      {position}
                      <input
                        type="checkbox"
                        name="jobPosition"
                        value={position}
                        checked={filters.jobPosition.includes(position)}
                        onChange={handleFilterChange}
                      />
                    </label>
                  ))}
                </div>
                <div className="filter-group">
                  <h3>Filter by MCQ Status</h3>
                  {['Complete', 'Passed', 'Failed'].map((status, index) => (
                    <label key={index}>
                      {status}
                      <input
                        type="checkbox"
                        name="mcqStatus"
                        value={status}
                        checked={filters.mcqStatus.includes(status)}
                        onChange={handleFilterChange}
                      />
                    </label>
                  ))}
                </div>
                <div className="filter-group">
                  <h3>Filter by Coding Status</h3>
                  {['Complete', 'Passed', 'Failed'].map((status, index) => (
                    <label key={index}>
                      {status}
                      <input
                        type="checkbox"
                        name="codingStatus"
                        value={status}
                        checked={filters.codingStatus.includes(status)}
                        onChange={handleFilterChange}
                      />
                    </label>
                  ))}
                </div>
                <div className="filter-group">
                  <h3>Filter by Interview Status</h3>
                  {['Complete', 'Passed', 'Failed'].map((status, index) => (
                    <label key={index}>
                      {status}
                      <input
                        type="checkbox"
                        name="interviewStatus"
                        value={status}
                        checked={filters.interviewStatus.includes(status)}
                        onChange={handleFilterChange}
                      />
                    </label>
                  ))}
                </div>
                <div className="filterBtns">
                  <button className="reset-filters-button" onClick={handleResetFilters}>Reset</button>
                  <button className="apply-filters-button" onClick={handleApplyFilters}>Apply</button>
                </div>
              </div>
            </div>
          )}

          <div className="candidate-list">
            {paginatedCandidates.map((candidate) => (
              <div key={candidate.interview_id} className="candidate-card">
                <div className="candidate-header">
                  <h3>{candidate.candidate_name}</h3>
                  {(candidate.mcq_status === 'Complete' || candidate.mcq_status === 'Passed' ||
                    candidate.coding_status === 'Passed' || candidate.coding_status === 'Complete' ||
                    candidate.interview_status === 'Complete' || candidate.interview_status === 'Passed') && (
                    <button
                      className="view-button"
                      onClick={() => handleViewCandidate(candidate.interview_id)}
                    >
                      View Report
                    </button>
                  )}
                </div>
                <div className="candidate-details">
                  <p><strong>Job Position:</strong> {candidate.jdposition}</p>
                  <p><strong>HR:</strong> {candidate.hrname}</p>
                  {(candidate.mcq_status !== "NA") && <p><strong>MCQ Status:</strong> {candidate.mcq_status}</p>}
                  {(candidate.coding_status !== "NA") && <p><strong>Coding Status:</strong> {candidate.coding_status}</p>}
                  <p><strong>Interview Status:</strong> {candidate.interview_status}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="pagination-controls">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              Previous
            </button>
            <span>Page {currentPage} of {totalPages}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next
            </button>
          </div>
          </>
          )}
        </div>
      </div>
    </Authenticator>
  );
};

export default CheckReport;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from './header'; 
import Sidebar from './sidebar';
import '../../css/CompanyAdmin.css';
import { Authenticator } from '@aws-amplify/ui-react';
import '../../css/createMCQTest.css';
import axios from 'axios';
import host from '../../global.js';
import CustomALert from '../customalter.js';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

const CreateMCQSet = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { orgadminId } = useParams();
  const [setsData, setSetsData] = useState({});
  const [selectedSet, setSelectedSet] = useState('');
  const [newSetName, setNewSetName] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [editFormData, setEditFormData] = useState({});
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    question: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    answer: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
      const checkSession = async () => {
        try {
          const session = await fetchAuthSession();
          const { accessToken } = session.tokens ?? {};
          const groups = accessToken.payload['cognito:groups'] ?? [];
          const { userId } = await getCurrentUser();
          
          if (groups.includes('Company_Admin') && userId === orgadminId) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        } catch (error) {
          setIsAuthenticated(false);
        } finally {
          setIsLoading(false);
        }
      };
      
      checkSession();
      }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${host}/displayallquestions/${orgadminId}`);
        setSetsData(response.data.sets["0"] || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [orgadminId]);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleEditFormChange = (event) => {
    const { name, value } = event.target;
    setEditFormData({
      ...editFormData,
      [name]: value
    });
  };

  const handleSetQuestion = async (e) => {
    e.preventDefault();
    const { question, option1, option2, option3, option4, answer } = formData;

    if (!question || !option1 || !option2 || !option3 || !option4 || !answer) {
      setAlertMessage("All fields must be filled.");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 1000);
      return;
    }

    if (new Set([option1, option2, option3, option4]).size !== 4) {
      setAlertMessage("All four options must be different.");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 1000);
      return;
    }

    if (![option1, option2, option3, option4].includes(answer)) {
      setAlertMessage("Correct answer must match one of the options.");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 1000);
      return;
    }

    const setName = selectedSet || newSetName;

    if (!setName) {
      setAlertMessage("You must select or create a set.");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 1000);
      return;
    }

    if (newSetName && Object.keys(setsData).includes(newSetName)) {
      setAlertMessage("A set with this name already exists.");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 1000);
      return;
    }

    console.log("orgadminId : ",orgadminId);
    
    try {
      await axios.post(`${host}/add_mcq_to_set/${orgadminId}/${setName}`, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setAlertMessage("Question Inserted");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 1000);
      setFormData({
        question: '',
        option1: '',
        option2: '',
        option3: '',
        option4: '',
        answer: ''
      });
      setNewSetName('');
      const response = await axios.get(`${host}/displayallquestions/${orgadminId}`);
      setSetsData(response.data.sets["0"] || {});
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const handleEditQuestion = (setName, index) => {
    setEditIndex({ setName, index });
    setEditFormData(setsData[setName][index]);
  };

  const handleSaveQuestion = async (setName, index) => {
    const { question, option1, option2, option3, option4, answer } = editFormData;
    if (!question || !option1 || !option2 || !option3 || !option4 || !answer) {
      setAlertMessage("All fields must be filled.");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 1000);
      return;
    }

    if (new Set([option1, option2, option3, option4]).size !== 4) {
      setAlertMessage("All four options must be different.");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 1000);
      return;
    }

    if (![option1, option2, option3, option4].includes(answer)) {
      setAlertMessage("Correct answer must match one of the options.");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 1000);
      return;
    }

    try {
      await axios.put(`${host}/editmcq/${orgadminId}/${setName}/${index}`, editFormData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setAlertMessage("Question Updated Successfully!");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 1000);
      setEditIndex(null);
      const response = await axios.get(`${host}/displayallquestions/${orgadminId}`);
      setSetsData(response.data.sets["0"] || {});
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const handleDeleteQuestion = async (setName, index) => {
    try {
      await axios.delete(`${host}/deletemcq/${orgadminId}/${setName}/${index}`);
      setAlertMessage("Question Deleted Successfully!");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 1000);
      const response = await axios.get(`${host}/displayallquestions/${orgadminId}`);
      setSetsData(response.data.sets["0"] || {});
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  if (isLoading) {
    <div className="loader"></div>
  }

  if (!isAuthenticated) {
    return (
      <div className="AuthenticationMessageContainer">
        <div className="AuthenticationMessage">
      <p>
        You are not Authorized to access this Page!
      </p>
      <button onClick={() => navigate('/admin/login')}> Login Page</button>
      </div>
      </div>
    );
  }

  return (
    <>
    {alertVisible && <CustomALert message={alertMessage} />}
      <Authenticator>
        <div>
          <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} orgadminId={orgadminId}/>
          <div className="company-admin-container">
            <Sidebar isSidebarOpen={isSidebarOpen} orgadminId={orgadminId}/>
            <div className={`${isSidebarOpen ? 'sidebarIsOpen' : ''}`}>
            <div className='CreateMCQTestPage'>
      <div className='createMCQ'>
        <h2>Add new question</h2>
        <form>
        <div className='selectSet'>
        <label htmlFor="setSelect">Select a Set:</label>
        <select
          id="setSelect"
          value={selectedSet}
          onChange={(e) => setSelectedSet(e.target.value)}
        >
          <option value="">Create New Set</option>
          {Object.keys(setsData).map((setName) => (
            <option key={setName} value={setName}>
              SET {setName}
            </option>
          ))}
        </select>
        <label htmlFor="newSetName">New Set Name:</label>
        <input
          id="newSetName"
          name="newSetName"
          placeholder="Enter New Set Name"
          value={newSetName}
          onChange={(e) => setNewSetName(e.target.value)}
          disabled={!!selectedSet}
        />
      </div>
          <input name='question' placeholder='Enter Question' value={formData.question} onChange={handleFormChange} />
          <input name='option1' placeholder='Enter Option 1' value={formData.option1} onChange={handleFormChange} />
          <input name='option2' placeholder='Enter Option 2' value={formData.option2} onChange={handleFormChange} />
          <input name='option3' placeholder='Enter Option 3' value={formData.option3} onChange={handleFormChange} />
          <input name='option4' placeholder='Enter Option 4' value={formData.option4} onChange={handleFormChange} />
          <input name='answer' placeholder='Enter Answer' value={formData.answer} onChange={handleFormChange} />
          <button onClick={handleSetQuestion}>Set Question</button>
        </form>
      </div>
      {selectedSet && (
      <div className='displayQuestions'>
        {selectedSet && setsData[selectedSet] && (
          <div className='questionsContainer'>
            <h3>SET {selectedSet} QUESTIONS </h3>
            {Object.keys(setsData[selectedSet]).map((key) => (
              <div key={key} className="displayedQuestions">
                {editIndex && editIndex.setName === selectedSet && editIndex.index === Number(key) ? (
                  <>
                    <input name='question' placeholder='Enter Question' value={editFormData.question} onChange={handleEditFormChange} />
                    <input name='option1' placeholder='Enter Option 1' value={editFormData.option1} onChange={handleEditFormChange} />
                    <input name='option2' placeholder='Enter Option 2' value={editFormData.option2} onChange={handleEditFormChange} />
                    <input name='option3' placeholder='Enter Option 3' value={editFormData.option3} onChange={handleEditFormChange} />
                    <input name='option4' placeholder='Enter Option 4' value={editFormData.option4} onChange={handleEditFormChange} />
                    <input name='answer' placeholder='Enter Answer' value={editFormData.answer} onChange={handleEditFormChange} />
                    <button onClick={() => handleSaveQuestion(selectedSet, Number(key))}>Save</button>
                  </>
                ) : (
                  <>
                    <p className='displayedQuestion'>Question {Number(key)}: <pre>{setsData[selectedSet][key].question}</pre></p>
                    <ul>
                      <li>{setsData[selectedSet][key].option1}</li>
                      <li>{setsData[selectedSet][key].option2}</li>
                      <li>{setsData[selectedSet][key].option3}</li>
                      <li>{setsData[selectedSet][key].option4}</li>
                    </ul>
                    <p>Correct Answer: {setsData[selectedSet][key].answer}</p>
                    <button className='editBtn' onClick={() => handleEditQuestion(selectedSet, Number(key))}>Edit</button>
                    <button className='deleteBtn' onClick={() => handleDeleteQuestion(selectedSet, Number(key))}>Delete</button>
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      )}
            </div>
            </div>
          </div>
        </div>
      </Authenticator>
    </>
  );
};

export default CreateMCQSet;
import React, { useEffect, useState } from 'react';
import Header from './header'; 
import Sidebar from './sidebar';
import '../../css/CompanyAdmin.css';
import { Authenticator } from '@aws-amplify/ui-react';
import Modal from './Modal';
import axios from 'axios';
import host from '../../global';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import { useNavigate, useParams } from 'react-router-dom';

const CreatedJDs = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [jobDescriptions, setJobDescriptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const {orgadminId} = useParams();
  const [MCQTimeLimit, setMCQTimeLimit] = useState(0);
  const [CodingTimeLimit, setCodingTimeLimit] = useState(0);
  const [isMCQIncluded, setIsMCQIncluded] = useState(false);
  const [isCodingIncluded, setIsCodingIncluded] = useState(false);
  const [codingSets, setCodingSets] = useState([]);
  const [codingJudgingCriteria, setCodingJudgingCriteria] = useState([]);
  const [selectedCodingSet, setSelectedCodingSet] = useState("NA");
  const [selectedInterviewType, setSelectedInterviewType] = useState('');
  const [numberOfQuestion, setNumberOfQuestion] = useState(0);
  const [selectedSet, setSelectedSet] = useState("NA");
  const [sets, setSets] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showCreateJDForm, setShowCreateJDForm] = useState(false);
  const [detailedJD, setDetailedJD] = useState(null);
  const [showDetailedJD, setShowDetailedJD] = useState(false);
  const [jbrole, setJbrole] = useState('');
  const [jobdesc, setJobdesc] = useState('');
  const [isUpdatingJD, setIsUpdatingJD] = useState(false);
  const [PrevJbrole, setPrevJbrole] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

    useEffect(() => {
      const checkSession = async () => {
        setIsLoading(true);
        try {
          const session = await fetchAuthSession();
          const { accessToken } = session.tokens ?? {};
          const groups = accessToken.payload['cognito:groups'] ?? [];
          const { userId } = await getCurrentUser();
          
          if (groups.includes('Company_Admin') && userId === orgadminId) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        } catch (error) {
          setIsAuthenticated(false);
        } finally {
          setIsLoading(false);
        }
      };
      
      checkSession();
      }, []);

  useEffect(() => {
    setCodingJudgingCriteria("NA");
  }, []);

  const fetchJobDescriptions = async () => {
    try {
      const response = await fetch(`${host}/jobdescriptions/${orgadminId}`);
      const data = await response.json();
      setJobDescriptions(data);
    } catch (error) {
      console.error('Error fetching job descriptions:', error);
    }
  };

  useEffect(() => {
    const fetchSetNames = async () => {
      try {
        const response = await fetch(`${host}/get_all_set_names/${orgadminId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setSets(data.sets);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchSetNames();
  }, [orgadminId]);

  useEffect(() => {
    const fetchCodingSets = async () => {
      try {
        const response = await axios.get(`${host}/api/Codingsets/${orgadminId}`);
        setCodingSets(Object.keys(response.data));
      } catch (error) {
        console.error('Error fetching coding sets:', error);
      }
    };
    fetchCodingSets();
  }, [orgadminId]);
  
  useEffect(() => {
    fetchJobDescriptions();
  }, [orgadminId]);

  const handleJbdescChange = (e) => {
    setJobdesc(e.target.value);
  }
  
  const handleJbroleChange = (e) => {
    setJbrole(e.target.value);
  }

  const handleNumberChange = (e) => {
    setNumberOfQuestion(e.target.value);
  };

  const handleMCQTimeLimitChange = (e) => {
    setMCQTimeLimit(e.target.value);    
  }

  const handleCodingTimeLimitChange = (e) => {
    setCodingTimeLimit(e.target.value);
  }

  const handleInterviewTypeChange = (e) => {
    setSelectedInterviewType(e.target.value);
  };

  const handleSetChange = (e) => {
    setSelectedSet(e.target.value);
  };

  const handleCodingJudgingCriteriaChange = (e) => {
    setCodingJudgingCriteria(e.target.value);
  };

  const handleIncludeMCQ = () => {
    if(isMCQIncluded){
      setIsMCQIncluded(false);
      setSelectedSet("NA");
      setMCQTimeLimit(0);
    }
    else{
      setIsMCQIncluded(true);
    }
  }

  const handleIncludeCoding = () => {
    if(isCodingIncluded){
      setIsCodingIncluded(false);
      setSelectedCodingSet("NA");
      setCodingJudgingCriteria("NA");
      setCodingTimeLimit(0);
    }
    else{
      setIsCodingIncluded(true);
    }
  }

  const handleAddJD = () => {
    setJbrole('');
    setJobdesc('');
    setNumberOfQuestion(0);
    setSelectedInterviewType('NA');
    setIsMCQIncluded(false);
    setMCQTimeLimit(0); 
    setSelectedSet('NA'); 
    setIsCodingIncluded(false);
    setCodingTimeLimit(0);
    setCodingJudgingCriteria('NA');
    setSelectedCodingSet('NA');
    setIsUpdatingJD(false);
    setShowCreateJDForm(true);
  };
  
  const handleSubmitAddJD = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${host}/${orgadminId}/createjd/`, {
        jobrole: jbrole,
        description: jobdesc,
        selectedInterviewType: selectedInterviewType,
        numberOfQuestion: numberOfQuestion,
        selectedSet: selectedSet,
        MCQTimeLimit: MCQTimeLimit,
        selectedCodingSet: selectedCodingSet,
        CodingTimeLimit: CodingTimeLimit,
        codingJudgingCriteria: codingJudgingCriteria
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response) {
        setShowModal(false);
        alert("JD Added Successfully!");
        await fetchJobDescriptions(); 
      } else {
        console.error('Error adding job description:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const handleDeleteJD = async (e) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to delete this job description?')) {
      try {
        const response = await axios.delete(`${host}/deletejd/${orgadminId}/${PrevJbrole}`);
        if (response.status === 200) {
          alert("JD Deleted Successfully!");
          await fetchJobDescriptions();
          setShowCreateJDForm(false);
          setPrevJbrole('');
        } else {
          console.error('Error deleting job description:', response.statusText);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const closeCreateJD = () => {
    setShowCreateJDForm(false);
  }

  const viewDetailedJD = async(jobrole) => {
    setShowDetailedJD(true);
    try {
      const response = await axios.get(`${host}/${orgadminId}/getjd?job_position=${jobrole}`);
      if(response){
        setDetailedJD(response.data);
      }
    } catch (error) {
      console.error('Error fetching job description:', error);
    }
  }

  const hideDetailedJD = () => {
    setShowDetailedJD(false);
  }

  const handleEditJDRequest = async (jobrole) => {
    setIsUpdatingJD(true);
    try {
      const response = await axios.get(`${host}/${orgadminId}/getjd?job_position=${jobrole}`);
      setShowCreateJDForm(true);
      if(response){
        setPrevJbrole(response.data.jobrole);
        setJbrole(response.data.jobrole);
        setJobdesc(response.data.description);
        setNumberOfQuestion(response.data.number_of_questions);
        setSelectedInterviewType(response.data.interview_type);
        if(response.data.mcq_set !== "NA"){
          setIsMCQIncluded(true);
          setMCQTimeLimit(response.data.mcq_time_limit); 
          setSelectedSet(response.data.mcq_set); 
        }
        else{
          setIsMCQIncluded(false);
        }
        if(response.data.Coding_set !== "NA"){
          setIsCodingIncluded(true);
          setCodingTimeLimit(response.data.coding_time_limit);
          setCodingJudgingCriteria(response.data.Coding_Judge_Crieteria);
          setSelectedCodingSet(response.data.Coding_set);
        } 
        else{
          setIsCodingIncluded(false);
        }
      }
    } catch (error) {
      console.error('Error fetching job description:', error);
    }
  }

  const updateJDRequest = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${host}/${orgadminId}/updatejd/${PrevJbrole}`, {
        jobrole: jbrole,
        description: jobdesc,
        selectedInterviewType: selectedInterviewType,
        numberOfQuestion: numberOfQuestion,
        selectedSet: selectedSet,
        MCQTimeLimit: MCQTimeLimit,
        selectedCodingSet: selectedCodingSet,
        CodingTimeLimit: CodingTimeLimit,
        codingJudgingCriteria: codingJudgingCriteria
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response) {
        setShowModal(false);
        alert("JD Updated Successfully!");
        setShowCreateJDForm(false);
        setPrevJbrole('');
        await fetchJobDescriptions(); 
      } else {
        console.error('Error updating job description:', response.statusText);
      }      
      
    } catch (error) {
      console.error('Error:', error);
    }
  }

  if (isLoading) {
    <div className="loader"></div>
  }

  if (!isAuthenticated) {
    return (
      <div className="AuthenticationMessageContainer">
        <div className="AuthenticationMessage">
      <p>
        You are not Authorized to access this Page!
      </p>
      <button onClick={() => navigate('/admin/login')}> Login Page</button>
      </div>
      </div>
    );
  }
  return (
    <>
      <Authenticator>
        {showCreateJDForm &&
        <div className='CreateJDFormBlock'>
          <form className='CreateJDForm'>
            <div className='JDSection1'>
              <label>Job Title: </label>
              <input type="text" name="jobrole" value={jbrole} onChange={handleJbroleChange} required />
              <label>Job Description: </label>
              <textarea name="description" value={jobdesc} onChange={handleJbdescChange}required ></textarea>
            </div>

            <div className='JDSection2'>
              <div className='selectInterviewType'>
                <label>Type of Interview: </label>
                <select value={selectedInterviewType} onChange={handleInterviewTypeChange} required>
                  <option value="">Select Type Of Interview</option>
                  <option value="Technical Interview">Technical Interview</option>
                  <option value="Behavioral Interview">Behavioral Interview</option>
                </select>
              </div>
              <div className='setNumberOfQuestions'>
                <label>Interview Time Limit (Mins):</label>
                <input placeholder='0' type='number' min="1" value={numberOfQuestion} onChange={handleNumberChange} required />
              </div>

              <hr></hr>

              <div className="checkbox-container">
                <input
                  type="checkbox"
                  checked={isMCQIncluded}
                  onChange={handleIncludeMCQ}
                  />
                <label id='checkboxLabel'>Include MCQ Test</label>
              </div>
            
              {isMCQIncluded && 
              <>
              <div className='JDmcqTest'>
                <div className='JDselectSetForMCQ'>
                  <label>Select Set For MCQ Test:</label>
                  <select id="JDsetsSelect" value={selectedSet} onChange={handleSetChange} required>
                    <option>Select a set</option>
                    {sets.map((setName, index) => (
                      <option key={index}>{setName}</option>
                    ))}
                  </select>
                </div>
                <div className='JDMCQTimeLimit'>
                  <label>Set MCQ Time Limit (Mins):</label>
                  <input  type='number' value={(MCQTimeLimit)} onChange={handleMCQTimeLimitChange} required ></input>
                </div>
              </div>
              <hr></hr>
              </> 
              }

              <div className="checkbox-container">
                <input
                  type="checkbox"
                  checked={isCodingIncluded}
                  onChange={handleIncludeCoding}
                />
                <label id='checkboxLabel'>Include Coding Test</label>
              </div>
              {isCodingIncluded &&
              <>
                <div className='JDmcqTest'>
                  <div className='JDselectSetForMCQ'>
                    <label>Select Coding Set:</label>
                    <select id="JDsetsSelect" value={selectedCodingSet} onChange={(e) => setSelectedCodingSet(e.target.value)} required>
                      <option value="">Select Set</option>
                        {codingSets.map((setName, index) => (
                          <option key={index} value={setName}>{setName}</option>
                        ))}
                    </select>
                  </div>
                  <div className='JDMCQTimeLimit'>
                    <label>Set Coding Time Limit (Mins):</label>
                    <input placeholder='0' min="1" type='number' value={CodingTimeLimit} onChange={handleCodingTimeLimitChange} required ></input>
                  </div>
                </div>
                <div className='selectCodingCriteriaAndSet'>
                  <div className='JDselectSetForMCQ'>
                    <label>Set Coding Judging Criteria:</label>
                    <select id="JDsetsSelect" value={codingJudgingCriteria} onChange={handleCodingJudgingCriteriaChange} required>
                      <option value="">Select Criteria</option>
                      <option value="0">Judge 0</option>
                      <option value="1">Judge 1</option>
                      <option value="2" disabled>Judge 2</option>
                      <option value="3" disabled>Judge 3</option>
                      <option value="4" disabled>Judge 4</option>
                      <option value="5" disabled>Master Judge</option>
                    </select>
                  </div>
                </div>
                <hr style={{marginTop: "25px"}}></hr>
              </>
              }

              <div className='Hide'></div>
              {isUpdatingJD ? 
              (<div className='CreateJDBtns'>
                <button id='createJDBtn' onClick={updateJDRequest}>Update JD</button>
                <button onClick={handleDeleteJD}>Delete JD</button>
                <button onClick={closeCreateJD}>Close</button>
              </div>) :
              (<div className='CreateJDBtns'>
                <button onClick={handleSubmitAddJD} id='createJDBtn'>Create JD</button>
                <button onClick={closeCreateJD}>Close</button>
              </div>)
              }
            </div>

          </form>
        </div>
        }

        {(showDetailedJD && detailedJD) &&
        <div className='detailedJDPage'>
          <div className='detailedJD'>
            <div className='detailedJDHeader'>
              <h1>Job Details</h1>
              <img src='/reject.png' alt='Logo' onClick={hideDetailedJD}></img>
            </div>
            <div className='detailedJDDetails'>
            <p><strong>Job Role: </strong>{detailedJD.jobrole}</p>
            <hr></hr>
            <p><strong>Description:</strong></p>
            <p><pre id='ViewJobDescription'>{detailedJD.description}</pre></p>
            <hr></hr>
            <p><strong>Date of Creation: </strong>{detailedJD.postingDate}</p>
            <hr></hr>
            <p><strong>Interview Type: </strong>{detailedJD.interview_type}</p>
            <hr></hr>
            <p><strong>Interview Time Duration: </strong>{detailedJD.number_of_questions}</p>
            <hr></hr>
            {(detailedJD.mcq_set !== "NA") &&
            <>
            <p><strong>MCQ Test Details:</strong></p>
            <p><strong>MCQ Test Set: </strong>{detailedJD.mcq_set}</p>
            <p><strong>MCQ Time Limit: </strong>{detailedJD.mcq_time_limit} Minutes</p>
            <hr></hr>
            </>}
            {(detailedJD.Coding_set !== "NA") &&
            <>
            <p><strong>Coding Test Details:</strong></p>
            <p><strong>Coding Set: </strong>{detailedJD.Coding_set}</p>
            <p><strong>Coding Time Limit: </strong>{detailedJD.coding_time_limit}</p>
            <p><strong>Coding Judging Criteria: </strong>Judge {detailedJD.Coding_Judge_Crieteria}</p>
            </>}
            </div>
          </div>
        </div>
        }
        
        <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} orgadminId={orgadminId}/>
          <div className="company-admin-container">
            
            <Sidebar isSidebarOpen={isSidebarOpen} orgadminId={orgadminId}/>
            <div className={`content ${isSidebarOpen ? 'sidebarIsOpen' : ''}`}>
              <h1>Created Job Descriptions</h1>
              <button className='addjd' onClick={handleAddJD}>Add New JD</button>
              {jobDescriptions.length > 0 ? (
                <div className="job-description-cards">
                  <table className='ViewCreatedJDsTable'>
                    <tr>
                      <th>Job Role</th>
                      <th>Interview Type</th>
                      <th>Interview Duration</th>
                      <th>Tests Included</th>
                      <th>Created Date</th>
                      <th>Action</th>
                    </tr>
                    {jobDescriptions.map((jd) => (
                    <tr key={jd.jdid}>
                        <td id='jobRoleRow' onClick={() => viewDetailedJD(jd.jobrole)} ><strong>{jd.jobrole}</strong></td>
                        <td>{jd.interview_type}</td>
                        <td>{jd.number_of_questions} Minutes</td>
                        <td id='includedTests'>
                          <div>Interview</div>
                          <div>{(jd.mcq_set === "NA") ? <span></span> : <span>MCQ</span>}</div>
                          <div>{(jd.Coding_set === "NA") ? <span></span> : <span>Coding</span>}</div>
                        </td>
                        <td>{jd.postingDate}</td>
                        <td id='EditJD'><strong onClick={() => handleEditJDRequest(jd.jobrole)}>Edit</strong></td>
                    </tr>
                    ))}
                  </table>
                </div>
              ) : (
                <div className='loader'></div>
              )}
            </div>
          </div>

        <Modal show={showModal} onClose={() => setShowModal(false)} title={modalTitle}>
          {modalContent}
        </Modal>
      </Authenticator>
    </>
  );
};

export default CreatedJDs;

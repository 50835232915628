import React, { useEffect, useState } from 'react';
import Header from './header'; 
import Sidebar from './sidebar';
import '../../css/CompanyAdmin.css';
import { Authenticator } from '@aws-amplify/ui-react';
import Modal from './Modal';
import host from '../../global';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import { useNavigate, useParams } from 'react-router-dom';

const HrProfile = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [hrProfiles, setHrProfiles] = useState([]);
  const {orgadminId} = useParams();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  
    useEffect(() => {
      const checkSession = async () => {
        try {
          const session = await fetchAuthSession();
          const { accessToken } = session.tokens ?? {};
          const groups = accessToken.payload['cognito:groups'] ?? [];
          const { userId } = await getCurrentUser();
          
          if (groups.includes('Company_Admin') && userId === orgadminId) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        } catch (error) {
          setIsAuthenticated(false);
        } finally {
          setIsLoading(false);
        }
      };
      
      checkSession();
      }, []);

  const fetchHrProfiles = async () => {
    try {
      const response = await fetch(`${host}/hrprofiles/${orgadminId}`);
      const data = await response.json();
      console.log(data)
      setHrProfiles(data);
    } catch (error) {
      console.error('Error fetching HR profiles:', error);
    }
  };

  useEffect(() => {
    fetchHrProfiles();
  }, [orgadminId]);

  const handleAddHR = () => {
    setModalTitle('Add HR');
    setModalContent(
      <form onSubmit={handleAddHRSubmit}>
        <label>
          Name:
          <input type="text" name="name" required />
        </label>
        <label>
          Email:
          <input type="email" name="email" required />
        </label>
        <label>
          Contact Number:
          <input type="text" name="contactNumber" required />
        </label>
        <label>
          Department:
          <input type="text" name="department" required />
        </label>
        <button type="submit">Add HR</button>
      </form>
    );
    setShowModal(true);
  };

  const handleEditHR = (hrProfile) => {
    setModalTitle('Edit HR');
    setModalContent(
      <form onSubmit={(event) => handleEditHRSubmit(event, hrProfile.hrId)}>
        <label>
          Name:
          <input type="text" name="name" defaultValue={hrProfile.hrName} required />
        </label>
        <label>
          Email:
          <input type="email" name="email" defaultValue={hrProfile.hremail} required />
        </label>
        <label>
          Contact Number:
          <input type="text" name="contactNumber" defaultValue={hrProfile.contactNumber} required />
        </label>
        <label>
          Department:
          <input type="text" name="department" defaultValue={hrProfile.HRDepartment} required />
        </label>
        <button type="submit">Save Changes</button>
      </form>
    );
    setShowModal(true);
  };

  const handleAddHRSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const newHR = {
      hrName: formData.get('name'),
      hremail: formData.get('email'),
      contactNumber: formData.get('contactNumber'),
      HRDepartment: formData.get('department')
    };
    try {
      const response = await fetch(`${host}/${orgadminId}/addhrprofile`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newHR)
      });
      const result = await response.json();
      console.log('HR added:', result);
      setShowModal(false);
      await fetchHrProfiles();
    } catch (error) {
      console.error('Error adding HR profile:', error);
    }
  };

  const handleEditHRSubmit = async (event, hrId) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const updatedHR = {
      hrName: formData.get('name'),
      hremail: formData.get('email'),
      contactNumber: formData.get('contactNumber'),
      HRDepartment: formData.get('department')
    };
    try {
      const response = await fetch(`${host}/${orgadminId}/updatehrprofile/${hrId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedHR)
      });
      const result = await response.json();
      alert('HR Profile Updated Successfully');
      setShowModal(false);
      await fetchHrProfiles();
    } catch (error) {
      console.error('Error updating HR profile:', error);
    }
  };

  return (
    <>
    {isLoading ? (
      <div className='loader'></div>
    ): !isAuthenticated ?(
      <div className="AuthenticationMessageContainer">
        <div className="AuthenticationMessage">
      <p>
        You are not Authorized to access this Page!
      </p>
      <button onClick={() => navigate('/admin/login')}> Login Page</button>
      </div>
      </div>
    ):(
    <Authenticator>
      <div>
        <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} orgadminId={orgadminId}/>
        <div className="company-admin-container">
          <Sidebar isSidebarOpen={isSidebarOpen} orgadminId={orgadminId}/>
          <div className={`content ${isSidebarOpen ? 'sidebarIsOpen' : ''}`}>
            <h1>HR Profiles</h1>
            <div className="hr-list">
              {hrProfiles.length > 0 ? (
                hrProfiles.map((hr) => (
                  <div key={hr.hrId} className="hr-profile">
                    <h4>{hr.hrName}</h4>
                    <p><strong>Email:</strong> {hr.hremail}</p>
                    <p><strong>Contact Number:</strong> {hr.contactNumber}</p>
                    <p><strong>Department:</strong> {hr.HRDepartment}</p>
                    <button className="edithr" onClick={() => handleEditHR(hr)}>Edit HR</button>
                  </div>
                ))
              ) : (
                <p>No HR profiles found.</p>
              )}
            </div>
            <button className="addhr" onClick={handleAddHR}>Add HR</button>
          </div>
        </div>
        <Modal show={showModal} onClose={() => setShowModal(false)} title={modalTitle}>
          {modalContent}
        </Modal>
      </div>
    </Authenticator>
  )};
  </>
  );
};

export default HrProfile;
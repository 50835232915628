import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../css/sidebar.css'; 
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
const Sidebar = ({ isSidebarOpen,orgadminId }) => {
  const [isActionsDropdownOpen, setIsActionsDropdownOpen] = useState(false);
  const [isMCQDropdownOpen, setIsMCQDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleActionsDropdown = () => {
    console.log(orgadminId)
    setIsActionsDropdownOpen(!isActionsDropdownOpen);
  };

  const toggleMCQDropdown = (event) => {
    event.stopPropagation();
    setIsMCQDropdownOpen(!isMCQDropdownOpen);
  };

  const handleSignout = async () => {
    try {
      sessionStorage.removeItem('orgadminId');
      await signOut();
      navigate('/admin/login');
    } catch (error) {
      console.error('Error signing out', error);
      alert('Error signing out');
    }
  };

  return (
    <div className={`sidebar-container ${isSidebarOpen ? 'open' : 'closed'}`}>
      {isSidebarOpen && (
        <nav className="sidebar">
          <ul>
            <li><Link to={`/org-admin/${orgadminId}`}><img src={'/dashboard.png'} alt="Dashboard Icon" className="sidebar-icon" />Dashboard</Link></li>
            <li><Link to={`/org-admin/${orgadminId}/hrprofiles`}><img src={'/hrprofiles.png'} alt="Profiles Icon" className="sidebar-icon" />HR Profiles</Link></li>
            <li><Link to={`/org-admin/${orgadminId}/analytics`}><img src={'/analytics.png'} alt="Analytics Icon" className="sidebar-icon" />Analytics</Link></li>
            <li onClick={toggleActionsDropdown} className={`dropdown ${isActionsDropdownOpen ? 'open' : ''}`}> 
            <div className="actions-header">
              <img src={'/actions.png'} alt="Actions Icon" className="sidebar-icon" />
              Actions
            </div>
              {isActionsDropdownOpen && (
                <ul className="dropdown-menu">
                  <li><Link to={`/org-admin/${orgadminId}/checkresume`}>Resume Parser</Link></li>
                  <li><Link to={`/org-admin/${orgadminId}/created-jds`}>View Created JDs</Link></li>
                  <li className={`dropdown ${isMCQDropdownOpen ? 'open' : ''}`} onClick={toggleMCQDropdown}>
                    Create MCQ Set
                    {isMCQDropdownOpen && (
                      <ul className="mcqdropdown-menu">
                        <li><Link to={`/org-admin/${orgadminId}/fetchmcq`}>- Get from GenieHire</Link></li>
                        <li><Link to={`/org-admin/${orgadminId}/createmcqset`}>- Create Own Set</Link></li>
                      </ul>
                    )}
                  </li>
                  <li><Link to={`/org-admin/${orgadminId}/createcodingproblemset`}>Create Coding Problem Set</Link></li>
                </ul>
              )}
            </li>
            <li className='signout' onClick={handleSignout}><img src={'/signout.png'} alt="Signout Icon" className="sidebar-icon" />Sign Out</li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default Sidebar;
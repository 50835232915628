import React from 'react';

const Toolbar = ({ problems, interview_id, onProblemClick }) => {
    return (
        <div style={{
            width: '50px',
            background: '#333',
            color: 'white',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100vh',
            position: 'fixed',
            top: '0',
            left: '0',
            marginTop: "45px",
            borderRight: '1px solid #ccc'
        }}>
            {problems.map((problem, index) => (
                <div
                    key={problem.id}
                    style={{
                        cursor: 'pointer',
                        marginBottom: '1rem',
                        padding: '0.5rem',
                        borderRadius: '4px',
                        background: '#555',
                        textAlign: 'center'
                    }}
                    onClick={() => onProblemClick(problem.id)}
                >
                    {index + 1}
                </div>
            ))}
        </div>
    );
};

export default Toolbar;
import React from 'react';
import Sidebar from './sidebar';
import '../../css/CompanyAdmin.css'
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';

const Dashboard = ({ data,hr_id }) => {
  const navigate = useNavigate();
const [isLoading, setIsLoading] = useState(true);
const [isAuthenticated, setIsAuthenticated] = useState(false);
useEffect(() => {
  const checkSession = async () => {
    try {
      const session = await fetchAuthSession();
      const { accessToken } = session.tokens ?? {};
      const groups = accessToken.payload['cognito:groups'] ?? [];
      const { userId } = await getCurrentUser();
      
      if (groups.includes('HR') && userId === hr_id) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };
  
  checkSession();
  }, []);
  if (!data) {
    return <p>No Data Found!</p>;
  }

  if (isLoading) {
    <div className="loader"></div>
  }

  if (!isAuthenticated) {
    return (
      <div className="AuthenticationMessageContainer">
        <div className="AuthenticationMessage">
      <p>
        You are not Authorized to access this Page!
      </p>
      <button onClick={() => navigate('/admin/login')}> Login Page</button>
      </div>
      </div>
    );
  }
  
  return (
    <div>
      <Sidebar />
      {isLoading ? (
        <div className="loader"></div>
      ):(
        <>
        <h1>Welcome, {data.name}!</h1>
        <h2>Organization: {data.organizationName}</h2>
        <p>Total Number of Candidate Tested</p>
        <div className="hr-profiles-circle">
          {data.total_candidate_count}
        </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;

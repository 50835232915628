import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../../css/newMCQTest.css';
import host from '../../global.js';
import CustomALert from '../../components/customalter.js';
// import { requestFullscreen } from '../utils/fullScreen.js';

const MCQTestPage = () => {
  const navigate = useNavigate();
  const { hr_id, interview_id } = useParams();

  const timeLeftKey = `timeLeft_${interview_id}_mcq`;
  const initialTimeLeft = parseInt( localStorage.getItem(timeLeftKey));
  const [timeLeft, setTimeLeft] = useState(initialTimeLeft);
  const startDateTime = (localStorage.getItem(`${interview_id}_mcq_startDateTime`) || new Date().toUTCString());
  localStorage.setItem(`${interview_id}_mcq_startDateTime`, startDateTime);
  const [testData, setTestData] = useState(null);
  const [userAnswers, setUserAnswers] = useState(JSON.parse(localStorage.getItem('userAnswers')) || {});
  const [resultId, setResultId] = useState('');
  const [tabSwitchCount, setTabSwitchCount] = useState(0);
  const [stopTest, setStopTest] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [attempted, setattempted] = useState(Object.keys(userAnswers).length || 0);
  const audio = new Audio('/tab-switch-sound.mp3');
  const [wasTabVisible, setWasTabVisible] = useState(true); 
  const entryAudio = new Audio('/switch_to_fullscreen.mp3'); 
  const fullscreenExitAudio = new Audio('/fullscreen-exit-sound.mp3');

  const [questionId, setQuestionNum] = useState(0);

  const[MCQTimeLimit, setMCQTimeLimit] = useState(0);

  const stopAllAudio = async() => {
    audio.pause();
    audio.currentTime = 0;
    fullscreenExitAudio.pause();
    fullscreenExitAudio.currentTime = 0;
    entryAudio.pause();
    entryAudio.currentTime = 0;
  };

  useEffect(() => {
    entryAudio.play()
      .then(() => {
        console.log('Entry audio playback started successfully.');
      })
      .catch(error => {
        console.error('Entry audio playback error:', error);
      });
    
    return () => {
      entryAudio.pause();
      entryAudio.currentTime = 0;
    };
  }, []);


  useEffect(() => {
    localStorage.setItem(timeLeftKey, timeLeft.toString());
  }, [userAnswers, timeLeft]);

  useEffect(() => { 
    const getCandidateStatus = async () => {
      try {
        const response = await axios.post(`${host}/checkcandidatestatus/${interview_id}`);
        console.log(response.data);
        setMCQTimeLimit(response.data.mcq_time_limit);
        if(response.data.mcq_status === "Complete"){
          setStopTest(true);
        }
      } catch (error) {
        console.error("Error: ", error);
      }
    }
    getCandidateStatus();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
        if(JSON.parse(localStorage.getItem("TestDataLoad"))){
            setTestData(JSON.parse(localStorage.getItem("TestDataLoad")));
            console.log("Previous Data Loaded");

            console.log(JSON.parse(localStorage.getItem('userAnswers')));
        }
        else{
            try {
                const resp = await axios.get(`${host}/get_mcq_set/${interview_id}`);
                console.log(resp.data);
                
                const response = await axios.post(`${host}/get_mcqs_by_indices/${hr_id}/${resp.data}`);

                setTestData(response.data);
                localStorage.setItem("TestDataLoad", JSON.stringify(response.data));
                console.log("New Data Loaded");
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };
    fetchData();
  }, [hr_id]);

  useEffect(() => {
    if (timeLeft <= 0) {
        handleresult();
      return;
    }
    const timer = setInterval(() => {
      setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  useEffect(() => {
//     // Function to request fullscreen
//     // const enterFullscreen = () => {
//     //   if (document.documentElement.requestFullscreen) {
//     //     document.documentElement.requestFullscreen();
//     //   } else if (document.documentElement.mozRequestFullScreen) { // Firefox
//     //     document.documentElement.mozRequestFullScreen();
//     //   } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
//     //     document.documentElement.webkitRequestFullscreen();
//     //   } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
//     //     document.documentElement.msRequestFullscreen();
//     //   }
//     // };

//     // // Request fullscreen when the page loads
//     // enterFullscreen();

    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Tab has become hidden
        setWasTabVisible(false);
        setTabSwitchCount(prevCount => prevCount + 1);
          playAudio();
      } else if (!wasTabVisible) {
        // Tab has become visible and it was previously hidden
        setWasTabVisible(true);
        // Do not play audio when returning to the tab
      }
    };
//     const handleFullscreenChange = () => {
//       if (!document.fullscreenElement) {
//         setTabSwitchCount(prevCount => prevCount + 1);
//         playFullscreenExitAudio();
//       }
//     };

    const playAudio = () => {
    stopAllAudio();
    audio.play()
        .then(() => console.log('Audio playback started successfully.'))
        .catch(error => console.error('Audio playback error:', error));
    };

    const playFullscreenExitAudio = () => {
    stopAllAudio();
    fullscreenExitAudio.play()
        .then(() => console.log('Fullscreen exit audio playback started successfully.'))
        .catch(error => console.error('Fullscreen exit audio playback error:', error));
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    // document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    //   document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    if (tabSwitchCount === 1) {
      alert('Warning: You have switched tabs or exited fullscreen. Please return to the test.');
    } else if (tabSwitchCount === 2) {
      alert('Warning: You have switched tabs or exited fullscreen again. Please return to the test.');
    } else if (tabSwitchCount === 3) {
      alert('You have switched tabs or exited fullscreen multiple times. Your test will be submitted.');
      stopAllAudio();
      handleresult();
    }
  }, [tabSwitchCount]);

  const handleOptionChange = (questionId, selectedOption) => {
    const ans = userAnswers[questionId];
    if(ans == undefined){
      setattempted(attempted+1);
    }
    setUserAnswers({
      ...userAnswers,
      [questionId]: selectedOption
    });
    
    if(userAnswers){
        localStorage.setItem('userAnswers', JSON.stringify(userAnswers));
    }
  };

  const handleresult = async () => {
    await stopAllAudio();
    localStorage.removeItem(timeLeftKey);

    const timeTaken = formatTime(MCQTimeLimit*60 - timeLeft);
    let totalScore = 0;
    const updatedData = {};
    Object.keys(testData.data).forEach(key => {
        const question = testData.data[key];
        const givenAnswer = userAnswers[key] || '';
        const isCorrect = question.answer === givenAnswer;
        const score = isCorrect ? 1 : 0;
        totalScore += score;
  
        updatedData[key] = {
            ...question,
            given_answer: givenAnswer,
            score: score
        };
    });

    const resultData = {
        interview_id:interview_id,
        hr_id: hr_id,
        data: updatedData,
        total_score: totalScore,
        time_taken: timeTaken,
        startDateTime : startDateTime
    };

    console.log(resultData);
    try {
        const response = await axios.post(`${host}/save_test_result`, resultData);
        setAlertMessage("Test submitted successfully!");
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 2000);
        setTimeout(() => {
          sendCompletionMail();
          handleNavigate();
      }, 2000);
        // try {
        //     const response2 = await axios.post(`${host}/save_mcqtest_result_id/${interview_id}/${response.data}`, resultData);
        // } catch (error) {
        //     console.error('Error: ', error);
        // }
    } catch (error) {
        console.error('Error saving test result: ', error);
        alert('Error Submitting Test');
    }
  };

  const sendCompletionMail = async () => {
    try {
      const resp = await axios.post(`${host}/gethremail/${interview_id}`);
      const hr_email = resp.data["hr_email"];
      const hr_id = resp.data["hr_id"];
      const response = await axios.post(`${host}/notifymcqtestcomplete/${hr_email}/${hr_id}/${interview_id}`);
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const handleNavigate = () => {
    navigate(`/user/${interview_id}/testhomepage`);
  };

  const handleQuestionNum = (i) => {
    setQuestionNum(parseInt(i));
  }

  const handleNextQuestion = (questionId) => {
    if(questionId < (Object.keys(testData.data).length)-1){
        setQuestionNum(questionId+1);
    }
  }

  const handlePreviousQuestion = (questionId) => {
    if(questionId >= 1){
        setQuestionNum(questionId-1);
    }
  }

  const handlClearQuestion = (questionId) => {
    if(userAnswers[questionId]){
        delete userAnswers[questionId]; 
        localStorage.setItem('userAnswers', JSON.stringify(userAnswers));
    }
  }

  return (
    <>
    {alertVisible && <CustomALert message={alertMessage} />}

    <div className="MCQTestPage">
      {stopTest &&
        <div className='stoptestpage'>
          <div id='stoptest'>
            <h2>Test For This Candidate ID Is Already Conducted</h2>
            <h3>Only One Attemp Is Allowed</h3>
            <h3>You Can Close This Window Now</h3>
          </div>
        </div>
      }

      <p id='timeleft'>
        Attempted: {attempted} &emsp; Unattempted: {25-attempted} &emsp;  Time Left: {formatTime(timeLeft)} <button onClick={handleresult}>Submit Test</button>
      </p>
      
      {/* <h1>MCQ Test Data</h1> */}
      <div className="MCQTestPageContent">
        <div className='questionPalete'>
        {testData ? (
          Object.keys(testData.data).map((qId) => {
            if((qId == questionId) && (userAnswers[qId])){
              return (
                <div key={qId} className='questionNum circledngreen'>
                    <p onClick={() => handleQuestionNum(qId)}>{Number(qId)+1}</p>
                </div>
              );
            }
            else if(qId == questionId){
              return (
                <div key={qId} className='questionNum circled'>
                    <p onClick={() => handleQuestionNum(qId)}>{Number(qId)+1}</p>
                </div>
              );
            }
            else if(userAnswers[qId]){
                  return (
                  <div key={qId} className='questionNum green'>
                      <p onClick={() => handleQuestionNum(qId)}>{Number(qId)+1}</p>
                  </div>
                  );                
            }
            else{
                return (
                    <div key={qId} className='questionNum'>
                        <p onClick={() => handleQuestionNum(qId)}>{Number(qId)+1}</p>
                    </div>
                );
            }
        })
        ) : (
          <p>Loading...</p>
        )}
        </div>
        <div className='questionPart'>
        {testData ? (
            <div key={questionId} className="MCQuestion">
                <div className='questionAndOptions'>
                    <h3>Question {Number(questionId)+1}: <pre>{testData.data[questionId].question}</pre></h3>
                    <form className="MCQuestionForm">
                        <div className='optionsSection1'>
                        <label>
                        <input
                            type="radio"
                            name={`question_${questionId}`}
                            value={testData.data[questionId].option1}
                            checked={userAnswers[questionId] == testData.data[questionId].option1}
                            onChange={() => handleOptionChange(questionId, testData.data[questionId].option1)}
                        />
                        {testData.data[questionId].option1}
                        </label>
                        <label>
                        <input
                            type="radio"
                            name={`question_${questionId}`}
                            value={testData.data[questionId].option2}
                            checked={userAnswers[questionId] == testData.data[questionId].option2}
                            onChange={() => handleOptionChange(questionId, testData.data[questionId].option2)}
                        />
                        {testData.data[questionId].option2}
                        </label>
                        </div>
                        <div className='optionsSection1'>
                        <label>
                        <input
                            type="radio"
                            name={`question_${questionId}`}
                            value={testData.data[questionId].option3}
                            checked={userAnswers[questionId] == testData.data[questionId].option3}
                            onChange={() => handleOptionChange(questionId, testData.data[questionId].option3)}
                        />
                        {testData.data[questionId].option3}
                        </label>
                        <label>
                        <input
                            type="radio"
                            name={`question_${questionId}`}
                            value={testData.data[questionId].option4}
                            checked={userAnswers[questionId] == testData.data[questionId].option4}
                            onChange={() => handleOptionChange(questionId, testData.data[questionId].option4)}
                        />
                        {testData.data[questionId].option4}
                        </label>
                        </div>
                    </form>
                </div>
                <div className='MCQBtns'>
                    <button onClick={() => handlePreviousQuestion(questionId)}>Previous</button>
                    <button onClick={() => handlClearQuestion(questionId)}>Clear</button>
                    <button onClick={() => handleNextQuestion(questionId)}>Next</button>
                </div>
            </div>
        ) : (
          <p>Loading...</p>
        )}
        </div>
    </div>
    </div>
    </>
  );
};

export default MCQTestPage;
import React, {useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../../css/testHomePage.css';
import axios from 'axios';
import host from '../../global.js';
import CustomALert from '../../components/customalter.js';

const TestHomePage = () => {
  const navigate = useNavigate();
  const { interview_id } = useParams();
  const {mcqTestID, setMcqTestId} = useState('');
  const [adminId, setAdminId] = useState('');
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    const getMCQTestID = async () => {
      try {
        const response = await axios.post(`${host}/gethrid/${interview_id}`);
        setAdminId(response.data);
      } catch (error) {
        console.error("Error Getting admin ID: ", error);
      }
    }
    getMCQTestID();
  }, []);

  const [MCQTestVisible, setMCQTestVisible] = useState(false);
  const [CodingTestVisible, setCodingTestVisible] = useState(false);

  useEffect(() => {
    const checkStatus = async () => {
      try{
        const response = await axios.post(`${host}/checkcandidatestatus/${interview_id}`);
        if(response){
          if(response.data.mcq_status != "NA"){
            setMCQTestVisible(true);
          }
          if(response.data.coding_status != "NA"){
            setCodingTestVisible(true);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    checkStatus();
  }, []);
 
  const navigateMCQtest = async () => {
    try{
      const response = await axios.post(`${host}/checkcandidatestatus/${interview_id}`);
      
      if(response.data.mcq_status === "Incomplete"){
        localStorage.setItem(`timeLeft_${interview_id}_mcq`, response.data.mcq_time_limit*60);
        setTimeout(() => {
          navigate(`/user/${adminId}/mcqtest/${interview_id}/instructions`);
        }, 1000);
      }
      else{
        setAlertMessage("MCQ Test is already Completed!");
        setAlertVisible(true);
        setTimeout(() => {
          setAlertVisible(false);
        }, 2000);
      }
    }
    catch (error) {
        console.error(error);
    }
  }


  const navigateCodingtest = async () => {
    try {
      const response = await axios.post(`${host}/checkcandidatestatus/${interview_id}`);
      if((response.data.mcq_status === "Passed" || response.data.mcq_status==="NA") && response.data.coding_status !== "Complete" ){
        localStorage.setItem(`timeLeft_${interview_id}_coding`, response.data.coding_time_limit*60);
        setTimeout(() => {
          navigate(`/user/${interview_id}/codinginstructions`);
        }, 1000);      
        
      }
      else if(response.data.mcq_status != "Passed"){
        setAlertMessage("You Must Pass Previous Tests To Start Coding Test!");
        setAlertVisible(true);
        setTimeout(() => {
          setAlertVisible(false);
        }, 2000);
      }
      else{
        setAlertMessage("Coding Test already Completed!");
        setAlertVisible(true);
        setTimeout(() => {
          setAlertVisible(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  }

  const navigateInterview = async () => {
    try {
      const response = await axios.post(`${host}/checkcandidatestatus/${interview_id}`);
      if((response.data.mcq_status === "Passed" || response.data.mcq_status === "NA") && (response.data.coding_status === "Complete" || response.data.coding_status === "NA")){
        navigate(`/interview/${interview_id}/instructions`);
      }
      else{
        setAlertMessage("You Must Pass Previous Tests To Start Interview!");
        setAlertVisible(true);
        setTimeout(() => {
          setAlertVisible(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  }

  return (
    <>
    {alertVisible && <CustomALert message={alertMessage} />}
    <div className='TestHomePage'>
      <div className='TestHomePageContent'>
        {MCQTestVisible &&
          <button onClick={navigateMCQtest}>MCQ Test</button>
        }
        {CodingTestVisible &&
          <button onClick={navigateCodingtest}>Coding Test</button>
        }
        <button onClick={navigateInterview}>Interview</button>        
      </div>
    </div>
    </>
  );
};

export default TestHomePage;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../../css/evaluationPage.css';
import host from '../../global';
import UserDetails from '../Reports/UserDetails';
import MCQResult from '../Reports/MCQResult';
import InterviewResult from '../Reports/InterviewResult';
import CodingResult from '../Reports/CodingResult';
import Header from './header'; 
import Sidebar from './sidebar';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';

function EvatuationReportOrgAdmin() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { orgadminId, interview_id } = useParams();
  const [candidateName, setCandidateName] = useState('');
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [scores, setScores] = useState([]);
  const [remarks, setRemark] = useState([]);
  const [audioUrls, setAudioUrls] = useState([]);
  const [interviewStartDateTime,setInterviewStartDateTime] = useState('')
  const [finalRemark, setFinalRemark] = useState('');
  const [totalScore, setTotalScore] = useState(0);
  const [contact, setContact] = useState(0);
  const [numberOfQuestion, setNumberOfQuestion] = useState(0);
  const [MCQTestResultData, setMCQTestResultData] = useState(null);
  const [mcq_score, setMcqScore] = useState(0);
  const [percentInterviewScore, setPercentInterviewScore] = useState(0);
  const [status, setStatus] = useState({
    mcq_status: 'incomplete',
    coding_status: 'incomplete',
    interview_status: 'incomplete',
  });

  useEffect(() => {
    const getCandidateStatus = async () => {
      try {
        const response = await axios.post(`${host}/checkcandidatestatus/${interview_id}`);
        setStatus(response.data);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching candidate status:', error);
      }
    };

    getCandidateStatus();
  }, [interview_id]);

  useEffect(() => {
    if (status.mcq_status === 'Passed' || status.mcq_status === 'Complete' || status.mcq_status === 'NA') {
      const getNumberOfQuestions = async () => {
        try {
          const response = await axios.post(`${host}/numberofquestions/${interview_id}`);
          setNumberOfQuestion(response.data);
        } catch (error) {
          console.error('Error:', error.message);
        }
      };
  
      const getcandidatedetails = async () => {
        try {
          const response = await axios.get(`${host}/getcandidatedetails/${interview_id}`);
          setCandidateName(response.data.candidate_name);
          setContact(response.data.contact);
        } catch (error) {
          console.error('Error:', error.message);
        }
      };
      getcandidatedetails();
      getNumberOfQuestions();
    }
  }, [status.mcq_status, interview_id]);

  useEffect(() => {
    if ((status.mcq_status === 'Passed' || status.mcq_status === 'Complete') && interview_id) {
      const displayMCQTestResult = async () => {
        try {
          const response = await axios.post(`${host}/get_mcqs_test_result/${interview_id}`);
          setMCQTestResultData(response.data);
          const response1 = await axios.get(`${host}/count_objects/${interview_id}`);
          console.log(response1.data)
          setMcqScore(parseInt((response.data.total_score/parseInt(response1.data.count))*100));
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      displayMCQTestResult();
    }
  }, [interview_id, status.mcq_status, interview_id]);
  
  useEffect(() => {
    if (status.interview_status === 'Complete') {
      const fetchEvaluationResults = async () => {
        try {
          const response = await axios.get(`${host}/evaluation/${interview_id}`);
          const { questions, answers, scores, remarks, audio_urls, startDateTime } = response.data;
          console.log(scores);
          
          const filteredScores = scores
            .map(score => parseFloat(score))
            .filter(score => !isNaN(score));
  
          setQuestions(questions);
          setAnswers(answers);
          setScores(filteredScores);
          setRemark(remarks);
          setAudioUrls(audio_urls);
          setInterviewStartDateTime(startDateTime);
  
          const sumOfScores = filteredScores.reduce((acc, score) => acc + score, 0);
          setTotalScore(sumOfScores);
  
          const averageScore = filteredScores.length > 0 ? sumOfScores / filteredScores.length : 0;
          setPercentInterviewScore(parseInt(averageScore));
          setFinalRemark(averageScore > 60 ? 'Selected' : 'Rejected');
        } catch (error) {
          console.error('Error fetching evaluation results:', error);
        }
      };
  
      fetchEvaluationResults();
    }
  }, [status.interview_status, orgadminId, interview_id, numberOfQuestion]);  

  return (
    <>
    <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} orgadminId={orgadminId}/>
    <div className="company-admin-container">
      <Sidebar isSidebarOpen={isSidebarOpen} orgadminId={orgadminId}/>
      <div className={`content ${isSidebarOpen ? 'sidebarIsOpen' : ''}`}>
        <div className="App">
        <header className="title">Evaluation Report</header>
        <UserDetails
          interviewId={interview_id}
          candidateName={candidateName}
          contact={contact}
          totalScore={totalScore}
          mcq_score={mcq_score}
          percentInterviewScore={percentInterviewScore}
          numberOfQuestion={numberOfQuestion}
          finalRemark={finalRemark}
          mcq_status={status.mcq_status}
          coding_status={status.coding_status}
          interview_status={status.interview_status}
        />
        {(status.mcq_status === 'Passed' || status.mcq_status === 'Complete') && MCQTestResultData && (
          <MCQResult
            MCQTestResultData={MCQTestResultData}
            orgadminId={orgadminId}
            interviewId={interview_id}
          />
        )}
        {status.coding_status === 'Complete' && (
          <CodingResult 
            orgadminId={orgadminId}
            interview_id={interview_id}
          />
        )}
        {status.interview_status === 'Complete' && (
          <InterviewResult
            questions={questions}
            answers={answers}
            scores={scores}
            totalScore={totalScore}
            remarks={remarks}
            audioUrls={audioUrls}
            orgadminId={orgadminId}
            interviewId={interview_id}
            interviewStartDateTime = {interviewStartDateTime}
          />
        )}
      </div>
    </div>
    </div>
    </>
  );
  
}

export default EvatuationReportOrgAdmin;
import React from 'react';
import '../../css/instructionsPage.css';
import { useNavigate, useParams } from 'react-router-dom';

const CodingInstructions = () => {
  const navigate = useNavigate();
  const { interview_id } = useParams();
  console.log("interview",interview_id)

  const handleProceed = () => {
    navigate(`/user/${interview_id}/CodingProblemsPage`);
  };
  return (
    <div className="InstructionsPage">
      <div className="instructions-container">
        <h1>Coding Round Instructions</h1>
        <ol>
          <li>The test consists of multiple coding problems.</li>
          <li>You will have 60 minutes to complete the test.</li>
          <li>You may use any programming language of your choice, but ensure your code is executable in the provided environment.</li>
          <li>Read each problem statement carefully and follow the provided constraints.</li>
          <li>Make sure to test your code thoroughly before submission.</li>
          <li>Do not attempt to access external websites or resources. The use of unauthorized resources will be monitored.</li>
          <li>Once you submit your code, it cannot be altered. Ensure your solutions are final before submission.</li>
          <li>The test will automatically submit when the time is up, so manage your time wisely.</li>
        </ol>
      </div>
      <button onClick={handleProceed}>Proceed to Test</button>
    </div>
  );
};

export default CodingInstructions;
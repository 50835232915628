import React from 'react';
import '../../css/instructionsPage.css';
import { useNavigate, useParams } from 'react-router-dom';

const MCQInstructions = () => {
  const navigate = useNavigate();
  const {hr_id} = useParams();
  const {interview_id} = useParams();
  const handleProceed =() =>{
    navigate(`/user/${hr_id}/mcqtest/${interview_id}`);
  }
  return (
    <div className="InstructionsPage">
      <div className="instructions-container">
        <h1>MCQ Round Instructions</h1>
        <ol>
          <li>There will be 25 MCQs.</li>
          <li>There is no Negative Marking.</li>
          <li>The Time will be 30 mins.</li>
          <li>
            Don't try to sneak out of the tab, you will get 2 warnings, and after that, the test will be submitted automatically.
          </li>
          <li>You can see at to Top how much questions you have attempted and which questions are left</li>
          <li>The test will automatically submit when the time is up.</li>
          <li>Read each question carefully before answering.</li>
          <li>Once you submit the test, you cannot change your answers.</li>
          <li>No external help or reference materials are allowed during the test.</li>
        </ol>
      </div>
      <button onClick={handleProceed}>Proceed to Test</button>
    </div>
  );
};

export default MCQInstructions;
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Editor from '@monaco-editor/react';
import '../../css/codingProblems.css';
import HeaderBar from '../../components/headersection.jsx';
import Toolbar from '../../components/CodingToolbar.jsx';
import CustomALert from '../../components/customalter.js';
import { debounce } from 'lodash';
import host from '../../global.js';

const CodingProblemsPage = () => {
    const { interview_id } = useParams();
    const navigate = useNavigate();
    const [problems, setProblems] = useState([]);
    const [currentProblem, setCurrentProblem] = useState(null);
    const [code, setCode] = useState('');
    const startDateTime = (localStorage.getItem(`${interview_id}_coding_startDateTime`) || new Date().toUTCString());
    localStorage.setItem(`${interview_id}_coding_startDateTime`, startDateTime);
    const [selectedLanguage, setSelectedLanguage] = useState('javascript');
    const [output, setOutput] = useState('');
    const [error, setError] = useState('');
    const [testResults, setTestResults] = useState([]);
    const [showOutput, setShowOutput] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [tabSwitchCount, setTabSwitchCount] = useState(0);
    const [showTestCases, setShowTestCases] = useState(false);
    const [customInput, setCustomInput] = useState('');
    const [showCustomInput, setShowCustomInput] = useState(false);
    //const [codingScore, setCodingScore] = useState(0);
    const [showSubmitResults, setShowSubmitResults] = useState(false);
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const timeLeftKey = `timeLeft_${interview_id}_coding`;
    const initialTimeLeft = parseInt(localStorage.getItem(timeLeftKey));
    const [timeLeft, setTimeLeft] = useState(initialTimeLeft);
    const audio = new Audio('/tab-switch-sound.mp3');
    const [wasTabVisible, setWasTabVisible] = useState(true); 
    const entryAudio = new Audio('/switch_to_fullscreen.mp3'); 
    const fullscreenExitAudio = new Audio('/fullscreen-exit-sound.mp3');
    const [isLoading, setIsLoading] = useState(false);


    const stopAllAudio = async () => {
        audio.pause();
        audio.currentTime = 0;
        fullscreenExitAudio.pause();
        fullscreenExitAudio.currentTime = 0;
        entryAudio.pause();
        entryAudio.currentTime = 0;
    };

    const sendCompletionMail = async () => {
        try {
            const resp = await axios.post(`${host}/gethremail/${interview_id}`);
            const hremail = resp.data["hremail"];
            const hr_id = resp.data["hr_id"];
            console.log(hremail)
            console.log(hr_id)
            axios.post(`${host}/notifymcqtestcomplete/${hremail}/${hr_id}/${interview_id}`);
        } catch (error) {
            }
    };
    useEffect(() => {
        const saveCodeToLocalStorage = debounce(() => {
            if (currentProblem) {
                localStorage.setItem(`${selectedLanguage}_${currentProblem.id}`, code);
            }
        }, 4000);

        saveCodeToLocalStorage();

        return () => {
            saveCodeToLocalStorage.cancel();
        };
    }, [code, currentProblem]);



    useEffect(() => {
        if (currentProblem) {
            const savedCode = localStorage.getItem(`${selectedLanguage}_${currentProblem.id}`);
            setCode(savedCode || (currentProblem.boilerplate_code[selectedLanguage]));


        }
    }, [selectedLanguage, currentProblem]);


    useEffect(() => {
        entryAudio.play()
            .then(() => {
                console.log('Entry audio playback started successfully.');
            })
            .catch(error => {
                console.error('Entry audio playback error:', error);
            });

        return () => {
            entryAudio.pause();
            entryAudio.currentTime = 0;
        };
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                // Tab has become hidden
                setWasTabVisible(false);
                setTabSwitchCount(prevCount => prevCount + 1);
                playAudio();
            } else if (!wasTabVisible) {
                // Tab has become visible and it was previously hidden
                setWasTabVisible(true);
                // Do not play audio when returning to the tab
            }
        };
        const handleFullscreenChange = () => {
            if (!document.fullscreenElement) {
                setTabSwitchCount(prevCount => prevCount + 1);
                playFullscreenExitAudio();
            }
        };

        const playAudio = () => {
            stopAllAudio();
            audio.play()
                .then(() => console.log('Audio playback started successfully.'))
                .catch(error => console.error('Audio playback error:', error));
        };

        const playFullscreenExitAudio = () => {
            stopAllAudio();
            fullscreenExitAudio.play()
                .then(() => console.log('Fullscreen exit audio playback started successfully.'))
                .catch(error => console.error('Fullscreen exit audio playback error:', error));
        };


        document.addEventListener('visibilitychange', handleVisibilityChange);
        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        if (tabSwitchCount === 1) {
            alert('Warning: You have switched tabs or exited fullscreen. Please return to the test.');
        } else if (tabSwitchCount === 2) {
            alert('Warning: You have switched tabs or exited fullscreen again. Please return to the test.');
        } else if (tabSwitchCount === 3) {
            alert('You have switched tabs or exited fullscreen multiple times. Your test will be submitted.');
            stopAllAudio();
            EndTest();
        }
    }, [tabSwitchCount]);

    useEffect(() => {
        localStorage.setItem(timeLeftKey, timeLeft.toString());
    }, [timeLeft]);

    useEffect(() => {
        console.log(interview_id)
        axios.get(`${host}/api/problems/${interview_id}`)
            .then((response) => {
                setProblems(response.data);
                if (response.data.length > 0) {
                    setCurrentProblem(response.data[0]);
                }
            })
            .catch((error) => {
                console.error('Failed to fetch problems:', error);
            });
    }, []);

    useEffect(() => {
        if (timeLeft <= 0) {
            EndTest();
            return;
        }
        const timer = setInterval(() => {
            setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
        }, 1000);
        return () => clearInterval(timer);
    }, [timeLeft]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    const handleProblemClick = (id) => {
        const problem = problems.find(p => p.id === id);
        setCurrentProblem(problem);
        const savedCode = localStorage.getItem(`${selectedLanguage}_${problem.id}`);
        setCode(savedCode || (problem.boilerplate_code[selectedLanguage]));

        setShowOutput(false);
        setShowTestCases(false);
        setShowSubmitResults(false);
        setError('');
    };

    const handleRun = () => {
        setIsLoading(true);
        setOutput('');
        setError('');
        setTestResults([]);
        setShowOutput(true);
        setShowTestCases(false);
        setShowSubmitResults(false);
        setShowCustomInput(false);

        axios.post(`${host}/api/problems/${currentProblem.id}/submit`, { code, language: selectedLanguage, input: customInput })
            .then((response) => {
                const result = response.data;
                // Compilation Error
                setError(result.compile_output || result.stderr);
                setOutput(result.stdout || '');
            })
            .catch((error) => {
                console.error('Failed to execute code:', error);
                setError('Error: Failed to execute code');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const runTestCases = async () => {
        if (!currentProblem || !currentProblem.test_cases) return;

        setIsLoading(true);

        try {
            // Make a request to the new endpoint
            const response = await axios.post(`${host}/api/problems/${currentProblem.id}/run_test_case`, {
                code,
                language: selectedLanguage,
                input: ""
            });

            // The response is a list of results
            const results = response.data;

            // Process the results
            const processedResults = results.map((result, index) => ({
                index: index + 1,
                input: currentProblem.test_cases[index]?.input || "Unknown",
                desiredOutput: currentProblem.test_cases[index]?.output || "Unknown",
                yourOutput: result.yourOutput,
                status: result.status_message
            }));

            setTestResults(processedResults);
            setShowOutput(false);
            setShowTestCases(true);
            setShowCustomInput(false);
            setShowSubmitResults(false);

        } catch (error) {
            console.error('Failed to run test cases:', error);
        } finally {
            setIsLoading(false);
        }
    };


    function toggleCustomInput() {
        setShowCustomInput(!showCustomInput);
        setShowOutput(false);
        setShowTestCases(false);
        setShowSubmitResults(false);
        setShowSubmitResults(false);
    };

    const EndTest = async () => {
        // Collect all problem IDs
        const problemIds = problems.map(problem => problem.id);

        try {
            await fetch(`${host}/api/end_coding_test/${interview_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ problem_ids: problemIds })
            });

            await fetch(`${host}/save_coding_test/${interview_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ startDateTime }),
            });
            

            await stopAllAudio();

            // Clean up local storage
            localStorage.removeItem(timeLeftKey);
            problems.forEach(problem => {
                localStorage.removeItem(`${selectedLanguage}_${problem.id}`);
            });

            // Clear additional local storage items if necessary
            localStorage.clear();

            // Show alert message and navigate
            setAlertMessage("Test submitted successfully!");
            setAlertVisible(true);
            sendCompletionMail();

            setTimeout(() => {
                setAlertVisible(false);
                navigate(`/user/${interview_id}/testhomepage`);
            }, 4000);
        } catch (error) {
            console.error("Error ending the test:", error);
        }
    };



    const handleFinalSubmit = async () => {
        if (window.confirm('Are you sure you want to submit your code?')) {
            setIsLoading(true);
            setSubmitted(true);
            setShowOutput(false);
            setShowTestCases(false);
            setShowSubmitResults(false);
            setError('');
    
            
    
            try {
                // Send the code for final submission
                axios.post(`${host}/api/problems/${currentProblem.id}/submit_final_test_cases`, { code, language: selectedLanguage, interview_id });
                //const response = await axios.post(`${host}/api/problems/${currentProblem.id}/submit_final_test_cases`, { code, language: selectedLanguage, interview_id });

                // // Process the results
                // const { results, coding_score } = response.data;
                // const formattedResults = results.map((result, index) => ({
                //     index: index + 1,
                //     status: result.status_message,
                //     yourOutput: result.yourOutput,
                //     expectedOutput: result.expectedOutput
                // }));
    
                // setTestResults(formattedResults);
                // setCodingScore(coding_score);
                // setShowSubmitResults(true);

                // Immediately notify user that code is being submitted
                setAlertMessage('Your code has been submitted for evaluation.You can proceed to next question or End Test');
                setAlertVisible(true);
        
                // Hide the alert after 3 seconds
                setTimeout(() => {
                    setAlertVisible(false);
                }, 3000);
                setShowCustomInput(false);
                setShowTestCases(false);
    
            } catch (error) {
                console.error('Failed to submit final code:', error);
                setError('Error: Failed to submit final code');
            } finally {
                setIsLoading(false);
            }
        }
    }
     
    if (!currentProblem) return <div>Loading...</div>;

    return (
        <>

            {alertVisible && <CustomALert message={alertMessage} />}
            <div style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
                <HeaderBar />
                <header id='header'>
                    <p id='timeleft'>
                        Time Left: {formatTime(timeLeft)}
                        <button onClick={EndTest} className='end-test-btn'>
                            End Test
                        </button>
                    </p>
                </header>
                <main style={{ display: 'flex', flex: 1 }}>
                    <Toolbar problems={problems} interview_id={interview_id} onProblemClick={handleProblemClick} />
                    <div className='codepage'>
                        <div className='problemstatement'>
                            <h3>{currentProblem.description}</h3>
                            <div className='problemdescription'>
                                <div className='problem-section'>
                                    <h4>Input Format:</h4>
                                    <p>{currentProblem.input_format}</p>
                                    <h4>Output Format:</h4>
                                    <p>{currentProblem.output_format}</p>
                                    <h4>Constraints:</h4>
                                    <p>{currentProblem.constraints}</p>
                                </div>
                                <div className='problem-section'>
                                    {currentProblem.examples && currentProblem.examples.length > 0 && (
                                        <div>
                                            <h3>Examples:</h3>
                                            <ol>
                                                {currentProblem.examples.map((example, index) => (
                                                    <li key={index}>
                                                        <strong>Input:</strong> <pre>{example.input}</pre>
                                                        <strong>Output:</strong><pre> {example.output}</pre>
                                                        <strong>Explanation:</strong> <pre>{example.explanation}</pre>
                                                    </li>
                                                ))}
                                            </ol>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='code-editor-container'>
                            <div className='selectlanguage'>
                                <select id="language" value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
                                    <option value="javascript">JavaScript</option>
                                    <option value="python">Python</option>
                                    <option value="java">Java</option>
                                    <option value="cpp">C++</option>
                                    <option value="c">C</option>
                                    <option value="go">Go</option>
                                    <option value="cs">C#</option>
                                </select>
                            </div>
                            <div className='editor'>
                                <Editor
                                    height="60vh"
                                    language={selectedLanguage.toLowerCase()}
                                    value={localStorage.getItem(`${selectedLanguage}_${currentProblem.id}`)}
                                    onChange={(value) => setCode(value)}
                                />
                            </div>
                            {isLoading && (
                                <div className="loadingstate">
                                    <div className="spinner"></div>
                                </div>
                            )}
                            {(showOutput || showTestCases || showSubmitResults) && !isLoading && (
                                <div>
                                    {showOutput && output && (
                                        <div className='output'>
                                            <h2>Output:</h2>
                                            <pre>{output}</pre>
                                        </div>
                                    )}
                                    {showTestCases && (
                                        <div className='test-cases'>
                                            <h2>Test Case Results:</h2>
                                            <ul>
                                                {testResults.map((result) => (
                                                    <li key={result.index}>
                                                        <strong>Test Case {result.index} ({result.status})</strong>
                                                        <div>
                                                            <strong>Your Output:</strong>
                                                            <pre>{result.yourOutput}</pre>
                                                        </div>
                                                        <div>
                                                            <strong>Expected Output:</strong>
                                                            <pre>{result.desiredOutput}</pre>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                    {/* {showSubmitResults && (
                                        <div>
                                            <h2>Submit Results:</h2>
                                            <ul>
                                                {testResults.map((result) => (
                                                    <li key={result.index}>
                                                        <strong>Test Case {result.index} ({result.status})</strong>
                                                        <div>Your Output: {result.yourOutput}</div>
                                                        <div>Expected Output: {result.expectedOutput}</div>
                                                    </li>
                                                ))}
                                            </ul>
                                            <h2>Coding Score: {codingScore}</h2>
                                        </div>
                                    )} */}
                                </div>
                            )}
                            {error && !isLoading && (
                                <div className='error'>
                                    <h2>Error:</h2>
                                    <pre>{error}</pre>
                                </div>
                            )}
                            <div className="actionbtns">
                                <div className='custominput'>
                                    <button onClick={toggleCustomInput}>
                                        {showCustomInput ? 'Hide Input' : 'Custom Input'}
                                    </button>
                                    {showCustomInput && (
                                        <textarea
                                            value={customInput}
                                            onChange={(e) => setCustomInput(e.target.value)}
                                            placeholder="Enter custom input here..."
                                        />
                                    )}
                                </div>
                                <div className={`button-group ${showCustomInput ? 'column-layout' : 'row-layout'}`}>
                                    <button onClick={handleRun}>Run</button>
                                    <button onClick={runTestCases}>Run Test Cases</button>
                                    <button onClick={handleFinalSubmit}>Submit Code</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );

};
export default CodingProblemsPage;
import React, { useEffect, useState } from 'react';
import Sidebar from './sidebar';
import Dashboard from './Dashboard';
import Header from './header'; 
import '../../css/CompanyAdmin.css';
import { useParams } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import host from '../../global';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

const OrganizationAdmin = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);
  const { orgadminId } = useParams(); 
  const [isLoading, setIsLoading] = useState(true);
const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const session = await fetchAuthSession();
        const { accessToken } = session.tokens ?? {};
        const groups = accessToken.payload['cognito:groups'] ?? [];
        const { userId } = await getCurrentUser();
        
        if (groups.includes('Company_Admin') && userId === orgadminId) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };
    
    checkSession();
    }, []);

  useEffect(() => { 
    const fetchDashboardData = async () => {
      try {
        const response = await fetch(`${host}/dashboard/${orgadminId}`);
        const data = await response.json();
        setDashboardData(data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error.message);
      }
    };

    fetchDashboardData();
  }, [orgadminId]);

  if (isLoading) {
    <div className="loader"></div>
  }

  if (!isAuthenticated) {
    return (
      <div className="AuthenticationMessageContainer">
        <div className="AuthenticationMessage">
      <p>
        You are not Authorized to access this Page!
      </p>
      <button onClick={() => navigate('/admin/login')}> Login Page</button>
      </div>
      </div>
    );
  }
  
  return (
    <>
    <Authenticator>
      <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} orgadminId={orgadminId} />
      <div className="company-admin-container">
        <Sidebar isSidebarOpen={isSidebarOpen} orgadminId={orgadminId} />
        <div className={`content ${isSidebarOpen ? 'sidebarIsOpen' : ''}`}>
          {dashboardData ? (
            <Dashboard data={dashboardData} orgadminId={orgadminId} />
          ) : (
            <div className="loader"></div>
          )}
        </div>
      </div>
    </Authenticator>
    </>
  );
};

export default OrganizationAdmin;

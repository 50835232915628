import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../css/newLandingPage.css";
import "../../css/DemoPortal.css";
import axios from 'axios';
import CustomALert from '../../components/customalter';
import Header from "./Header";
import Footer from "./Footer";
import host from '../../global';
import Alert from '@mui/material/Alert';

const DemoPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    employeeSize: '',
    Department: '',
    jobRole: '',
    isRecruitmentConsultancy: false,
    isUsingATS: false,
    message: ''
  });

  const navigate = useNavigate();
  const [emailError, setEmailError] = useState('');
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const validateEmail = (email) => {
    const workEmailRegex = /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|live\.com|aol\.com|icloud\.com|mail\.com|msn\.com)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (workEmailRegex.test(email)) {
      setEmailError('');
      return true;
    } else {
      setEmailError('Please enter a valid work email address.');
      return false;
    }
  };
  

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'email') {
      validateEmail(value);
    }

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
        setAlertMessage("Please Enter valid work Email Address!");
        setAlertVisible(true);
        setTimeout(() => {
          setAlertVisible(false);
        }, 1000);
      return;
    }

    try {
      const response = await axios.post(`${host}/submit-form`, formData);
      if (response) {
        setAlertMessage("Thank You For Showing Interest. We will connect with you soon!");
        setAlertVisible(true);
        setTimeout(() => {
          setAlertVisible(false);
          navigate('/');
        }, 2500);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <>
      {alertVisible && <CustomALert message={alertMessage} />}
      <div className='LP'>
        <div className='LP-bkg' ></div>
        <div className='Section1' style={{ height: '70vh' }}>
          <Header />
          <div className="welcomeText">
            <h1>Request a Demo</h1>
            <p className="description">
              Discover how GenieHire can transform your recruitment process. Fill out the form below to request a personalized demo.
            </p>
          </div>
        </div>
        <div className='demo-form-section'>
          <div className='demo-content'>
            <h2>Book Your Exclusive Demo</h2>
            <p>Here’s what you’ll experience:</p>
            <ul>
              <li>Witness a live showcase of our cutting-edge AI-driven recruitment tools in action.</li>
              <li>Discover how our platform can revolutionize and streamline your hiring process.</li>
              <li>Engage in a personalized Q&A session to get all your questions answered.</li>
              <li>Hear success stories from clients who’ve transformed their recruitment strategies.</li>
              <li>Receive tailored insights and pricing options that fit your specific needs.</li>
            </ul>
          </div>
        </div>

        <div className="demo-portal">
          <div className="demo-portal-content">
            <h1>Fill The Details</h1>
            <form onSubmit={handleSubmit}>
              <div className='section-1'>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Work Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <div className='demo-error'>
                  {emailError && <Alert severity="error">{emailError}</Alert>}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="employeeSize">Employee Size</label>
                  <select
                    id="employeeSize"
                    name="employeeSize"
                    value={formData.employeeSize}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select</option>
                    <option value="1-10">1-10</option>
                    <option value="11-50">11-50</option>
                    <option value="51-200">51-200</option>
                    <option value="201-500">201-500</option>
                    <option value="501+">501+</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="Department">Your Department</label>
                  <select
                    id="Department"
                    name="Department"
                    value={formData.Department}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Marketing">Marketing</option>
                    <option value="Sales">Sales</option>
                    <option value="HR">HR</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="jobRole">Job Role</label>
                  <select
                    id="jobRole"
                    name="jobRole"
                    value={formData.jobRole}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select</option>
                    <option value="Manager">Manager</option>
                    <option value="Team Lead">Team Lead</option>
                    <option value="Staff">Employee</option>
                    <option value="Intern">Intern</option>
                  </select>
                </div>
              </div>

              <div className='section-2'>
                <div className="form-group checks">
                  <label>Are you a recruitment consultancy?</label>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      id="isRecruitmentConsultancy"
                      name="isRecruitmentConsultancy"
                      checked={formData.isRecruitmentConsultancy}
                      onChange={handleChange}
                    />
                    <label htmlFor="isRecruitmentConsultancy">Yes</label>
                  </div>
                </div>
                <div className="form-group checks">
                  <label>Are you currently using an ATS?</label>
                  <div className="toggle-switch">
                    <input
                      type="checkbox"
                      id="isUsingATS"
                      name="isUsingATS"
                      checked={formData.isUsingATS}
                      onChange={handleChange}
                    />
                    <label htmlFor="isUsingATS">Yes</label>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="message">What hiring problems are you trying to solve? (optional)</label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="3"
                  ></textarea>
                </div>
                <button id='submitDemo' type="submit">Submit Request</button>
              </div>
            </form>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default DemoPage;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/userDashboard.css';
import host from '../../global.js';
import CustomALert from '../../components/customalter.js';

const UserDashboard = () => {
  const { interview_id } = useParams();
  const [candidateEmail, setCandidateEmail] = useState('');
  const [candidateName, setCandidateName] = useState("");
  const [interviewTime, setInterviewTime] = useState(null); // Add state for interview time
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [MCQTestId, setMCQTestId] = useState('');
  const [countdown, setCountdown] = useState(null); // State for countdown
  const navigate = useNavigate();

  useEffect(() => {
    const autoFill = async () => {
      try {
        const response = await axios.get(`${host}/getcandidatemail_interview_time/${interview_id}`);
        setCandidateEmail(response.data.candidate_email);
        setCandidateName(response.data.candidate_name); 
        setInterviewTime(new Date(response.data.interview_scheduled_time)); 
      } catch (error) {
        console.error("Error: ", error);
      }
    }
    autoFill();
  }, [interview_id]);

  useEffect(() => {
    if (interviewTime) {
      const updateCountdown = () => {
        const now = new Date();
        const timeDifference = interviewTime - now;

        if (timeDifference > 0) {
          const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
          setCountdown(`${hours}h ${minutes}m ${seconds}s`);
        } else {
          setCountdown(null);
        }
      }

      updateCountdown();
      const intervalId = setInterval(updateCountdown, 1000);

      return () => clearInterval(intervalId);
    }
  }, [interviewTime]);

  const [formData, setFormData] = useState({
    name: candidateName,
    contact: '',
    interviewID: interview_id,
    emailID: candidateEmail
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!countdown) { // Only submit if the countdown is null (i.e., interview time has passed)
      const data = new FormData();
      data.append('name', formData.name);
      data.append('contact', formData.contact);
      data.append('interviewID', formData.interviewID);

      try {
        // Check if interviewID exists in MongoDB
        const response = await axios.get(`${host}/check-interview/${formData.interviewID}`, {
          params: {
            contact_number: formData.contact,
            name: formData.name,
          }
        });
      } catch (error) {
        console.error('Error submitting form:', error);
      } finally {
        navigate(`/user/${interview_id}/testhomepage`);
      }
    } else {
      setAlertVisible(true);
      setAlertMessage("The test has not started yet. Please wait for the countdown to complete.");
    }
  };

  return (
    <>
      {alertVisible && <CustomALert message={alertMessage} />}
      <div className='userDashboardPage'>
        <div className='userForm'>
          <h1>Fill The Details</h1>
          {countdown ? (
            <div className='countdown'>
              <h2>Countdown to Test:</h2>
              <p>{countdown}</p>
            </div>
          ) : (
            <form>
              <input
                name='name'
                placeholder='Enter Your Name'
                value={candidateName}
                onChange={handleInputChange}
                required
              />
              <input
                type="tel"
                name="contact"
                placeholder='Enter Contact Number'
                value={formData.contact}
                onChange={handleInputChange}
                pattern="[0-9]{10}"
                required
              />
              <input
                name="emailID"
                type="email"
                placeholder='Enter Email ID'
                value={candidateEmail}
                required
              />
              <button className='detailsubmitbtn' onClick={handleSubmit}>Submit</button>
            </form>
          )}
        </div>
      </div>
    </>
  );
}

export default UserDashboard;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../../css/evaluationPage.css';
import host from '../../global';

const CodingReport = () => {

  const { admin_id, interview_id } = useParams();
  const [problems, setProblems] = useState([]);
  const [codeSubmissions, setCodeSubmissions] = useState([]);
  const [candidateName, setCandidateName] = useState('');
  const [contact, setContact] = useState('');

  useEffect(() => {
    const getcandidatedetails = async () => {
        try {
          const response = await axios.get(`${host}/getcandidatedetails/${interview_id}`);
          setCandidateName(response.data.candidate_name);
          setContact(response.data.contact);
        } catch (error) {
          console.error('Error:', error.message);
        }
      };

    const fetchProblems = async () => {
      try {
        const response = await axios.get(`${host}/api/problems`);
        setProblems(response.data);
      } catch (error) {
        console.error('Error fetching problems:', error);
      }
    };

    const fetchCodeSubmissions = async () => {
      try {
        const response = await axios.get(`${host}/api/get_codesubmissions/${interview_id}`);
        setCodeSubmissions(response.data.submissions);
      } catch (error) {
        console.error('Error fetching code submissions:', error);
      }
    };

    getcandidatedetails();
    fetchProblems();
    fetchCodeSubmissions();
  }, [interview_id]);


  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 1500);
  }, [problems, codeSubmissions ]);

return (
    <div className="reportPage">
    <div id='reportContent'>
      <header className="title">Interview Report</header>
      <div className='candidate-details'>
        <p><strong>Name: {candidateName}</strong></p>
        <p><strong>Contact: {contact}</strong></p>
      </div>
      <div className="CodingQuestionsList">
        {problems.map((problem) => {
          const problemId = problem.id;
          const submission = codeSubmissions[problemId];
          const scoreClass = submission?.coding_score > 0 ? 'correct' : 'incorrect';
          const notAttemptedStyle = { fontWeight: 'bold', color: 'black' };
          return (
            <div key={problem._id} className={`CodingResultQuestion print`}>
              <p><span style={{ fontWeight: 'bold' }}>Problem Title:</span> {problem.title}</p>
              <p><span style={{ fontWeight: 'bold' }}>Description:</span> {problem.description}</p>
              {submission && submission.language && submission.code && submission.coding_score !== undefined && submission.test_cases_passed ? (
                <div>
                  <p><span style={{ fontWeight: 'bold' }}>Language:</span> {submission.language}</p>
                  <p><span style={{ fontWeight: 'bold' }}>Code:</span>
                  <pre id='codeOnPrint'>{submission.code}</pre></p>
                  <p><span style={{ fontWeight: 'bold' }}>Score:</span>{submission.coding_score}</p>
                  <p><span style={{ fontWeight: 'bold' }}>Test Cases Passed:</span> {submission.test_cases_passed}</p>
                </div>
              ) : (
                <p style={notAttemptedStyle}>Not Attempted</p>
              )}
            </div>
          );
        })}
      </div>
      </div>
    </div>
  );
}

export default CodingReport;
import React from 'react';
import '../../css/newLandingPage.css';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

const PricingPage = () => {
    const navigate = useNavigate();
    return (
        <div className='LP'>
            <div className='LP-bkg' style={{ height: '70vh' }}></div>
            <div className='Section1' style={{ height: '70vh' }}>
                <Header/>
                <div className='welcomeText'>
                    <h1 className='pageTitle'>Pricing</h1>
                    <p className='description'>Unlock the power of AI-driven recruitment at an unbeatable price.</p>
                </div>
            </div>
            <div className='PricingContent'>
                <div className='PricingCard'>
                    <h2 className='pricingTitle'>Independence Day Offer</h2>
                    <p className='pricingDescription'>Celebrate Independence Day with our special offer!</p>
                    <p className='pricingDetail'><strong>Get each interview for just $1</strong> (originally priced at $5). This limited-time offer is available for a short period, so don't miss out!</p>
                    <button>Start Free Trial</button>
                    <hr />
                </div>
                <div className='PricingCard'>
                    <h2 className='pricingTitle'>Standard Pricing</h2>
                    <p className='pricingDescription'>Our regular pricing is straightforward and affordable:</p>
                    <ul className='pricingList'>
                        <li>$5 per interview</li>
                        <li>No hidden fees</li>
                        <li>Flexible payment options</li>
                    </ul>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default PricingPage;

import React from 'react';
import Sidebar from './sidebar';
import '../../css/CompanyAdmin.css'
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { useState,useEffect } from 'react';

const Dashboard = ({ data,orgadminId }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
    useEffect(() => {
      const checkSession = async () => {
        try {
          const session = await fetchAuthSession();
          const { accessToken } = session.tokens ?? {};
          const groups = accessToken.payload['cognito:groups'] ?? [];
          const { userId } = await getCurrentUser();
          
          if (groups.includes('Company_Admin') && userId === orgadminId) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        } catch (error) {
          setIsAuthenticated(false);
        } finally {
          setIsLoading(false);
        }
      };
      
      checkSession();
      }, []);

      if (isLoading) {
        <div className="loader"></div>
      }
    
      if (!isAuthenticated) {
        return (
          <div className="AuthenticationMessageContainer">
            <div className="AuthenticationMessage">
          <p>
            You are not Authorized to access this Page!
          </p>
          <button onClick={() => navigate('/admin/login')}> Login Page</button>
          </div>
          </div>
        );
      }

  if (!data) {
    return   <div className="loader"></div>;
  }

  return (
    <div>
      <Sidebar />
      <h1>Welcome, {data.name}!</h1>
      <h2>Organization: {data.organizationName}</h2>
      <p>Number of HR Profiles Created</p>
      <div className="hr-profiles-circle">
        {data.numberOfHRProfiles}
      </div>
    </div>
  );
};

export default Dashboard;
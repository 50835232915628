import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import host from '../../global';
import HostCodingProblem from '../HostCodingProblem';
import '../../css/CreateCodingProblemSet.css';
import Sidebar from './sidebar';
import Dashboard from './Dashboard';
import Header from './header';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

const CreateProblem = () => {
  const initialFormData = {
    title: '',
    description: '',
    input_format: '',
    output_format: '',
    constraints: '',
    examples: [{ input: '', output: '', explanation: '' }],
    test_cases: [{ input: '', output: '' }],
    final_test_cases: [{ input: '', output: '' }],
    boilerplate_code: {}
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [sets, setSets] = useState([]);
  const [selectedSet, setSelectedSet] = useState('');
  const [newSetName, setNewSetName] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('javascript');
  const [code, setCode] = useState('');
  const [formVisible, setFormVisible] = useState(false);
  const [codingProblems, setCodingProblems] = useState([]);
  const [availableProblems, setAvailableProblems] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const { orgadminId } = useParams();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);
  const handleBoilerplateCodeChange = (language, code) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      boilerplate_code: {
        ...prevFormData.boilerplate_code,
        [language]: code
      }
    }));
  };
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
      const checkSession = async () => {
        try {
          const session = await fetchAuthSession();
          const { accessToken } = session.tokens ?? {};
          const groups = accessToken.payload['cognito:groups'] ?? [];
          const { userId } = await getCurrentUser();
          
          if (groups.includes('Company_Admin') && userId === orgadminId) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        } catch (error) {
          setIsAuthenticated(false);
        } finally {
          setIsLoading(false);
        }
      };
      
      checkSession();
      }, []);

  useEffect(() => {
    fetchSets();
  }, []);

  useEffect(() => {
    if (selectedSet) {
      fetchCodingProblems(selectedSet);
    } else {
      setCodingProblems([]); // Clear coding problems when no set is selected
      fetchAllAvailableProblems();
    }
  }, [selectedSet]);

  useEffect(() => {
    setCode(formData.boilerplate_code[selectedLanguage] || '');
  }, [selectedLanguage, formData.boilerplate_code]);

  const fetchSets = async () => {
    try {
      const response = await axios.get(`${host}/api/sets/${orgadminId}`);
      setSets(Object.keys(response.data));
    } catch (error) {
      console.error('Error fetching sets:', error);
    }
  };

  const fetchCodingProblems = async (setName) => {
    if (!setName || !orgadminId) {
      console.error('Set name or admin ID is missing.');
      return;
    }

    try {
      const response = await axios.post(`${host}/api/coding-problems`, {
        orgadminId,
        set_name: setName
      });
      setCodingProblems(response.data);

      // Fetch available problems and filter out those already in the selected set
      const responseAvailable = await axios.get(`${host}/api/available_problems`);
      const problems = responseAvailable.data;
      const filteredProblems = problems.filter(
        (p) => !response.data.some((cp) => cp.problem_id === p.problem_id)
      );
      setAvailableProblems(filteredProblems);
    } catch (error) {
      console.error('Error fetching coding problems:', error);
    }
  };

  const fetchAllAvailableProblems = async () => {
    try {
      const response = await axios.get(`${host}/api/available_problems`);
      setAvailableProblems(response.data);
    } catch (error) {
      console.error('Error fetching available problems:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log({
      ...formData,
      set_name: selectedSet || newSetName,
      orgadminId
    })
    try {
      await axios.post(`${host}/api/createproblems`, {
        ...formData,
        set_name: selectedSet || newSetName,
        orgadminId
      });
      // Provide success feedback
      alert('Problem created successfully!');

      // Reset the form data
      setFormData(initialFormData);

      // Refresh problems based on whether a set is selected
      if (selectedSet) {
        await fetchCodingProblems(selectedSet); // Refresh problems for the selected set
      } else {
        await fetchAllAvailableProblems(); // Refresh available problems if no set is selected
      }
    } catch (error) {
      // Log and alert errors
      console.error('Error creating problem:', error);
      alert('An error occurred while creating the problem. Please try again.');
    }
  };

  const handleExamplesChange = (index, key, value) => {
    const newExamples = [...formData.examples];
    newExamples[index][key] = value;
    setFormData({ ...formData, examples: newExamples });
  };

  const handleTestCasesChange = (index, key, value) => {
    const newTestCases = [...formData.test_cases];
    newTestCases[index][key] = value;
    setFormData({ ...formData, test_cases: newTestCases });
  };

  const handleFinalTestCasesChange = (index, key, value) => {
    const newFinalTestCases = [...formData.final_test_cases];
    newFinalTestCases[index][key] = value;
    setFormData({ ...formData, final_test_cases: newFinalTestCases });
  };

  const handleCreateSet = async () => {
    if (!newSetName) {
      alert('Set name is required');
      return;
    }
    try {
      const response = await axios.post(`${host}/api/create-set`, {
        orgadminId,
        set_name: newSetName,
        problems: codingProblems
      });
      setSets([...sets, newSetName]);
      setSelectedSet(newSetName);
      setNewSetName('');
    } catch (error) {
      console.error('Error creating set:', error);
    }
  };

  const handleCreateOrUpdateSet = async () => {
    if (!newSetName.trim() && !selectedSet) {
      alert('Please enter a set name or select an existing set.');
      return;
    }

    const setName = selectedSet || newSetName;

    // Extract problem IDs from codingProblems
    const problemIds = codingProblems.map(problem => problem.problem_id);

    try {
      await axios.post(`${host}/api/create-set`, {
        name: setName,
        orgadminId,
        problem_ids: problemIds
      });
      alert('Set saved successfully!');
      setNewSetName('');
      //setCreateSetVisible(false);
      fetchSets(); // Refresh the sets list
    } catch (error) {
      console.error('Error saving set:', error);
    }
  };

  const moveToCodingProblems = (problem) => {
    setCodingProblems([...codingProblems, problem]);
    setAvailableProblems(availableProblems.filter(p => p.problem_id !== problem.problem_id));
  };

  const moveToAvailableProblems = (problem) => {
    setAvailableProblems([...availableProblems, problem]);
    setCodingProblems(codingProblems.filter(p => p.problem_id !== problem.problem_id));
  };

  if (isLoading) {
    <div className="loader"></div>
  }

  if (!isAuthenticated) {
    return (
      <div className="AuthenticationMessageContainer">
        <div className="AuthenticationMessage">
      <p>
        You are not Authorized to access this Page!
      </p>
      <button onClick={() => navigate('/admin/login')}> Login Page</button>
      </div>
      </div>
    );
  }
  return (
    <>
      <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} orgadminId={orgadminId}/>
      <div className="company-admin-container">
        <Sidebar isSidebarOpen={isSidebarOpen} orgadminId={orgadminId}/>
        <div className={`content ${isSidebarOpen ? 'sidebarIsOpen' : ''}`}>
          {dashboardData ? (
            <Dashboard data={dashboardData} />
          ) : (
            <div className="create-problem-container">
              <h1>Create Coding Problem</h1>

              <div className="form-container">
                <div className="set-selection">
                  <div className='select-set'>
                  <label>Select a Set : </label>
                  <select
                    value={selectedSet}
                    onChange={(e) => setSelectedSet(e.target.value)}
                  >
                    <option value="">-- Select a Set --</option>
                    {sets.map((set, index) => (
                      <option key={index} value={set}>{set}</option>
                    ))}
                  </select>
                  </div>

                  <div className="new-set">
                    <label>Create New Set : </label>
                    <input
                      type="text"
                      value={newSetName}
                      onChange={(e) => setNewSetName(e.target.value)}
                      placeholder="New Set Name"
                    />
                    <button
                      onClick={handleCreateOrUpdateSet}
                      className="show-form-button"
                    >
                      {selectedSet ? 'Update Set' : 'Create Set'}
                    </button>
                  </div>
                </div>

                <div className="problems-section">
                  <div className="coding-problems-section">
                    <h2>Coding Problems</h2>
                    <ul>
                      {codingProblems.length > 0 ? (
                        codingProblems.map((problem, index) => (
                          <li key={index}>
                            <h3>{problem.title}</h3>
                            <button onClick={() => moveToAvailableProblems(problem)}>-</button>
                          </li>
                        ))
                      ) : (
                        <p>No coding problems available.</p>
                      )}
                    </ul>
                  </div>

                  <div className="available-problems-section">
                    <h2>Available Problems</h2>
                    <ul>
                      {availableProblems.length > 0 ? (
                        availableProblems.map((problem) => (
                          <li key={problem.problem_id}>
                            <h3>{problem.title}</h3>
                            <button onClick={() => moveToCodingProblems(problem)}>+</button>
                          </li>
                        ))
                      ) : (
                        <p>No available problems.</p>
                      )}
                    </ul>
                  </div>
                </div>

                {!formVisible ? (
                  <>
                    <button
                      onClick={() => setShowPopup(true)}
                      className="show-form-button"
                    >
                      Host My Problem
                    </button>

                    {showPopup && (
                      <div className="popup-overlay">
                        <div className="popup-content">
                          <button className="popup-close" onClick={() => setShowPopup(false)}>×</button>
                          <h2>What would you like to do?</h2>
                          <div className="popup-buttons">
                            <button onClick={() => alert('Raise a ticket with your problem description')}>Contact Us</button>
                            <button onClick={() => {
                              setFormVisible(true);
                              setShowPopup(false);
                            }}>Try Myself</button>
                          </div>
                        </div>
                      </div>
                    )}

                  </>
                ) : (
                  <HostCodingProblem
                    formData={formData}
                    setFormData={setFormData}
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                    code={code}
                    setCode={setCode}
                    handleBoilerplateCodeChange={handleBoilerplateCodeChange}
                    handleSubmit={handleSubmit}
                    handleExamplesChange={handleExamplesChange}
                    handleTestCasesChange={handleTestCasesChange}
                    handleFinalTestCasesChange={handleFinalTestCasesChange}
                    setFormVisible={setFormVisible}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>


    </>
  );
};

export default CreateProblem;

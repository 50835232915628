import React, { useState } from 'react';
import { Editor } from '@monaco-editor/react';
import '../css/CreateCodingProblemSet.css';

const HostCodingProblem = ({
  formData,
  setFormData,
  selectedLanguage,
  setSelectedLanguage,
  code,
  setCode,
  handleBoilerplateCodeChange,
  handleSubmit,
  handleExamplesChange,
  handleTestCasesChange,
  handleFinalTestCasesChange
}) => {

  const [isEditorVisible, setIsEditorVisible] = useState(false);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const toggleEditorVisibility = () => {
    setIsEditorVisible(prevState => !prevState);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="createProblemForm createOwnProblem">
        <div>
          <label><h2>Title:</h2></label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            placeholder="Title of the problem"
            required
          />
        </div>

        <div>
          <label><h2>Description:</h2></label>
          <textarea
            name="description"
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            placeholder="Description of the problem"
            rows={1}
            required
          />
        </div>

        <div>
          <label><h2>Input Format:</h2></label>
          <textarea
            name="input_format"
            value={formData.input_format}
            onChange={(e) => setFormData({ ...formData, input_format: e.target.value })}
            placeholder="Input Format"
            rows={1}
            required
          />
        </div>

        <div>
          <label><h2>Output Format:</h2></label>
          <textarea
            name="output_format"
            value={formData.output_format}
            onChange={(e) => setFormData({ ...formData, output_format: e.target.value })}
            placeholder="Output Format"
            rows={1}
            required
          />
        </div>

        <div>
          <label><h2>Constraints:</h2></label>
          <textarea
            name="constraints"
            value={formData.constraints}
            onChange={(e) => setFormData({ ...formData, constraints: e.target.value })}
            placeholder="Constraints"
            rows={1}
            required
          />
        </div>

        <div>
          <h2>Examples</h2>
          {formData.examples.map((example, index) => (
            <div key={index} className="example-item">
              <label>Example Input:</label>
              <textarea
                name={`example_input_${index}`}
                value={example.input}
                onChange={(e) => handleExamplesChange(index, 'input', e.target.value)}
                placeholder="Example input"
                rows={1}
              />
              <label>Example Output:</label>
              <textarea
                name={`example_output_${index}`}
                value={example.output}
                onChange={(e) => handleExamplesChange(index, 'output', e.target.value)}
                placeholder="Example output"
                rows={1}
              />
              <label>Explanation:</label>
              <textarea
                name={`example_explanation_${index}`}
                value={example.explanation}
                onChange={(e) => handleExamplesChange(index, 'explanation', e.target.value)}
                placeholder="Explanation"
                rows={1}
              />
            </div>
          ))}
          <button type="button" onClick={() => setFormData({ ...formData, examples: [...formData.examples, { input: '', output: '', explanation: '' }] })}>
            Add Example
          </button>
        </div>

        <div>
          <h2>Test Cases</h2>
          {formData.test_cases.map((testcase, index) => (
            <div key={index} className="testcase-item">
              <input
                type="text"
                name={`test_input_${index}`}
                value={testcase.input}
                onChange={(e) => handleTestCasesChange(index, 'input', e.target.value)}
                placeholder="Test case input"
                required
              />
              <input
                type="text"
                name={`test_output_${index}`}
                value={testcase.output}
                onChange={(e) => handleTestCasesChange(index, 'output', e.target.value)}
                placeholder="Expected output"
                required
              />
            </div>
          ))}
          <button type="button" onClick={() => setFormData({ ...formData, test_cases: [...formData.test_cases, { input: '', output: '' }] })}>
            Add Test Case
          </button>
        </div>

        <div>
          <h2>Final Test Cases</h2>
          {formData.final_test_cases.map((testcase, index) => (
            <div key={index} className="testcase-item">
              <input
                type="text"
                name={`final_test_input_${index}`}
                value={testcase.input}
                onChange={(e) => handleFinalTestCasesChange(index, 'input', e.target.value)}
                placeholder="Final test case input"
                required
              />
              <input
                type="text"
                name={`final_test_output_${index}`}
                value={testcase.output}
                onChange={(e) => handleFinalTestCasesChange(index, 'output', e.target.value)}
                placeholder="Expected output"
                required
              />
            </div>
          ))}
          <button type="button" onClick={() => setFormData({ ...formData, final_test_cases: [...formData.final_test_cases, { input: '', output: '' }] })}>
            Add Final Test Case
          </button>
        </div>

        <div className="boilerplate-section">
          <button type="button" onClick={toggleEditorVisibility}>
            {isEditorVisible ? 'Hide Boiler Plate' : 'Add Boiler Plate'}
          </button>

          {isEditorVisible && (
            <>
              <div className="testcase-item">
                <select id="language" value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
                  <option value="javascript">JavaScript</option>
                  <option value="python">Python</option>
                  <option value="java">Java</option>
                  <option value="cpp">C++</option>
                  <option value="c">C</option>
                  <option value="go">Go</option>
                  <option value="cs">C#</option>
                </select>
              </div>

              <div className="editor">
                <Editor
                  height="60vh"
                  language={selectedLanguage}
                  value={code}
                  onChange={(value) => {
                    setCode(value);
                    handleBoilerplateCodeChange(selectedLanguage, value);
                  }}
                />
              </div>
            </>
          )}
        </div>

        <button type="submit" onClick={handleScrollToTop} className="submitButton">
          Create Problem
        </button>
      </form>
    </>
  );
};

export default HostCodingProblem;
import React, { useState } from "react";
import "../../css/thankYouPage.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import host from "../../global";

const ThankYou = () => {
  const { interview_id } = useParams();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [feedback, setFeedback] = useState({
    experienceRating: 0,
    comments: "",
  });
  const audio = new Audio(
    `https://nsqrbucket.s3.ap-south-1.amazonaws.com/ThankYouAudio/speech_20240926124153122.mp3`
  );

  const handleStarClick = (rating) => {
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      experienceRating: rating,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const feedbackToSend = {
      rating: feedback.experienceRating,
      comments: feedback.comments,
    };

    try {
      await axios.post(
        `${host}/submit_feedback?interview_id=${interview_id}`,
        feedbackToSend
      );
      setFormSubmitted(true);
      audio.play().catch((error) => {
        console.error("Error playing audio:", error);
        alert("Could not play the thank you audio.");
      });
    } catch (error) {
      console.error("Failed to submit feedback:", error);
      alert("There was an error submitting your feedback. Please try again.");
    }
  };

  const handleSkipFeedback = () => {
    setFormSubmitted(true);
    audio.play().catch((error) => {
      console.error("Error playing audio:", error);
      alert("Could not play the thank you audio.");
    });
  };

  const handleShowFeedbackForm = () => {
    setShowFeedbackForm(true);
  };

  if (formSubmitted) {
    return (
      <div className="thankYouPage">
        <div className="thankYouPageContent">
        <div className='interviewComplete'>
          <h1 style={{ color: "white" }}>Thank You!</h1>
          <h3 style={{ color: "white" }}>Your interview is complete.</h3>
          </div>
          <p style={{ color: "white" }}>
            You can close this tab and leave the Teams meeting.
          </p>
          <p style={{ color: "white" }}>
            We will evaluate your interview, and if qualified, you will be
            contacted by HR.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="thankYouPage">
    <div className="feedbackForm">
            <div className='interviewComplete'>
      <h1 style={{ color: "white" }}>Thank You!</h1>
      <h3 style={{ color: "white" }}>Your interview is complete.</h3>
      </div>
      <p style={{ color: "white" }}>
        Your feedback is valuable to us. Please take a moment to share your
        thoughts!
      </p>
      {!showFeedbackForm ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <button className="nextQuestionBtn" onClick={handleShowFeedbackForm}>Give Feedback</button>
          <button className="nextQuestionBtn" onClick={handleSkipFeedback}>Skip Feedback</button>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div>
            <label style={{ color: "white" }}>
              How would you rate your interview experience?
            </label>
            <div className="rating-container">
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  className={`star ${
                    feedback.experienceRating >= star ? "selected" : ""
                  }`}
                  onClick={() => handleStarClick(star)}
                  role="button"
                  tabIndex={0}
                  aria-label={`Rate ${star} out of 5`}
                  onKeyDown={(e) => e.key === "Enter" && handleStarClick(star)}
                >
                  ★
                </span>
              ))}
            </div>
          </div>

          <div>
            <label style={{ color: "white" }} htmlFor="comments">
              Additional Comments:
            </label>
            <textarea
              id="comments"
              name="comments"
              value={feedback.comments}
              onChange={(e) =>
                setFeedback((prev) => ({ ...prev, comments: e.target.value }))
              }
              placeholder="Share your thoughts..."
              rows="4"
              style={{ color: "black" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <button className="nextQuestionBtn" type="submit">Submit Feedback</button>
            <button className="nextQuestionBtn" type="button" onClick={handleSkipFeedback}>
              Skip Feedback
            </button>
          </div>
        </form>
      )}
    </div>
    </div>
  );
};

export default ThankYou;

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Create a root element for the React application
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the App component into the root element
root.render(
  // Uncomment the following line to enable StrictMode
  // <React.StrictMode>   tool for identifying potential problems in an application. It activates additional checks and warnings for its descendants.
    <App />
  // </React.StrictMode>
);

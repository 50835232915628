import React, { useEffect, useState } from 'react';
import Sidebar from './sidebar';
import Dashboard from './Dashboard';
import Header from './header'; 
import '../../css/CompanyAdmin.css';
import { useParams } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import host from '../../global';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

const HrAdminPortal = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);
  const {hr_id} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  
useEffect(() => {
  const checkSession = async () => {
    try {
      const session = await fetchAuthSession();
      const { accessToken } = session.tokens ?? {};
      const groups = accessToken.payload['cognito:groups'] ?? [];
      const { userId } = await getCurrentUser();
      
      if (groups.includes('HR') && userId === hr_id) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  checkSession();
}, []);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await fetch(`${host}/hrdashboard/${hr_id}`);
        const data = await response.json();
        setDashboardData(data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error.message);
      }
    };

    fetchDashboardData();
  }, [hr_id]);

  if (isLoading) {
    return <div className="loader"></div>;
  }

  if (!isAuthenticated) {
    return (
      <div className="AuthenticationMessageContainer">
        <div className="AuthenticationMessage">
      <p>
        You are not Authorized to access this Page!
      </p>
      <button onClick={() => navigate('/admin/login')}> Login Page</button>
      </div>
      </div>
    );
  }

  return (
    <Authenticator>
      <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} hr_id={hr_id}/>
      <div className="company-admin-container">
        <Sidebar isSidebarOpen={isSidebarOpen} hr_id={hr_id}/>
        <div className={`content ${isSidebarOpen ? 'sidebarIsOpen' : ''}`}>
          {dashboardData ? (
            <Dashboard data={dashboardData} hr_id = {hr_id}/>
          ) : (
              <div className="loader"></div>
          )}
        </div>
      </div>
    </Authenticator>
  );
};

export default HrAdminPortal;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../css/newLandingPage.css';
import Header from './Header';
import Footer from './Footer';

const LandingPage = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className='LP'>
                <div className='LP-bkg'></div>
                <div className='Section1'>
                    <Header />
                    <div className='welcomeText'>
                        <p className='largeText'>Transform</p>
                        <p className='largeText'>Recruitment with <span className='highlighedText'>AI</span></p>
                        <p className='smallText description'>
                        Transform your recruitment with GenieHire's AI solutions. From automating resume parsing to refining candidate evaluations, our technology brings unmatched efficiency and precision to your hiring process.
                        </p>
                        <button id='exploreBtn'>Explore Our Solutions</button>
                    </div>
                </div>

                <div className='Section2'>
                    <div className='Section2-Part1'>
                        <div className='videoLink'>
                            <img src='/temp.png' alt='AI Recruitment Solutions'></img>
                        </div>
                        <div className='whoAreWe'>
                            <h1>Who We Are</h1>
                            <p>
                                At GenieHire, we're passionate about leveraging AI to streamline recruitment. Our team of experts combines technology and industry knowledge to deliver innovative solutions that drive results.
                            </p>
                            <hr></hr>
                            <p className='highlighedText'>
                                Learn More &nbsp;<span><img id='arrow-icon' src='/up-right-arrow.png' alt='arrow'></img></span>
                            </p>
                        </div>
                    </div>
                    <hr></hr>
                    <div className='Section2-Part2'>
                        <h1>What We Offer</h1>
                        <div className='feature-cards'>
                            <div className='feature-card'>
                                <img src='/resume.png' alt='Resume Parsing'></img>
                                <p className='card-heading'>AI Resume Parsing</p>
                                <p className='card-description'>
                                    Automatically extract and categorize key information from resumes to quickly identify the best candidates.
                                </p>
                                <button className='xplorBtn'>Learn More</button>
                            </div>
                            <div className='feature-card'>
                                <img src='/report.png' alt='Automated Reporting'></img>
                                <p className='card-heading'>Automated Reporting</p>
                                <p className='card-description'>
                                    Generate insightful reports effortlessly with our AI-powered analytics tools.
                                </p>
                                <button className='xplorBtn'>Learn More</button>
                            </div>
                            <div className='feature-card'>
                                <img src='/mcq.png' alt='AI MCQ Tests'></img>
                                <p className='card-heading'>AI MCQ Tests</p>
                                <p className='card-description'>
                                    Evaluate candidates’ skills using AI-driven multiple-choice tests designed to assess their competencies accurately.
                                </p>
                                <button className='xplorBtn'>Learn More</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='Section3'>
                    <h1>Our Key Features</h1>
                    <div className='features'>
                        <div className='feature-img'>
                            <img src='/img1.jpg' alt='Resume Parsing'></img>
                        </div>
                        <div className='feature-description'>
                            <h1>Resume Parsing</h1>
                            <p>
                                Leverage AI to parse resumes with high accuracy, ensuring you never miss a top candidate.
                            </p>
                            <hr></hr>
                            <p className='highlighedText'>
                                Learn More &nbsp; <span><img id='arrow-icon' src='/up-right-arrow.png' alt='arrow'></img></span>
                            </p>
                        </div>
                    </div>

                    <div className='features'>
                        <div className='feature-description'>
                            <h1>AI-Powered MCQ Tests</h1>
                            <p>
                                Assess candidates efficiently with AI-generated multiple-choice questions that highlight their strengths and weaknesses.
                            </p>
                            <hr></hr>
                            <p className='highlighedText'>
                                Discover More &nbsp; <span><img id='arrow-icon' src='/up-right-arrow.png' alt='arrow'></img></span>
                            </p>
                        </div>
                        <div className='feature-img'>
                            <img src='/img1.jpg' alt='AI MCQ Tests'></img>
                        </div>
                    </div>

                    <div className='features'>
                        <div className='feature-img'>
                            <img src='/img1.jpg' alt='AI Evaluation'></img>
                        </div>
                        <div className='feature-description'>
                            <h1>AI Evaluation</h1>
                            <p>
                                Utilize AI to evaluate candidate responses and gain deeper insights into their fit for the role.
                            </p>
                            <hr></hr>
                            <p className='highlighedText'>
                                Read More &nbsp; <span><img id='arrow-icon' src='/up-right-arrow.png' alt='arrow'></img></span>
                            </p>
                        </div>
                    </div>

                    <div className='features'>
                        <div className='feature-description'>
                            <h1>AI-Powered Interviews</h1>
                            <p>
                                Conduct AI-driven interviews that assess candidate performance in real-time, providing actionable insights.
                            </p>
                            <hr></hr>
                            <p className='highlighedText'>
                                Explore More &nbsp; <span><img id='arrow-icon' src='/up-right-arrow.png' alt='arrow'></img></span>
                            </p>
                        </div>
                        <div className='feature-img'>
                            <img src='/img1.jpg' alt='AI Interviews'></img>
                        </div>
                    </div>
                </div>

                <div className='Section4'>
                    <h1>Start Your Free Trial Today</h1>
                    <p>
                        Experience the power of AI-driven recruitment with our free trial. Explore all our features and see how GenieHire can transform your hiring process.
                    </p>
                    <button>Start Free Trial</button>
                </div>

                <Footer />
            </div>
        </>
    );
};

export default LandingPage;

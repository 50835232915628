import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../../css/mcqReport.css';
import host from '../../global';

function EvaluationPortal() {
  const { admin_id, interview_id } = useParams(); // Retrieve interview_id from URL parameters
  const [MCQresultId, setMCQresultId] = useState('');
  const [MCQTestResultData, setMCQTestResultData] = useState(null);
  const [candidateName, setCandidateName] = useState('');
  const [contact, setContact] = useState(0);

  useEffect(() => {

    const getcandidatedetails = async () => {
      try {
        const response = await axios.get(`${host}/getcandidatedetails/${interview_id}`);
        setCandidateName(response.data.candidate_name);
        setContact(response.data.contact);
      } catch (error) {
        console.error('Error:', error.message);
      }
    };

    getcandidatedetails();
  }, [interview_id]);

  useEffect(() => {
    const displayMCQTestResult = async () => {
      try {
        const response = await axios.post(`${host}/get_mcqs_test_result/${interview_id}`);
        setMCQTestResultData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    displayMCQTestResult();
  }, [interview_id]);

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 1500);
  }, []);

  return (
    <div className="reportPage">
      <div id='reportContent'>
        <header className="title">MCQ Test Report</header>
        {MCQTestResultData &&
        <div className='candidate-details'>
          <p><strong>Name: {candidateName}</strong></p>
          <p><strong>Contact: {contact}</strong></p>
          <p><strong>{MCQTestResultData.startDateTime}</strong></p>
          <p><strong>Total Score: {MCQTestResultData.total_score}</strong></p>
        </div>
        }
        {MCQTestResultData ? (
          Object.keys(MCQTestResultData.data).map((questionId) => (
          <div key={questionId} className="MCQResultquestionsReport">
          <p><strong>Question {Number(questionId)+1}:</strong> {MCQTestResultData.data[questionId].question}</p>
              <ul>
                <li>{MCQTestResultData.data[questionId].option1}</li>
                <li>{MCQTestResultData.data[questionId].option2}</li>
                <li>{MCQTestResultData.data[questionId].option3}</li>
                <li>{MCQTestResultData.data[questionId].option4}</li>
              </ul>
              <p><strong>Correct Answer:</strong> {MCQTestResultData.data[questionId].answer}</p>
              <p><strong>Selected Answer:</strong> {MCQTestResultData.data[questionId].given_answer}</p>
              <p><strong>Score:</strong> {MCQTestResultData.data[questionId].score}</p>
          </div>
          ))
         ) : (
           <p>...</p>
         )}
      </div>
    </div>
  );
}

export default EvaluationPortal;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';

function RoleRedirect() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function redirectBasedOnRole() {
      try {
        const session = await fetchAuthSession();
        const { accessToken } = session.tokens ?? {};
        const groups = accessToken.payload['cognito:groups'] ?? [];
        const { userId } = await getCurrentUser();

        // Redirect based on the user's group
        if (groups.includes('ConvAI_Admin')) {
          navigate(`/admin/${userId}/geniehireadmin`);
        } else if (groups.includes('Company_Admin')) {
          navigate(`/org-admin/${userId}`);
        } else if (groups.includes('HR')) {
          navigate(`/hr/${userId}`);
        } else {
          navigate('/not-found'); 
        }
      } catch (err) {
        console.log(err);
        navigate('/not-found'); 
      } finally {
        setLoading(false);
      }
    }
    redirectBasedOnRole();
  }, [navigate]);

  return loading ? <div className="loader"></div> : null;
}

export default RoleRedirect;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CheckResume from './components/hrportal/CheckResume.js';
import CheckResumeOrgAdmin from './components/orgadmin/CheckResumeOrgAdmin.js';
import AdminLogin from './pages/LoginPage';
import AdminPortal from './components/hrportal/HRPortal.js';
import InterviewPortal from './pages/Candidate Pages/InterviewPortal.js';
import EvaluationPortal from './components/hrportal/EvaluationReport';
import EvatuationReportOrgAdmin from './components/orgadmin/EvatuationReportOrgAdmin.js';
import ThankYou from './pages/Candidate Pages/ThanksPage.js';
import BulkResumeUpload from './components/hrportal/BulkResumeParser.js';
import MCQTest from './pages/Candidate Pages/MCQTestPage.js';
import UserDashboard from './pages/Candidate Pages/userDashboard.js';
import TestHomePage from './pages/Candidate Pages/testHomePage.js';
import MCQReportPage from './pages/Report Pages/mcqReport.js';
import CodingReportPage from './pages/Report Pages/codingReport.js';
import InterviewReportPage from './pages/Report Pages/interviewReport.js';
import MCQInstructions from './components/Instructions/MCQInstructions.jsx'
import CodingInstructions from './components/Instructions/CodingInstructions.jsx'
import InterviewInstructions from './components/Instructions/InterviewInstructions.jsx'
import CodingProblemsPage from './pages/Candidate Pages/CodingProblemsPage.js';
import NotFound from './pages/NotFound';
import OrganizationAdmin from './components/orgadmin/OrganizationAdminHome.js';
import HRPortal from './components/hrportal/HRPortal.js';
import RoleRedirect from './pages/RoleRedirect';
import HrProfile from './components/orgadmin/HrProfiles';
import AdminAnalytics from './components/orgadmin/Analytics';
import HRAnalytics from './components/hrportal/Analytics';
import CreatedJDs from './components/orgadmin/ViewCreatedJDs';
import CreateMCQSet from './components/orgadmin/createMCQSet';
import Fetch from './components/orgadmin/fetchmcq';
import ConvaiAdminDashboard from './pages/GenieHireAdminDash.js';
import CheckReportHR from './components/hrportal/checkreport';
import CreateCodingProblemSet from './components/orgadmin/CreateCodingProblemSet.js';
import CheckReportOrgAdmin from './components/orgadmin/checkreport';
import NewLandingPage from './pages/LandingPage/newLandingPage';
import FeaturesPage from './pages/LandingPage/FeaturesPage';
import PricingPage from './pages/LandingPage/PricingPage';
import SupportPage from './pages/LandingPage/SupportPage';
import AboutUsPage from './pages/LandingPage/AboutUs';
import DemoPage from './pages/LandingPage/DemoPage.js';
import Bulkresumemodel from './components/hrportal/BulkResumeModel';
import OrgAdminEditProfile from './components/orgadmin/OrgAdminEditProfile.js';
import HREditProfile from './components/hrportal/HREditProfile.js';
import ScheduleTest from './pages/Candidate Pages/ScheduleTest.js';

const App = () => {
  return (
    <Router>
      <Routes>

      {/* Catch-all route for undefined paths */}
      <Route path="*" element={<NotFound />} />

        {/* Landing Page Routes  */}
        <Route path="/" element={<NewLandingPage />} />
        <Route path="/demo" element={<DemoPage />} />
        <Route path="/features" element={<FeaturesPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/aboutus" element={<AboutUsPage />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/role-redirect" element={<RoleRedirect />} />

        {/* GenieHire Admin Routes  */}
        <Route path="/admin/:admin_id/geniehireadmin" element={<ConvaiAdminDashboard />} />

        {/* Organization Admin Routes  */}
        <Route path="/org-admin/:orgadminId" element={<OrganizationAdmin />} />
        <Route path="/org-admin/:orgadminId/checkreport" element={<CheckReportOrgAdmin />} />
        <Route path="/org-admin/:orgadminId/hrprofiles" element={<HrProfile />} />
        <Route path="/org-admin/:orgadminId/editprofile" element={<OrgAdminEditProfile />} />
        <Route path="/org-admin/:orgadminId/fetchmcq" element={<Fetch />} />
        <Route path="/org-admin/:orgadminId/created-jds" element={<CreatedJDs />} />
        <Route path="/org-admin/:orgadminId/analytics" element={<AdminAnalytics />} />
        <Route path="/org-admin/:orgadminId/checkresume" element={<CheckResumeOrgAdmin />} />
        <Route path="/org-admin/evaluationpage/:orgadminId/:interview_id" element={<EvatuationReportOrgAdmin />} />
        <Route path="/org-admin/:orgadminId/createmcqset" element={<CreateMCQSet />} />
        <Route path='/org-admin/:orgadminId/createcodingproblemset' element={<CreateCodingProblemSet />} />

        {/* HR Routes  */}
        <Route path="/hr/:hr_id/editprofile" element={<HREditProfile />} />
        <Route path="/hr/:hr_id" element={<HRPortal />} />
        <Route path="/hr/:hr_id/analytics" element={<HRAnalytics />} />
        <Route path="/hr/:hr_id" element={<AdminPortal />} />
        <Route path="/hr/:hr_id/checkreport" element={<CheckReportHR />} />
        <Route path="/hr/:hr_id/checkresume" element={<CheckResume />} />
        <Route path="/hr/:hr_id/BulkResume" element={<BulkResumeUpload />} />
        <Route path="/hr/:hr_id/BulkResume/Bulkresumemodel/:upload_id" element={<Bulkresumemodel/>} />
        <Route path="/hr/evaluationpage/:hr_id/:interview_id" element={<EvaluationPortal />} />

        {/* Report PrintPage Routes  */}
        <Route path='/mcqreport/:adminId/:interview_id' element={<MCQReportPage />} />
        <Route path='/codingreport/:hr_id/:interview_id' element={<CodingReportPage />} />
        <Route path='/interviewreport/:adminId/:interview_id' element={<InterviewReportPage />} />

        {/* User Routes  */}
        <Route path="/user/:interview_id/scheduletest" element={<ScheduleTest />} />
        <Route path="/user/:hr_id/mcqtest/:interview_id" element={<MCQTest />} />
        <Route path="/user/:hr_id/mcqtest/:interview_id/instructions" element={<MCQInstructions />} />
        <Route path="/user/:interview_id/userdashboard" element={<UserDashboard />} />
        <Route path="/user/:interview_id/testhomepage" element={<TestHomePage />} />
        <Route path="/user/:interview_id/codinginstructions" element={<CodingInstructions />} />
        <Route path="/user/:interview_id/CodingProblemsPage" element={<CodingProblemsPage />} />
        <Route path="/interview/:interview_id/instructions" element={<InterviewInstructions />} />
        <Route path="/interview/:interview_id" element={<InterviewPortal />} />
        <Route path="/thankyou/:interview_id" element={<ThankYou />} />
      </Routes>
    </Router>
  );
};

export default App;
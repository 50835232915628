import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from '../aws-exports';
import { useNavigate } from 'react-router-dom';
import '../css/loginPage.css';

// Configure Amplify with AWS settings
Amplify.configure(awsExports);

function Login() {
  // Hook to programmatically navigate to different routes
  const navigate = useNavigate();

  return (
    <div className='loginPageBody'>
      <div className='loginPageContent'>
        {/* Vertical rectangular block displaying an image */}
        <div className='verticalBlock'>
          <img src='/login.png' alt='Login Illustration' />
        </div>
        {/* Login section */}
        <div className='loginSection'>
          <div className='loginPage'>
            <h1 id='adminLoginHeading'>Login</h1>
            {/* Authenticator component from AWS Amplify UI for authentication */}
            <Authenticator>
              {({ signOut, user }) => {
                if (user) {
                  // Redirect to role-redirect page if user is authenticated
                  navigate('/role-redirect');
                  return null; // Render nothing while redirecting
                }
                return null; // Render nothing until the user is authenticated
              }}
            </Authenticator>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

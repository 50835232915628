import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../css/CompanyAdmin.css";
import host from "../../global";
import { useNavigate, useParams } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import HeaderBar from "./header";
import Sidebar from "./sidebar";
import { getCurrentUser } from "@aws-amplify/auth";
import CustomAlert from "../customalter";
import { fetchUserAttributes } from "aws-amplify/auth";
import { fetchAuthSession } from 'aws-amplify/auth';

const BulkResumeUpload = () => {
  const [jobPositions, setJobPositions] = useState([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [selectedJobPosition, setSelectedJobPosition] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [resume, setResume] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [responseReport, setResponseReport] = useState("");
  const [responseScore, setResponseScore] = useState(0);
  const [interviewID, setInterviewID] = useState("");
  const [MCQTimeLimit, setMCQTimeLimit] = useState(0);
  const [resumeName, setResumeName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailID, setEmailID] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isConfirmClicked, setIsConfirmClicked] = useState(false);
  const [resumeContent, setResumeContent] = useState("");
  const [isInterviewIDVisible, setIsInterviewIDVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedInterviewType, setSelectedInterviewType] = useState("");
  const [numberOfQuestion, setNumberOfQuestion] = useState(0);
  const [selectedSet, setSelectedSet] = useState("");
  const [sets, setSets] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [codingSets, setCodingSets] = useState([]);
  const [codingJudgingCriteria, setCodingJudgingCriteria] = useState([]);
  const [selectedCodingSet, setSelectedCodingSet] = useState("");
  const [isResumeUploaded, setIsResumeUploaded] = useState(false);
  const [isEmailVisible, setIsEmailVisible] = useState(false);
  const [hrEmail, setHrEmail] = useState("");
  const [CodingTimeLimit, setCodingTimeLimit] = useState(0);
  const navigate = useNavigate();
  const { hr_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
useEffect(() => {
  const checkSession = async () => {
    try {
      const session = await fetchAuthSession();
      const { accessToken } = session.tokens ?? {};
      const groups = accessToken.payload['cognito:groups'] ?? [];
      const { userId } = await getCurrentUser();
      
      if (groups.includes('HR') && userId === hr_id) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  checkSession();
}, []);

  useEffect(() => {
    const getUserAttributes = async () => {
      const attributes = await fetchUserAttributes();
      setHrEmail(attributes["email"]);
      console.log(hrEmail);
    };
    const fetchJobPositions = async () => {
      try {
        const response = await axios.get(
          `${host}/getjdpositions/${hr_id}`
        );
        setJobPositions(response.data);
      } catch (error) {
        console.error("Error fetching job positions:", error);
      }
    };
    getUserAttributes();
    fetchJobPositions();
  }, [hr_id, hrEmail]);

  useEffect(() => {
    async function checkAuth() {
      try {
        await getCurrentUser();
      } catch (error) {
        navigate("/admin/login");
      }
    }

    checkAuth();
  }, [navigate]);

  const handleNotify = () => {
    setIsVisible(!isVisible);
  };

  const handleEmailChange = (e) => {
    setEmailID(e.target.value);
  };

  const handleJobPositionChange = async (e) => {
    const selectedPosition = e.target.value;
    setSelectedJobPosition(selectedPosition);
    setJobDescription("");
    setErrorMessage("");
    setResponseReport("");
    setResponseScore(0);
    setInterviewID("");
    setIsSubmitting(false);
    setEmailID("");
    setIsVisible(false);
    setIsCheckboxChecked(false);
    setIsConfirmClicked(false);
    setResumeContent("");
    setIsInterviewIDVisible(false);
    try {
      const response = await axios.get(
        `${host}/${hr_id}/getjd?job_position=${selectedPosition}`
      );
      if (response) {
        setJobDescription(response.data.description);
        setCodingTimeLimit(response.data.coding_time_limit);
        setMCQTimeLimit(response.data.mcq_time_limit);
        setNumberOfQuestion(response.data.number_of_questions);
        setSelectedInterviewType(response.data.interview_type);
        setSelectedCodingSet(response.data.Coding_set);
        setSelectedSet(response.data.mcq_set);
        setCodingJudgingCriteria(response.data.Coding_Judge_Crieteria);
      }
    } catch (error) {
      console.error("Error fetching job description:", error);
      setJobDescription("");
    }
  };

  const handleResumeChange = (e) => {
    const file = e.target.files[0];

    // Check if the file exists
    if (file) {
      setResumeName(file.name);

      // Validate the file extension to ensure it's a .zip file
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (fileExtension === "zip") {
        setErrorMessage("");
        setResume(file);
        console.log("Valid ZIP file selected:", file);
        setIsResumeUploaded(true);
      } else {
        setErrorMessage("Only ZIP files are allowed.");
        setResume(null);
        console.log("Invalid file type selected");
      }
    } else {
      setErrorMessage("Please select a file.");
      setResume(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedJobPosition || !jobDescription) {
      setErrorMessage(
        "Please select a job position and load the job description."
      );
      return;
    }

    if (!resume) {
      setErrorMessage("Please upload a resume.");
      return;
    }

    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("job_role", selectedJobPosition);
    formData.append("job_description", jobDescription);
    formData.append("file", resume);


    try {
         axios.post(
        `${host}/Bulkupload/${hr_id}/${selectedInterviewType}/${numberOfQuestion}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setIsSubmitting(false);
      setTimeout(() => {
        setAlertMessage("Submission successful!");
        setAlertVisible(true);
        setTimeout(() => {
          setAlertVisible(false);
        }, 2000);
      }, 1000);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      } catch (error) {
      console.error("Error uploading resume:", error);
    }
  };

  if (isLoading) {
    return <div className="loader"></div>;
  }

  if (!isAuthenticated) {
    return (
      <div className="AuthenticationMessageContainer">
        <div className="AuthenticationMessage">
      <p>
        You are not Authorized to access this Page!
      </p>
      <button onClick={() => navigate('/admin/login')}> Login Page</button>
      </div>
      </div>
    );
  }
  
  return (
    <Authenticator>
      <HeaderBar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        hr_id={hr_id}
      />
      <div className="company-admin-container">
      {alertVisible && <CustomAlert message={alertMessage} />}
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          hr_id={hr_id}
        />
        <div className="content">
          <div className="maincontent">
            <div className={"showFull"}>
              <form onSubmit={handleSubmit}>
                <select
                  value={selectedJobPosition}
                  onChange={handleJobPositionChange}
                >
                  <option value="">Select Job Position</option>
                  {jobPositions.map((position, index) => (
                    <option key={index} value={position}>
                      {position}
                    </option>
                  ))}
                </select>
                <div id="textareaDiv">
                  <textarea
                    placeholder="Job Description"
                    value={jobDescription}
                    onChange={(e) => setJobDescription(e.target.value)}
                  />
                </div>
                <div className="file-control">
                  <label className="file-upload-button">
                    <span>Upload Resume</span>
                    <input
                      type="file"
                      accept=".zip"
                      onChange={handleResumeChange}
                    />
                  </label>
                  {resumeName && <p>{resumeName}</p>}
                </div>
                <div className="button-container">
                  {!isSubmitting ? (
                    <button id="submitBtnAdmin" type="submit">
                      Submit
                    </button>
                  ) : (
                    <div className="loading-animation">Loading...</div>
                  )}
                </div>
                {errorMessage && <p className="error">{errorMessage}</p>}
                {responseScore >= 70 && (
                  <div id="notifyCandidateBtn" onClick={handleNotify}>
                    Notify Candidate
                  </div>
                )}
                {isEmailVisible && (
                  <form id="notifyForm">
                    <input
                      value={emailID}
                      type="email"
                      placeholder="Enter Email"
                      onChange={handleEmailChange}
                    />
                    {/* <button onClick={sendNotification}>Send</button> */}
                  </form>
                )}
                {/* </>
                    )}
                  </>
                )} */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </Authenticator>
  );
};

export default BulkResumeUpload;

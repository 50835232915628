// src/components/Footer.js
import React from 'react';
import '../../css/newLandingPage.css';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();
    return (
        <div className='footer'>
            <div className='footer-links'>
                <ul>
                    <li><strong>Company</strong></li>
                    <li onClick={() => {
                        navigate('/aboutus');
                        window.scrollTo(0, 0);
                    }}>About</li>
                    <li>Features</li>
                    <li>Works</li>
                    <li>Career</li>
                </ul>
                <ul>
                    <li><strong>Help</strong></li>
                    <li>Customer Support</li>
                    <li>Delivery Details</li>
                    <li>Terms & Conditions</li>
                    <li>Privacy Policy</li>
                </ul>
                <ul>
                    <li><strong>Resources</strong></li>
                    <li>Documentation</li>
                    <li>Tutorials</li>
                    <li>How to - Blog</li>
                    <li>Youtube Playlist</li>
                </ul>
            </div>
            <hr />
            <div className='footer-end'>
                <p>© Copyright 2024, All Rights Reserved by Geniehire.AI</p>
                <div className='footer-socials'>
                    <p>Connect With Us: </p>
                    <img src='/linkedin.png' alt='linkedin' />
                    <img src='/github.png' alt='facebook' />
                    <img src='/instagram.png' alt='instagram' />
                    <img src='/twitter.png' alt='twitter' />
                </div>
            </div>
        </div>
    );
};

export default Footer;

import React from 'react';
import '../../css/newLandingPage.css';
import Header from './Header';
import Footer from './Footer';

const AboutUsPage = () => {
    return (
        <div className='LP'>
            <div className='LP-bkg' style={{ height: '70vh' }}></div>
            <div className='Section1' style={{ height: '70vh' }}>
                <Header />
                <div className='welcomeText'>
                    <h1 className='pageTitle'>About Us</h1>
                    <p className='description'>
                        At GenieHire, we are committed to revolutionizing the recruitment process through cutting-edge AI technology. Our mission is to empower organizations to make data-driven hiring decisions and streamline their recruitment workflows.
                    </p>
                </div>
            </div>

            <div className='AboutContent'>
                <div className='AboutCard'>
                    <h2 className='sectionTitle'>Our Mission</h2>
                    <p className='sectionDescription'>
                        Our mission is to redefine recruitment by harnessing the power of artificial intelligence. We strive to deliver innovative solutions that simplify and enhance every aspect of the hiring process, from resume parsing to candidate evaluation.
                    </p>
                </div>
                <hr />

                <div className='AboutCard'>
                    <h2 className='sectionTitle'>Our Vision</h2>
                    <p className='sectionDescription'>
                        We envision a world where recruitment is seamless, data-driven, and devoid of biases. By integrating advanced AI technologies, we aim to make hiring more efficient and fair, ultimately contributing to the success of organizations and the growth of their teams.
                    </p>
                </div>
                <hr />

                <div className='AboutCard'>
                    <h2 className='sectionTitle'>Our Values</h2>
                    <ul className='valuesList'>
                        <li><strong>Innovation:</strong> We are dedicated to pushing the boundaries of AI technology to offer groundbreaking solutions.</li>
                        <li><strong>Integrity:</strong> We uphold the highest standards of transparency and ethical practices in everything we do.</li>
                        <li><strong>Customer-Centricity:</strong> Our clients are at the heart of our business. We prioritize their needs and work tirelessly to exceed their expectations.</li>
                        <li><strong>Collaboration:</strong> We believe in the power of teamwork and collaboration, both within our company and with our clients.</li>
                    </ul>
                </div>
            </div>

            <div className='TeamSection'>
                <h1 className='sectionTitle'>Meet the Team</h1>
                <div className='teamMembers'>
                    <div className='teamMember'>
                        <img src='/team1.jpg' alt='John Doe' className='teamPhoto' />
                        <h2>John Doe</h2>
                        <p><strong>Founder & CEO</strong></p>
                        <p>John leads GenieHire with a passion for technology and innovation. With over 15 years of experience in the tech industry, he is dedicated to driving the company's mission forward.</p>
                    </div>
                    <div className='teamMember'>
                        <img src='/team2.jpg' alt='Jane Smith' className='teamPhoto' />
                        <h2>Jane Smith</h2>
                        <p><strong>Chief Technology Officer</strong></p>
                        <p>Jane is the mastermind behind our AI algorithms. Her expertise in machine learning and data science ensures that our solutions are both cutting-edge and effective.</p>
                    </div>
                    <div className='teamMember'>
                        <img src='/team3.jpg' alt='Michael Brown' className='teamPhoto' />
                        <h2>Michael Brown</h2>
                        <p><strong>Head of Customer Success</strong></p>
                        <p>Michael is dedicated to ensuring our clients have the best possible experience with GenieHire. He leads our customer support team with a focus on delivering exceptional service.</p>
                    </div>
                </div>
            </div>

            <div className='StorySection'>
                <h1 className='sectionTitle'>Our Story</h1>
                <p className='storyDescription'>
                    GenieHire was founded with the vision of transforming recruitment through AI. What started as a small project has evolved into a leading provider of AI-driven hiring solutions. Our journey has been marked by innovation, growth, and a relentless commitment to improving the recruitment process for companies of all sizes.
                </p>
                <button className='ctaButton'>Learn More About Us</button>
            </div>

            <Footer />
        </div>
    );
};

export default AboutUsPage;

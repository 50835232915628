import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../css/BulkResumeModel.css";
import { useParams } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import HeaderBar from "./header";
import Sidebar from "./sidebar";
import { useNavigate } from "react-router-dom";
import host from "../../global";
import Modal from "react-modal";
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';

Modal.setAppElement("#root");

function App() {
  const [candidates, setCandidates] = useState([]);
  const [shortlisted, setShortlisted] = useState([]);
  const [retrofit, setRetrofit] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]); // To track selected candidates
  const [invalidData, setInvalidData] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newEmail, setNewEmail] = useState("");
  const { hr_id } = useParams();
  const { upload_id } = useParams();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const [nameFilter, setNameFilter] = useState("");
  const [minScoreFilter, setMinScoreFilter] = useState(0);
  const [maxScoreFilter, setMaxScoreFilter] = useState(100);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentReport, setCurrentReport] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
useEffect(() => {
  const checkSession = async () => {
    try {
      const session = await fetchAuthSession();
      const { accessToken } = session.tokens ?? {};
      const groups = accessToken.payload['cognito:groups'] ?? [];
      const { userId } = await getCurrentUser();
      
      if (groups.includes('HR') && userId === hr_id) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  checkSession();
}, []);

  useEffect(() => {
    axios
      .get(`${host}/resumes/${upload_id}`)
      .then((response) => {
        const fetchedCandidates = response.data.data;
        if (Array.isArray(fetchedCandidates)) {
          const filteredCandidates = fetchedCandidates
            .filter((candidate) =>
              candidate.name.toLowerCase().includes(nameFilter.toLowerCase())
            )
            .filter(
              (candidate) =>
                candidate.score >= minScoreFilter &&
                candidate.score <= maxScoreFilter
            );

          const sortedCandidates = filteredCandidates.sort(
            (a, b) => b.score - a.score
          );

          setCandidates(sortedCandidates);
          setShortlisted(sortedCandidates.filter((c) => c.score >= 60));
          setRetrofit(
            sortedCandidates.filter((c) => c.score < 60 && c.emails !== "")
          );
          setInvalidData(
            sortedCandidates.filter((c) => !c.emails || c.score === null)
          );
        } else {
          console.error("Unexpected data format:", fetchedCandidates);
        }
      })
      .catch((error) => {
        console.error("Error fetching candidates:", error);
        navigate(`/hr/${hr_id}`);
      });
  }, [upload_id, nameFilter, minScoreFilter, maxScoreFilter]);

  const scheduleInterviewForAll = () => {
    axios
      .post(
        `${host}/schedule_interviews/${hr_id}/${upload_id}`
      )
      .then((response) => {
        console.log("Interview scheduled for all candidates:", response.data);
        window.alert("Interviews have been scheduled for all candidates.");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error scheduling interviews:", error);
        window.alert(
          "An error occurred while scheduling interviews. Please try again."
        );
      });
  };

  const handleReportClick = (candidate) => {
    setCurrentReport(candidate.report); // Set the report for the modal
    setModalIsOpen(true); // Open the modal
  };

  const closeModal = () => {
    setModalIsOpen(false); // Close modal when user clicks close button
    setCurrentReport(null); // Reset current report
  };

  const validateData = (email) => {
    console.log(`Validating data for candidate with email: ${email}`);
  };

  // const shortlistCandidate = (email) => {
  //   const candidateToShortlist = retrofit.find((c) => c.emails === email);
  //   if (candidateToShortlist) {
  //     setRetrofit(retrofit.filter((c) => c.emails !== email));
  //     setShortlisted([...shortlisted, candidateToShortlist]);
  //   }
  // };

  const startEditingEmail = (index, currentEmail) => {
    setEditingIndex(index);
    setNewEmail(currentEmail);
  };

  const saveEmailChange = (index) => {
    const updatedCandidates = shortlisted.map((candidate, idx) =>
      idx === index ? { ...candidate, emails: newEmail } : candidate
    );
    setShortlisted(updatedCandidates);
    setEditingIndex(null);
    setNewEmail("");

    axios
      .put(`${host}/updatecandidate/${hr_id}/${upload_id}`, {
        emails: newEmail,
        index: index,
      })
      .then((response) => {
        console.log("Email updated successfully:", response);
      })
      .catch((error) => {
        console.error(
          "Error updating email:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const handleCheckboxChange = (candidate) => {
    setSelectedCandidates((prevSelected) => {
      if (prevSelected.includes(candidate)) {
        return prevSelected.filter((c) => c !== candidate);
      } else {
        return [...prevSelected, candidate];
      }
    });
  };

  // Delete selected candidates
  const deleteSelectedCandidates = () => {
    const selectedIndices = selectedCandidates.map(
      (candidate) => candidate.index_number
    );

    axios
      .delete(
        `${host}/deletecandidateBulkUpload/${hr_id}/${upload_id}`,
        {
          data: { indices: selectedIndices },
        }
      )
      .then(() => {
        console.log("Selected candidates deleted successfully");
        window.alert("Selected candidates have been deleted.");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error deleting selected candidates:", error);
        window.alert("An error occurred while deleting candidates.");
      });
  };

  const shortlistSelectedCandidates = () => {
    const candidatesToShortlist = selectedCandidates.filter(
      (candidate) => candidate.score < 60
    );

    setRetrofit(retrofit.filter((c) => !candidatesToShortlist.includes(c)));
    setShortlisted([...shortlisted, ...candidatesToShortlist]);
    setSelectedCandidates([]);
  };

  if (isLoading) {
    return <div className="loader"></div>;
  }

  if (!isAuthenticated) {
    return (
      <div className="AuthenticationMessageContainer">
        <div className="AuthenticationMessage">
      <p>
        You are not Authorized to access this Page!
      </p>
      <button onClick={() => navigate('/admin/login')}> Login Page</button>
      </div>
      </div>
    );
  }

  return (
    <Authenticator>
      <HeaderBar
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        hr_id={hr_id}
      />
      <div className="company-admin-container">
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          hr_id={hr_id}
        />
        <div className="content">
          <div className="maincontent">
            <div className={"showFull"}>
              <h1 className="app-header">Candidate Management</h1>
              {/* <div className="filters"> */}
              <div className="filters">
                <h3>Filters</h3>
                <div className="filter-group">
                  <label htmlFor="nameFilter">Name:</label>
                  <input
                    id="nameFilter"
                    type="text"
                    placeholder="Search by name"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                  />
                </div>
                <div className="filter-group">
                  <label htmlFor="minScoreFilter">Min Score:</label>
                  <input
                    id="minScoreFilter"
                    type="number"
                    placeholder="0"
                    value={minScoreFilter}
                    onChange={(e) => setMinScoreFilter(Number(e.target.value))}
                  />
                </div>
                <div className="filter-group">
                  <label htmlFor="maxScoreFilter">Max Score:</label>
                  <input
                    id="maxScoreFilter"
                    type="number"
                    placeholder="100"
                    value={maxScoreFilter}
                    onChange={(e) => setMaxScoreFilter(Number(e.target.value))}
                  />
                </div>
                <div className="filter-group">
                  <label htmlFor="maxScoreFilter"></label>
                  <button
                    className="app-button"
                    style={{ marginTop: "23px" }}
                    onClick={() => {
                      setNameFilter("");
                      setMinScoreFilter(0);
                      setMaxScoreFilter(100);
                    }}
                  >
                    Clear Filters
                  </button>
                </div>
              </div>
              {/* </div> */}
              {(nameFilter.length > 0 ||
                minScoreFilter.length > 0 ||
                maxScoreFilter.length > 0) && (
                <div className="filter-summary">
                  <h4>Filters applied:</h4>
                  <ul>
                    {nameFilter && <li>Name: {nameFilter}</li>}
                    {minScoreFilter > 0 && <li>Min Score: {minScoreFilter}</li>}
                    {maxScoreFilter < 100 && (
                      <li>Max Score: {maxScoreFilter}</li>
                    )}
                  </ul>
                </div>
              )}
              {shortlisted.length > 0 && (
                <section className="app-section">
                  <h2>Shortlisted Candidates</h2>
                  <table className="app-table">
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Score</th>
                        <th>Report</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {shortlisted.map((candidate, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{candidate.name}</td>
                          <td>
                            {editingIndex === index ? (
                              <input
                                type="text"
                                value={newEmail}
                                onChange={(e) => setNewEmail(e.target.value)}
                              />
                            ) : (
                              candidate.emails
                            )}
                          </td>
                          <td>{candidate.score}</td>
                          <td>
                            <div
                              className="tooltip-icon"
                              onClick={() => handleReportClick(candidate)}
                            >
                              <img
                                src="/eye.png"
                                alt="View Report"
                                style={{ height: "30px", width: "30px" }}
                              />
                              <span className="tooltip-text">View Report</span>
                            </div>
                          </td>

                          <td>
                            {editingIndex === index ? (
                              <button
                                className="app-button"
                                onClick={() => saveEmailChange(index)}
                              >
                                Save
                              </button>
                            ) : (
                              <button
                                className="app-button"
                                onClick={() =>
                                  startEditingEmail(index, candidate.emails)
                                }
                              >
                                Edit
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {/* Conditionally render the Schedule Interviews for All button */}
                  {invalidData.length === 0 && retrofit.length === 0 && (
                    <button
                      className="app-button"
                      onClick={scheduleInterviewForAll}
                    >
                      Schedule Interviews for All
                    </button>
                  )}

                  {/* Modal to display report */}
                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Candidate Report"
                    className="Modal"
                    overlayClassName="Overlay"
                  >
                    <h2>Candidate Report</h2>
                    <close-button onClick={closeModal}>Close</close-button>
                    {currentReport && <p>{currentReport}</p>}{" "}
                    {/* Show report content */}
                  </Modal>
                </section>
              )}

              {retrofit.length > 0 && (
                <section className="app-section">
                  <h2>Retrofit Candidates</h2>
                  <table className="app-table">
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Score</th>
                        <th>Report</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {retrofit.map((candidate, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{candidate.name}</td>
                          <td>{candidate.emails}</td>
                          <td>{candidate.score}</td>
                          <td>
                            <div
                              className="tooltip-icon"
                              onClick={() => handleReportClick(candidate)}
                            >
                              <img
                                src="/eye.png"
                                alt="View Report"
                                style={{ height: "30px", width: "30px" }}
                              />
                              <span className="tooltip-text">View Report</span>
                            </div>
                          </td>

                          <td>
                            <input
                              type="checkbox"
                              checked={selectedCandidates.includes(candidate)}
                              onChange={() => handleCheckboxChange(candidate)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    {/* Modal to display report */}
                    <Modal
                      isOpen={modalIsOpen}
                      onRequestClose={closeModal}
                      contentLabel="Candidate Report"
                      className="Modal"
                      overlayClassName="Overlay"
                    >
                      <h2>Candidate Report</h2>
                      <close-button onClick={closeModal}>Close</close-button>
                      {currentReport && <p>{currentReport}</p>}{" "}
                      {/* Show report content */}
                    </Modal>
                  </table>
                  {selectedCandidates.length > 0 && (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <button
                        className="app-button"
                        onClick={deleteSelectedCandidates}
                      >
                        Delete Selected Candidates
                      </button>
                      <button
                        className="app-button"
                        onClick={shortlistSelectedCandidates}
                      >
                        Shortlist Selected Candidates
                      </button>
                    </div>
                  )}
                </section>
              )}

              {invalidData.length > 0 && (
                <section className="app-section">
                  <h2>Invalid Data</h2>
                  <table className="app-table">
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Score</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invalidData.map((candidate, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{candidate.name}</td>
                          <td>{candidate.emails}</td>
                          <td>{candidate.score}</td>
                          <td>
                            <button
                              className="app-button"
                              onClick={() => validateData(candidate.emails)}
                            >
                              Validate
                            </button>
                            <button
                              className="app-button"
                              onClick={() =>
                                deleteSelectedCandidates(candidate.index_number)
                              }
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </section>
              )}
            </div>
          </div>
        </div>
      </div>
    </Authenticator>
  );
}

export default App;

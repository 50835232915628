import React, { useEffect, useState } from 'react';
import Header from './header'; 
import Sidebar from './sidebar';
import '../../css/CompanyAdmin.css';
import { Authenticator } from '@aws-amplify/ui-react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import host from '../../global';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';

const Analytics = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [totalCandidates, setTotalCandidates] = useState(null);
  const [mcqPassed, setMcqPassed] = useState(null);
  const [codingPassed, setCodingPassed] = useState(null);
  const [interviewPassed, setInterviewPassed] = useState(null);
  const { hr_id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    async function redirectBasedOnRole() {
      try {
        const session = await fetchAuthSession();
        const { accessToken } = session.tokens ?? {};
        const groups = accessToken.payload['cognito:groups'] ?? [];
        const { userId } = await getCurrentUser();

        if (groups.includes('HR') && userId === hr_id) {
          setIsAuthorized(true);
        } else {
          setIsAuthorized(false);
        }
      } catch (err) {
        console.log(err);
        setIsAuthorized(false);
      } finally {
        setLoading(false);
      }
    }
    redirectBasedOnRole();
  }, [hr_id, navigate]);

  useEffect(() => {
    const fetchTotalCandidates = async () => {
      try {
        const response = await axios.get(`${host}/hr/candidate-stats/${hr_id}`);
        setTotalCandidates(response.data.total_candidates);
        setMcqPassed(response.data.mcq_passed);
        setCodingPassed(response.data.coding_passed);
        setInterviewPassed(response.data.interview_passed);
      } catch (error) {
        console.error('Error fetching total candidates:', error);
      }
    };

    if (isAuthorized) {
      fetchTotalCandidates();
    }
  }, [hr_id, isAuthorized]);

  const handleViewReports = () => {
    navigate(`/hr/${hr_id}/checkreport`);
  };

  return (
    <>
    {loading ? (
      <div className='loader'></div>
    ): !isAuthorized ?(
      <div className="AuthenticationMessageContainer">
        <div className="AuthenticationMessage">
      <p>
        You are not Authorized to access this Page!
      </p>
      <button onClick={() => navigate('/admin/login')}> Login Page</button>
      </div>
      </div>
    ):(
      <Authenticator>
        <div>
          <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} hr_id={hr_id} />
          <div className="company-admin-container">
            <Sidebar isSidebarOpen={isSidebarOpen} hr_id={hr_id} />
            <div className={`content ${isSidebarOpen ? 'sidebarIsOpen' : ''}`}>
              <h1>Analytics</h1>
              <p>Welcome to the Analytics page!</p>
              <div className='charts'>
                <p>Total Candidates: </p>
                <p>Candidates who passed MCQ: </p>
                <p>Candidates who passed Coding: </p>
                <p>Candidates who passed Interview: </p>
              </div>
              <div className='charts'>
                <div className="hr-profiles-circle">
                  {totalCandidates !== null ? totalCandidates : 'Loading...'}
                </div>
                <div className="hr-profiles-circle">
                  {mcqPassed !== null ? mcqPassed : 'Loading...'}
                </div>
                <div className="hr-profiles-circle">
                  {codingPassed !== null ? codingPassed : 'Loading...'}
                </div>
                <div className="hr-profiles-circle">
                  {interviewPassed !== null ? interviewPassed : 'Loading...'}
                </div>
              </div>
              <button onClick={handleViewReports} className="addhr">View Reports</button>
            </div>
          </div>
        </div>
      </Authenticator>
  )}
  </>
);
};

export default Analytics;

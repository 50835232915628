import React, { useEffect, useState } from 'react';
import TicketModal from './TicketModal';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios'; 
import host from '../../global';

const Header = ({ isSidebarOpen, setIsSidebarOpen ,orgadminId}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isProfilePopupVisible, setIsProfilePopupVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const [pendingAccounts, setPendingAccounts] = useState([]);
  const navigate = useNavigate();
  const [adminData,setAdminData] = useState([])

  const handleTicketClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {

    const fetchPendingAccountRequest  = async() =>{
      try {
        const response1 = await axios.get(`${host}/${orgadminId}/pendingaccounts`);
        setPendingAccounts(response1.data);
      } catch (error) {
        console.error('Error fetching Profile Data', error);
      }
    }
    
    const fetchProfileData = async() =>{
    try {
      const response2 = await axios.get(`${host}/getadmindetails/${orgadminId}`);
      setAdminData(response2.data);
    } catch (error) {
      console.error('Error fetching Data', error);
    }
  }

  fetchProfileData();
  fetchPendingAccountRequest();
  },[orgadminId]);

  const handleNotificationClick = async (event) => {
    if(isProfilePopupVisible){
      setIsProfilePopupVisible(false)
    }
    const { top, left, height } = event.currentTarget.getBoundingClientRect();
    setPopupPosition({ top: top + height, left: left });
    setIsPopupVisible(!isPopupVisible);
  };

  const handleProfileClick = async () =>{
    if(isPopupVisible){
      setIsPopupVisible(false)
    }
    setIsProfilePopupVisible(!isProfilePopupVisible);
  }

  return (
    <>
      <div className='header'>
        <div className='leftheaderchild'>
          <div className='menu-bar' onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            ☰
          </div>
          <img src='/GenieHire-logo-black.png' alt='Notification' style={{position: 'absolute',height:'40px',marginLeft:'40px',cursor:'pointer'}}
            onClick={() => navigate(`/org-admin/${orgadminId}`)}
          />
        </div>
        <div className='rightheaderchild'>
          <button 
            className='notification-btn' 
            onClick={handleNotificationClick}
          >
            <img 
              src='/notificationbell.png' 
              alt='Notification' 
              className='notification-icon' 
            />
          </button>
          {isPopupVisible && (
            <div 
              className='notification-popup'
            >
              <h4>Profile Creation Approval Pending:</h4>
              {pendingAccounts.length > 0 ? (
                <ul>
                  {pendingAccounts.map((name, index) => (
                    <li key={index}>{index + 1}. {name}</li>
                  ))}
                </ul>
              ) : (
                <p>No pending approvals.</p>
              )}
            </div>
          )}
          <button className='action-btn' onClick={handleTicketClick}>RaiseTicket</button>
                    <img 
            src={adminData.profile || '/dummyprofile.png' }
            alt='Profile' 
            className='profile-icon' 
            style={{ height: '40px' ,width: '40px',border:"solid 1px white" ,cursor: 'pointer'}} 
            onClick={handleProfileClick}
          />
          {isProfilePopupVisible && (
        <div className="profile-popup">
          <h3>{adminData.name}</h3>
          <p>{adminData.email}</p>
          <button onClick={() => navigate(`/org-admin/${orgadminId}/editprofile`)}>
            View Complete Profile
          </button>
        </div>
      )}
        </div>
      </div>
      <TicketModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default Header;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import host from '../../global';
import { ScheduleMeeting } from 'react-schedule-meeting';
import Modal from 'react-modal';
import '../../css/ScheduleTest.css';

Modal.setAppElement('#root');

const ScheduleTest = () => {
  const { interview_id } = useParams();
  const navigate = useNavigate(); // Create navigate function

  const [selectedDate, setSelectedDate] = useState(null);
  const [startdate, setStartdate] = useState(null);
  const [enddate, setEnddate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [jobRole, setJobRole] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  useEffect(() => {
    const fetchTimeFrame = async () => {
      try {
        const response = await axios.get(`${host}/get-timeframe-testdata/${interview_id}`);
        const { TimeFrameForTest, job_role, job_description } = response.data;

        setJobRole(job_role);
        setJobDescription(job_description);

        const { start_date, end_date } = TimeFrameForTest;
        const today = new Date();
        const startDate = new Date(start_date);
        const endDate = new Date(end_date);

        if (startDate.getHours() < 13) {
          startDate.setHours(13, 0, 0, 0);
        }

        setStartdate(startDate);
        setEnddate(endDate);

        if (today > endDate) {
          setError('The time frame for selecting a date has expired.');
        }

        setLoading(false);
      } catch (err) {
        setError('Failed to fetch the interview schedule');
        setLoading(false);
      }
    };

    fetchTimeFrame();
  }, [interview_id]);

  const handleStartTimeSelect = (startTimeEventEmit) => {
    const selectedTime = startTimeEventEmit.startTime;
    const roundedTime = new Date(selectedTime);
    roundedTime.setMinutes(Math.round(selectedTime.getMinutes() / 60) * 60);
    roundedTime.setSeconds(0);
    roundedTime.setMilliseconds(0);

    setSelectedDate(roundedTime);
    setIsScheduleModalOpen(false);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openScheduleModal = () => setIsScheduleModalOpen(true);
  const closeScheduleModal = () => setIsScheduleModalOpen(false);

  const handleSubmit = async () => {
    if (!selectedDate) {
      setSubmitError('Please select a date and time before submitting.');
      return;
    }
    const formattedDate = selectedDate.toISOString();
    try {
      const response = await axios.post(`${host}/schedule-test/${interview_id}/${formattedDate}`);

      if (response.status === 200) {
        setIsSubmitClicked(true);
        setSubmitError(null);
      } else {
        setSubmitError('Failed to submit the schedule.');
      }
    } catch (err) {
      setSubmitError('Failed to submit the schedule.');
    }
  };

  const startInterview = async () => {
    const currentTime = new Date().toISOString(); // Get the current time in ISO format
  
    try {
      // Perform the API call
      await axios.post(`${host}/start-interview/${interview_id}/${currentTime}`);
      
      // Only navigate if the API call is successful
      navigate(`/user/${interview_id}/userdashboard/`);
    } catch (error) {
      console.error('Failed to start the interview', error);
      // Optionally, handle the error (e.g., show a notification or alert)
      // For example: alert('There was an error starting the interview. Please try again.');
    }
  };
  
  if (loading) return <p className="loading">Loading...</p>;
  if (error) return <p className="error">{error}</p>;

  const formatDate = (date) => {
    return date
      ? date.toLocaleDateString('en-GB', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' }) + ' ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      : 'Select a Date and Time';
  };

  const createGoogleCalendarLink = () => {
    if (!selectedDate) return '#';

    const eventTitle = `Test Schedule for ${jobRole}`;
    const eventDescription = jobDescription;
    const eventLocation = '';

    // Create Date objects for the start and end times
    const startDateTime = new Date(selectedDate);
    const endDateTime = new Date(startDateTime);
    endDateTime.setHours(startDateTime.getHours() + 2); // Set end time to 2 hours later

    // Convert dates to the format required by Google Calendar URL
    const formatDateForGoogleCalendar = (date) => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');
      return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
    };

    const startDateTimeStr = formatDateForGoogleCalendar(startDateTime);
    const endDateTimeStr = formatDateForGoogleCalendar(endDateTime);

    const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(eventTitle)}&dates=${startDateTimeStr}/${endDateTimeStr}&details=${encodeURIComponent(eventDescription)}&location=${encodeURIComponent(eventLocation)}`;

    return googleCalendarUrl;
  };

  const createOutlookCalendarLink = () => {
    if (!selectedDate) return '#';

    const eventTitle = `Test Schedule for ${jobRole}`;
    const eventDescription = jobDescription;
    const eventLocation = '';

    const startDateTime = new Date(selectedDate);
    const endDateTime = new Date(startDateTime);
    endDateTime.setHours(startDateTime.getHours() + 2);

    const formatDateForOutlook = (date) => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}:00`;
    };

    const startDateTimeStr = formatDateForOutlook(startDateTime);
    const endDateTimeStr = formatDateForOutlook(endDateTime);

    return `https://outlook.office.com/calendar/0/deeplink/compose?subject=${encodeURIComponent(eventTitle)}&startdt=${startDateTimeStr}&enddt=${endDateTimeStr}&body=${encodeURIComponent(eventDescription)}&location=${encodeURIComponent(eventLocation)}`;
  };

  const createAppleCalendarLink = () => {
    if (!selectedDate) return '#';

    const eventTitle = `Test Schedule for ${jobRole}`;
    const eventDescription = jobDescription;
    const eventLocation = '';

    const startDateTime = new Date(selectedDate);
    const endDateTime = new Date(startDateTime);
    endDateTime.setHours(startDateTime.getHours() + 2);

    const formatDateForApple = (date) => {
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      return `${year}${month}${day}T${hours}${minutes}00Z`;
    };

    const startDateTimeStr = formatDateForApple(startDateTime);
    const endDateTimeStr = formatDateForApple(endDateTime);

    return `webcal://www.apple.com/calendar/compose.ics?subject=${encodeURIComponent(eventTitle)}&start=${startDateTimeStr}&end=${endDateTimeStr}&description=${encodeURIComponent(eventDescription)}&location=${encodeURIComponent(eventLocation)}`;
  };

  return (
    <div className="schedule-test">
      <h1>Congratulations!</h1>
      <p className="congratulations">
        Your profile has been shortlisted for the position of{' '}
        <span className="job-role" onClick={openModal}>{jobRole}</span>.
      </p>
  
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Job Description"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>{jobRole}</h2>
        <p>{jobDescription}</p>
        <button onClick={closeModal} className="modal-close-button">Close</button>
      </Modal>
  
      {startdate && enddate && (
        <>
          <p className="instructions">
            Select a date between <span className="date-range">{startdate.toDateString()}</span> and <span className="date-range">{enddate.toDateString()}</span>:
          </p>
  
          <div className="button-container">
            <button className="start-interview-button" onClick={startInterview}>Start Interview</button>
            <button onClick={openScheduleModal} className="schedule-button">
              {formatDate(selectedDate)}
            </button>
          </div>
  
          <Modal
            isOpen={isScheduleModalOpen}
            onRequestClose={closeScheduleModal}
            contentLabel="Select Date" 
            className="schedule-modal"
            overlayClassName="modal-overlay"
          >
            <ScheduleMeeting
              availableTimeslots={[{ startTime: startdate, endTime: enddate }]}
              eventDurationInMinutes={0}
              eventStartTimeSpreadInMinutes={60}
              onStartTimeSelect={handleStartTimeSelect}
              minDate={new Date()}
              maxDate={enddate}
              selectedStartTime={selectedDate}
              primaryColor="#007bff"
              renderDatePicker={(props) => (
                <div className="custom-date-picker">
                  <input
                    type="date"
                    {...props}
                    className="date-picker-input"
                    min={new Date().toISOString().split('T')[0]}
                  />
                </div>
              )}
              renderTimePicker={() => null}
            />
            <button onClick={closeScheduleModal} className="schedule-modal-close-button">Close</button>
          </Modal>
  
          {selectedDate && (
            <>
              <button onClick={handleSubmit} className="submit-button">
                Submit
              </button>
              {submitError && <p className="error">{submitError}</p>}
            </>
          )}
  
          {isSubmitClicked && (
            <Modal
              isOpen={isSubmitClicked}
              onRequestClose={() => setIsSubmitClicked(false)}
              contentLabel="Submission"
              className="submit-modal"
              overlayClassName="modal-overlay"
            >
              <p>Your test schedule has been submitted successfully and an email has been sent!</p>
  
              <div className="calendar-icons">
                <a href={createGoogleCalendarLink()} target="_blank" rel="noopener noreferrer" className="calendar-icon">
                  <img
                    src="https://th.bing.com/th/id/OIP.UEzpbepQbdDXQubx8xrJzgHaHa?rs=1&pid=ImgDetMain"
                    alt="Add to Google Calendar"
                    style={{ width: '54px', height: '54px', marginRight: '80px' }}
                  />
                </a>
  
                <a href={createOutlookCalendarLink()} target="_blank" rel="noopener noreferrer" className="calendar-icon">
                  <img
                    src="https://img.icons8.com/color/480/outlook-calendar.png"
                    alt="Add to Outlook Calendar"
                    style={{ width: '54px', height: '54px', marginRight: '80px' }}
                  />
                </a>
  
                <a href={createAppleCalendarLink()} target="_blank" rel="noopener noreferrer" className="calendar-icon">
                  <img
                    src="https://th.bing.com/th/id/OIP.cC-aIrFVNmZR12NE9WnxZgHaHa?rs=1&pid=ImgDetMain"
                    alt="Add to Apple Calendar"
                    style={{ width: '54px', height: '54px' }}
                  />
                </a>
              </div>
  
              <button onClick={() => setIsSubmitClicked(false)} className="submit-modal-close-button">Close</button>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export default ScheduleTest;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/NotFound.css';

const NotFound1 = (props) => {
  // Hook to programmatically navigate to different routes
  const navigate = useNavigate();

  // Function to navigate to the home page
  const gotoHome = () => {
    navigate('/');
  };

  return (
    <div className="not-found1-container">
      <h3>OOPS! PAGE NOT FOUND</h3>
      <div className="not-found1-container1">
        {/* Displaying a large "404" error code */}
        <h1 className="not-found1-text1">404</h1>
      </div>
      <div className="not-found1-container2">
        {/* Message indicating that the page was not found */}
        <h2 className="not-found1-text2">
          WE ARE SORRY, BUT THE PAGE YOU REQUESTED WAS NOT FOUND
        </h2>
      </div>
      {/* Button to navigate back to the home page */}
      <button onClick={gotoHome}>Home</button>
    </div>
  );
};

export default NotFound1;

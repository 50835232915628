import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../css/sidebar.css';
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ isSidebarOpen,hr_id }) => {
  const navigate = useNavigate();
  const [isLoading,setIsLoading] = useState(false);

  const handleSignOut = async () => {
    try {
      setIsLoading(true)
      sessionStorage.removeItem('hr_id');
      await signOut();
      navigate('/admin/login');
      setIsLoading(false)
    } catch (error) {
      console.error('Error signing out', error);
      alert('Error signing out');
    }
  };

  if (isLoading) {
    return <div className="loader"></div>;
  }
  return (
    <div className={`sidebar-container ${isSidebarOpen ? 'open' : 'closed'}`}>
      {isSidebarOpen && (
        <nav className="sidebar">
          <ul>
            <li><Link to={`/hr/${hr_id}`}><img src={'/dashboard.png'} alt="Dashboard Icon" className="sidebar-icon" />Dashboard</Link></li>
            <li><Link to={`/hr/${hr_id}/analytics`}><img src={'/analytics.png'} alt="Analytics Icon" className="sidebar-icon" />Analytics</Link></li>
            <li><Link to={`/hr/${hr_id}/checkresume`}><img src={'/resume_parser.png'} alt="CV Icon" className="sidebar-icon" />Single Resume Parser</Link></li>
            <li><Link to={`/hr/${hr_id}/Bulkresume`}><img src={'/resume_parser.png'} alt="CV Icon" className="sidebar-icon" />Bulk Resume Parser</Link></li>
            <li><Link to={`/hr/${hr_id}/checkreport`}><img src={'/reports.png'} alt="Report Icon" className="sidebar-icon" />View Reports</Link></li>
            <li className ='signout' onClick = {handleSignOut}> <img src={'/signout.png'} alt="Signout Icon" className="sidebar-icon" />Sign Out</li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default Sidebar;

import React, { useRef, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../../css/interviewPage.css";
import RecordRTC from "recordrtc";
import host from "../../global";
import Swal from 'sweetalert2';


const InterviewPortal = () => {
  const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
  const AWS_REGION_NAME = process.env.REACT_APP_AWS_REGION_NAME;
  const { interview_id } = useParams();
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const INTRODUCTION_TEXT =
    "The AI-Driven Interview Process Assistant leverages the power of AI to automate and optimize the traditional recruitment process.";
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [isQuestionVisible, setIsQuestionVisible] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isQuestionPlaying, setIsQuestionPlaying] = useState(false);
  const [showResumeButton, setShowResumeButton] = useState(false);
  const [isInterviewComplete, setIsInterviewComplete] = useState(false);
  const [interviewNotFound, setInterviewNotFound] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [isNextQuestionLoading, setIsNextQuestionLoading] = useState(false);

  const playGreetingAudio = async () => {
    try {
      const audioGreeting = new Audio(
        `https://${BUCKET_NAME}.s3.${AWS_REGION_NAME}.amazonaws.com/${interview_id}_audio_questions/greeting.mp3`
      );
      await audioGreeting.play();
      setIsPlaying(true);
      audioGreeting.onended = () => {
        setIsPlaying(false);
        startRecording();
      };
    } catch (error) {
      console.error("Error playing greeting audio:", error);
      showErrorAlert();
      setIsPlaying(false);
    }
  };

  
    const showErrorAlert = () => {
      Swal.fire({
        icon: 'error',
        title: 'Attention!!',
        text: 'Something went wrong!',
        confirmButtonText: 'Refresh',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/interview/{interview_id}/instructions');
        }
      });
    };
    

  const resumeInterview = async () => {
    try {
      setShowResumeButton(false);
      const response = await axios.post(
        `${host}/resumeinterview/${interview_id}`,
        {}
      );
      if (response.data.message === "Interview Complete") {
        handleSubmit();
      } else {
        setCurrentQuestion(response.data.question);
        playQuestionAudio(response.data.currentQuestionIndex - 1);
      }
    } catch (error) {
      console.error("Error resuming interview:", error.message);
    }
  };

  const checkInterviewState = async () => {
    try {
      const response = await fetch(
        `${host}/check_interview_state/${interview_id}`
      );
      const data = await response.json();
      console.log(data);
      if (data.message) {
        setInterviewNotFound(true);
        setErrormsg(data.message);
      } else {
        setCurrentQuestionIndex(data.currentQuestionIndex);

        if (data.interview_status === "Complete") {
          setIsInterviewComplete(true);
        } else {
          if (data.currentQuestionIndex === -1) {
            playGreetingAudio();
          } else {
            setShowResumeButton(true);
          }
        }
      }
    } catch (error) {
      console.error("Error checking interview state:", error);
    }
  };

  useEffect(() => {
    checkInterviewState();
  }, [interview_id]);

  const stopRecordingAndProceed = async () => {
    setIsNextButtonEnabled(false);
    setIsQuestionVisible(false); 
    setIsNextQuestionLoading(true); 
    setIsRecording(false);
    try {
      if (recorder) {
        recorder.stopRecording(async () => {
          const blob = recorder.getBlob();
          const index = await getQuestion(blob);
          setRecorder(null);
          setIsPlaying(false);

          if (index !== null) {
            setIsNextQuestionLoading(true);
            playQuestionAudio(index);
            setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
            setIsNextQuestionLoading(false);
          }
        });
      }
    } catch (error) {
      console.error("Error stopping recording:", error);
    }
  };

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const recorder = RecordRTC(stream, {
        type: "audio",
        mimeType: "audio/mp3",
      });
      recorder.startRecording();
      setRecorder(recorder);
      setIsRecording(true);
    });
    setTimeout(() => {
      setIsNextButtonEnabled(true);
    }, 5000);
  };

  const getQuestion = async (audioBlob) => {
    const formData = new FormData();
    formData.append("file", audioBlob, "recording.mp3");

    try {
      const response = await axios.post(
        `${host}/getquestion/${interview_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (
        response.data.message &&
        response.data.message === "Interview Complete"
      ) {
        handleSubmit();
        return null;
      } else {
        setCurrentQuestion(response.data.question);
        console.log("Current question:", response.data.question);
        return response.data.currentQuestionIndex;
      }
    } catch (error) {
      console.error("Error uploading file:", error.message);
      alert("Attention: Internet issue detected. Please refresh the page.");
    }
  };

  const playQuestionAudio = (index) => {
    let retryCount = 0;
    const maxRetries = 4;
    console.log(currentQuestion);

    const tryPlayAudio = async () => {
      try {
        const audio = `https://${BUCKET_NAME}.s3.${AWS_REGION_NAME}.amazonaws.com/${interview_id}_audio_questions/${
          index + 1
        }.mp3`;
        audioRef.current.src = audio;

        try {
          await audioRef.current.load();
          await audioRef.current.play();
          setIsPlaying(true);
          try {
          } catch (error) {
            console.log("Error: ", error);
          }

          audioRef.current.onended = () => {
            setIsPlaying(false);
            setIsQuestionVisible(true);
            setIsQuestionPlaying(false);
            startRecording();
          };
        } catch (error) {
          console.error("Audio failed to play:", error);
          if (retryCount < maxRetries) {
            retryCount++;
            setTimeout(tryPlayAudio, 4000);
          } else {
            console.error(`Failed to play audio after ${maxRetries} attempts`);
            alert(
              "Interview Disconnected,Please check your internet connection and try again."
            );
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    tryPlayAudio();
  };

  const handleSubmit = async () => {
    setIsSubmitLoading(true);
    try {
      axios.delete(
        `${host}/delete-audio-files/${interview_id}`,
        {}
      );
      navigate(`/thankyou/${interview_id}`);
    } catch (error) {
      console.error("Error deleting audio files:", error);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <div className={`interviewPage ${isSubmitLoading ? "blurred" : ""}`}>
      {interviewNotFound ? (
        <div className="resumeButtonContainer">
          <p className="completionMessage">{errormsg}</p>
        </div>
      ) : isInterviewComplete ? (
        <div className="resumeButtonContainer">
          <p className="completionMessage">
            Your interview is complete. Thank you for participating!
          </p>
        </div>
      ) : showResumeButton ? (
        <div className="resumeButtonContainer">
          <p className="resumeDescription">
            In case your interview was interrupted, you can resume from where
            you left off by clicking the button below.
          </p>
          <button
            className="resumeBtn"
            onClick={resumeInterview}
            style={{ padding: "10px 20px", fontSize: "16px" }}
          >
            Resume Interview
          </button>
        </div>
      ) : (
        <>
          <h3>You Are Talking With Anamika</h3>
          <div className="voiceTranscript">
            <p>
              {currentQuestionIndex === -1
                ? INTRODUCTION_TEXT
                : isQuestionVisible
                ? currentQuestion
                : ""}
            </p>
          </div>

          <div className="pageContent">
            <div className="speakAnimation">
              {[1, 2, 3, 4, 5, 6, 7].map((_, index) => (
                <div
                  key={index}
                  className={`line ${isPlaying ? "animate" : ""}`}
                ></div>
              ))}
            </div>

            {/* <div>{transcript}</div> */}
            {isRecording && (
              <div className="animation.loader">
                <div className="circle">
                  <div className="dot"></div>
                </div>
                <div className="circle">
                  <div className="dot"></div>
                </div>
                <div className="circle">
                  <div className="dot"></div>
                </div>
                <div className="circle">
                  <div className="dot"></div>
                </div>
              </div>
            )}
          {/* Conditionally render the button */}
          { isNextButtonEnabled  && (
            <button
              className="nextQuestionBtn"
              onClick={stopRecordingAndProceed}
            >
              Next Question
            </button>
          )}

            {isNextQuestionLoading && (
              <div className="loadingWrapper">
                <Spinner />
                <p className="loadingMessage">
                  Analyzing answer... 
                </p>
              </div>
            )}
          </div>

          <audio ref={audioRef} src="/audio.mp3" />

          {isSubmitLoading && <div className="loader"></div>}
        </>
      )}
    </div>
  );
};

// Spinner component
const Spinner = () => (
  <div className="spinner">
    <div className="double-bounce1"></div>
    <div className="double-bounce2"></div>
  </div>
);
export default InterviewPortal;

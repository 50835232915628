import React, { useState } from 'react';
import '../../css/ticketmodal.css'; 
import axios from 'axios';
import host from '../../global';
import { useParams } from 'react-router-dom';

const TicketModal = ({ isOpen, onClose }) => {
  const [title, setTitle] = useState(''); 
  const [description, setDescription] = useState('');
  const orgadminId = useParams();

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${host}/${orgadminId}/raiseticket`, {
        title: title,
        description: description
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        alert("Ticket Submitted Successfully")
        setTitle(''); 
        setDescription('');
        onClose(); 
      } else {
        console.error('Error submitting ticket:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting ticket:', error);
    }
  };

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3> Raise a Ticket</h3>
        <select
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        >
          <option value="">Select ticket title</option>
          <option value="Issue with Job Description">Issue with Job Description</option>
          <option value="Technical Issue">Technical Issue</option>
          <option value="HR Profile Issue">HR Profile Issue</option>
          <option value="HR Profile Issue">Host Coding problem</option>
          <option value="Other">Other</option>
        </select>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter ticket description"
          rows="5"
        />
        <div className="modal-actions">
          <button onClick={handleSubmit}>Submit</button>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  ) : null;
};

export default TicketModal;
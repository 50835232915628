import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Header from './header';
import Sidebar from './sidebar';
import '../../css/CompanyAdmin.css';
import '../../css/random_mcq.css';
import { Authenticator } from '@aws-amplify/ui-react';
import host from '../../global';
import { fetchAuthSession } from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';

const Actions = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const { orgadminId } = useParams();
    const [topics, setTopics] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [questionsPerTopic, setQuestionsPerTopic] = useState({});
    const [setName, setSetName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [areTopicsLoading, setAreTopicsLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();
  
      useEffect(() => {
        const checkSession = async () => {
          try {
            const session = await fetchAuthSession();
            const { accessToken } = session.tokens ?? {};
            const groups = accessToken.payload['cognito:groups'] ?? [];
            const { userId } = await getCurrentUser();
            
            if (groups.includes('Company_Admin') && userId === orgadminId) {
              setIsAuthenticated(true);
            } else {
              setIsAuthenticated(false);
            }
          } catch (error) {
            setIsAuthenticated(false);
          } finally {
            setIsLoading(false);
          }
        };
        
        checkSession();
        }, []);

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                setAreTopicsLoading(true)
                const response = await axios.get(`${host}/mcq_topics`);
                setTopics(response.data);
                setAreTopicsLoading(false)
            } catch (error) {
                console.error('Error fetching topics', error);
            }
        };
        fetchTopics();
    }, []);

    const handleInputChange = (topic, value) => {
        setQuestionsPerTopic((prev) => ({
            ...prev,
            [topic]: parseInt(value, 10),
        }));
    };

    const handleSetChange = (e) => {
        setSetName(e.target.value);
    }

    const handleSaveSet = async () => {
        console.log(questions);
        console.log(setName);   
        console.log(orgadminId);
             
        try{
          const res = await axios.post(`${host}/insert_mcq_new_new/${orgadminId}/${setName}`, {data:{data: questions}});
          if(res){
            alert("Data Inserted")
          }
        }
        catch(e){
          console.error('There was an error!', e);
        }
      }

    const handleFetchQuestions = async () => {
        try {
            const topicCounts = Object.entries(questionsPerTopic)
                .filter(([topic, count]) => count > 0)
                .map(([topic, count]) => ({ topic, count }));

            const response = await axios.post(`${host}/fetchmcq`, topicCounts);
            setQuestions(response.data);
        } catch (error) {
            console.error('Error fetching questions', error);
        }
    };

    if (isLoading) {
        <div className="loader"></div>
      }
    
      if (!isAuthenticated) {
        return (
          <div className="AuthenticationMessageContainer">
            <div className="AuthenticationMessage">
          <p>
            You are not Authorized to access this Page!
          </p>
          <button onClick={() => navigate('/admin/login')}> Login Page</button>
          </div>
          </div>
        );
      }

    return (
        <>
            <Authenticator>
                <div>
                    <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} orgadminId={orgadminId}/>
                    <div className="company-admin-container">
                        <Sidebar isSidebarOpen={isSidebarOpen} orgadminId={orgadminId}/>
                        <div className={`content ${isSidebarOpen ? 'sidebarIsOpen' : ''}`}>
                            {areTopicsLoading ? (
                                <div className='loader'></div>
                            ):(
                            <div>
                                <h1>Fetch Random MCQs</h1>
                                <div className="topic">
                                    {topics.map((topic) => (
                                        <div key={topic} className='topicAndInput'>
                                            <div>{topic}</div>
                                            <input
                                                type="number"
                                                min="0"
                                                max="10"
                                                onChange={(e) => handleInputChange(topic, e.target.value)}
                                                aria-label={`Number of Questions for ${topic}`}
                                            />
                                        </div>
                                    ))}
                                    <button onClick={handleFetchQuestions}>Fetch Questions</button>
                                </div>
                                <div>
                                    {questions.length>0 &&
                                    <div className='saveAsSet'>
                                        <label>Save As Set : </label>
                                        <input placeholder='Set Name' value={setName} onChange={handleSetChange}></input>
                                        <button onClick={handleSaveSet}>Save</button>
                                    </div>
                                    }
                                    {questions.map((question, index) => (
                                        <div key={index} className="question">
                                            <h3>{question.question}</h3>
                                            <ul>
                                                <li>a. {question.option1}</li>
                                                <li>b. {question.option2}</li>
                                                <li>c. {question.option3}</li>
                                                <li>d. {question.option4}</li>
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </Authenticator>
        </>
    );
};

export default Actions;
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import host from '../../global';
import '../../css/CompanyAdmin.css';
import Header from './header'; 
import Sidebar from './sidebar';

const EditProfile = () => {
    const { orgadminId } = useParams();
    const fileInputRef = useRef(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [profileData, setProfileData] = useState({
        name: '',
        email: '',
        contact: '',
        profileImage: '',
        profileImageFile: null, 
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await fetch(`${host}/getadmindetails/${orgadminId}`);
                const data = await response.json();
                setProfileData({
                    name: data.name,
                    email: data.email,
                    contact: data.contact,
                    profileImage: data.profile,
                });
            } catch (error) {
                console.error('Error fetching profile data:', error.message);
            }
        };

        fetchProfileData();
    }, [orgadminId]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'profileImage' && files.length > 0) {
            const imageUrl = URL.createObjectURL(files[0]);
            setProfileData((prevData) => ({
                ...prevData,
                profileImage: imageUrl,
                profileImageFile: files[0],
            }));
        } else {
            setProfileData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
    
        const formData = new FormData();
        formData.append('org_admin_name', profileData.name);
        formData.append('org_admin_email', profileData.email);
        formData.append('org_admin_contact', profileData.contact);
        if (profileData.profileImageFile) {
            formData.append('profile', profileData.profileImageFile);
        }
        if (profileData.deleteProfileImage) {
            formData.append('deleteProfileImage', 'true');
        }
        try {
            const response = await fetch(`${host}/org-admin/${orgadminId}/updateprofile`, {
                method: 'PUT',
                body: formData
            });
    
            if (response.ok) {
                setMessage('Profile updated successfully!');
                window.location.reload()
            } else {
                const errorData = await response.json();
                console.error('Error details:', errorData);
                setMessage('Failed to update profile. ' + errorData.detail.map(err => err.msg).join(', '));
            }
        } catch (error) {
            console.error('Error updating profile:', error.message);
            setMessage('Error updating profile.');
        }
    
        setIsSubmitting(false);
    };
        
    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleDeleteImage = () => {
        setProfileData((prevData) => ({
            ...prevData,
            profileImage: '',
            profileImageFile: null,
            deleteProfileImage: true 
        }));
    };

    return (
        <Authenticator>
        <div>
        <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} orgadminId={orgadminId}/>
        <div className="company-admin-container">
          <Sidebar isSidebarOpen={isSidebarOpen} orgadminId={orgadminId}/>
          <div className={`content ${isSidebarOpen ? 'sidebarIsOpen' : ''}`}>
            <div className='edit-profile'>
                <div className="edit-profile-container">
                <h2>Edit Profile</h2>
                    <form onSubmit={handleSubmit}>
                        <div className='profile-img-container'>
                            <img
                                src={profileData.profileImage || '/adddisplayprofile.png'}
                                alt="Profile"
                                className="profile-img"
                                onClick={handleImageClick}
                            />
                            <input
                                type="file"
                                name="profileImage"
                                accept="image/*"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleChange}
                            />
                            {profileData.profileImage && (
                                <button type="button" onClick={handleDeleteImage} style={{ marginLeft: '10px', padding:'5px',backgroundColor:'#0073e6',color:'white',fontFamily:'FigTree' }}>
                                    Remove Profile
                                </button>
                            )}
                        </div>

                        <div>
                            <label>Name:</label>
                            <input
                                type="text"
                                name="name"
                                value={profileData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Email:</label>
                            <input
                                type="email"
                                name="email"
                                value={profileData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label>Contact:</label>
                            <input
                                type="text"
                                name="contact"
                                value={profileData.contact}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button type="submit" disabled={isSubmitting}>
                            {isSubmitting ? 'Updating...' : 'Update Profile'}
                        </button>
                    </form>
                    {message && <p>{message}</p>}
                </div>
            </div>
            </div>
            </div>
            </div>
        </Authenticator>
    );
};

export default EditProfile;
import React from 'react';
import '../../css/instructionsPage.css';
import host from '../../global';
import { useNavigate,useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';

const InterviewInstructions = () => {
  const navigate = useNavigate();
  const {interview_id} = useParams();

  useEffect(() => {
    const getTimeLimit = async () => {
      try {
        const response = await axios.post(`${host}/time_limit/${interview_id}`);
        console.log("response for time_limit: ", response.data);
        const interview_id_timelimit = `${interview_id}_timelimit`;
        const timeLimit = response.data *60;
        console.log(timeLimit)
        localStorage.setItem(interview_id_timelimit, timeLimit);
      } catch (error) {
          console.error('Error:', error.message);
      }
    };
    getTimeLimit();
  }, []);

  const handleProceed = async () =>{
    await fetch(`${host}/greeting-user/${interview_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    navigate(`/interview/${interview_id}`);
  }
  
  return (
    <div className="InstructionsPage">
      <div className="instructions-container">
        <h1>Interview Round Instructions</h1>
        <ol>
          <li>The interview will consist of multiple questions, including technical, problem-solving, and behavioral questions.</li>
          <li>Prepare to discuss your previous projects and experience with AI technologies.</li>
          <li>For problem-solving tasks, you may be asked to design or implement solutions based on hypothetical scenarios.</li>
          <li>Be ready to explain your thought process and reasoning for each answer or solution you provide.</li>
          <li>Maintain a professional demeanor and respond to questions clearly and concisely.</li>
          <li>Do not attempt to access unauthorized resources or collaborate with others during the interview.</li>
          <li>The interview will be recorded for evaluation purposes, so ensure your environment and conduct meet professional standards.</li>
          <li>Once the interview starts, you will not be able to pause or reschedule, so manage your time effectively.</li>
          <li>Be prepared to discuss your approach and rationale for each problem, including potential improvements or alternative solutions.</li>
        </ol>
      </div>
      <button onClick={handleProceed}>Proceed to Test</button>
    </div>
  );
};

export default InterviewInstructions;
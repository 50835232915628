import React from 'react';
import '../../css/newLandingPage.css';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

const SupportPage = () => {
    const navigate = useNavigate();
    return (
        <div className='LP'>
            <div className='LP-bkg' style={{ height: '70vh' }}></div>
            <div className='Section1' style={{ height: '70vh' }}>
                <Header/>
                <div className='welcomeText'>
                    <h1 className='pageTitle'>Support</h1>
                    <p className='description'>We are here to help you. Find answers to your questions and get support from our team.</p>
                </div>
            </div>
            <div className='SupportContent'>
                <div className='SupportCard'>
                    <h2 className='supportTitle'>Customer Support</h2>
                    <p className='supportDescription'>Our customer support team is available 24/7 to assist you with any issues or questions you may have. Reach out to us via email, phone, or live chat.</p>
                </div>
                <hr />
                <div className='SupportCard'>
                    <h2 className='supportTitle'>FAQs</h2>
                    <p className='supportDescription'>Check out our frequently asked questions for quick answers to common issues.</p>
                </div>
                <hr />
                <div className='SupportCard'>
                    <h2 className='supportTitle'>Documentation</h2>
                    <p className='supportDescription'>Explore our detailed documentation to get the most out of GenieHire. From setup to advanced features, we've got you covered.</p>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default SupportPage;

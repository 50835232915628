import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import host from '../../global';

function CodingResult({ adminId, interview_id }) {
  const [problems, setProblems] = useState([]);
  const [codeSubmissions, setCodeSubmissions] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedProblemId, setSelectedProblemId] = useState(null);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  const toggleDetails = () => {
    setShowDetails(prevState => !prevState); 
  };

  useEffect(() => {
    const fetchProblems = async () => {
      try {
        const response = await axios.get(`${host}/api/problems_from_codesubmissions/${interview_id}`);
        setProblems(response.data);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching problems:', error);
      }
    };

    const fetchCodeSubmissions = async () => {
      try {
        const response = await axios.get(`${host}/api/get_codesubmissions/${interview_id}`);
        setCodeSubmissions(response.data.submissions);
        const dateObj = new Date(response.data.startDateTime);
        const date = dateObj.toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).replace(/\//g, '-'); 
        
        const time = dateObj.toLocaleTimeString('en-GB', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
        setDate(date);
        setTime(time);
      } catch (error) {
        console.error('Error fetching code submissions:', error);
      }
    };

    fetchProblems();
    fetchCodeSubmissions();
  }, [interview_id]);

  const downloadPDF = () => {
    window.print();
  };

  const handleToggleDetails = (problemId) => {
    setSelectedProblemId(selectedProblemId === problemId ? null : problemId);
  };

  return (
    <div className="CodingResult">
      <div className='mcqResultHeader'>
        <h2>
          <span onClick={toggleDetails} className="dropdownArrow">
            {showDetails ? <FaChevronUp /> : <FaChevronDown />}
          </span>
          Coding Test Report
        </h2>
        <div className='scoreAndDownload'>
        <div id="totalscore">
              {date ? <>Date: {date}</> : null}
              &emsp;
              {time ? <>Time: {time}</> : null}
            </div>
          <button onClick={downloadPDF} id='downloadMCQReportBtn'>Download Coding Report</button>
        </div>
      </div>
      {showDetails && (
        <div className="CodingQuestionsList">
          {problems.map((problem) => {
            const problemId = problem.id;
            const submission = codeSubmissions[problemId];
            const testCases = submission?.test_cases_passed;
            
            const isDetailedTestCases = Array.isArray(testCases) && testCases.every(tc => tc.hasOwnProperty('status_message') && tc.hasOwnProperty('yourOutput') && tc.hasOwnProperty('expectedOutput'));

            const acceptedTestCasesCount = isDetailedTestCases ? testCases.filter(testCase => testCase.status_message === 'Accepted').length : 0;
            const scoreClass = acceptedTestCasesCount > 0 ? 'correct' : 'incorrect';

            return (
              <div key={problem._id} className={`CodingResultQuestion ${scoreClass}`}>
                <p><span style={{ fontWeight: 'bold' }}>Problem Title:</span> {problem.title}</p>
                <p><span style={{ fontWeight: 'bold' }}>Description:</span> {problem.description}</p>
                {submission && submission.language && submission.code && submission.coding_score !== undefined ? (
                  <div>
                    <p><span style={{ fontWeight: 'bold' }}>Language:</span> {submission.language}</p>
                    <p><span style={{ fontWeight: 'bold' }}>Code:</span>
                    <pre>{submission.code}</pre></p>
                    <p><span style={{ fontWeight: 'bold' }}>Score:</span> {submission.coding_score}</p>
                    <p><span style={{ fontWeight: 'bold' }}>Test Cases Passed:</span> {acceptedTestCasesCount}</p>
                    <button onClick={() => handleToggleDetails(problemId)}>
                      {selectedProblemId === problemId ? 'Hide Details' : 'Show Details'}
                    </button>
                    {selectedProblemId === problemId && (
                      <div className="testCaseDetails">
                        {isDetailedTestCases ? (
                          testCases.map((testCase, index) => (
                            <div key={index} className={`testCase ${testCase.status_message.toLowerCase()}`}>
                              <p><strong>Test Case {index + 1}:</strong></p>
                              <p><strong>Status:</strong> {testCase.status_message}</p>
                              <p><strong>Your Output:</strong></p>
                              <pre>{testCase.yourOutput}</pre>
                              <p><strong>Expected Output:</strong></p>
                              <pre>{testCase.expectedOutput}</pre>
                              {testCase.stderr && (
                                <div>
                                  <p><strong>Stderr:</strong></p>
                                  <pre>{testCase.stderr}</pre>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div>
                            <p><strong>Remark:</strong></p>
                            <pre>{testCases[0]}</pre>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <p style={{ fontWeight: 'bold', color: 'red' }}>Not Attempted</p>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default CodingResult;
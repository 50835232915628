import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../css/ConvaiAdminPage.css';
import axios from 'axios';
import host from '../global.js';
import CustomALert from '../components/customalter.js';
import { signOut } from "aws-amplify/auth";

const GenieHireAdminDashboard = () => {
    const navigate = useNavigate();
    const { admin_id } = useParams();
    
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [formVisible, setFormVisible] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [viewOrgDetails, setViewOrgDetails] = useState(false);
    const [formData, setFormData] = useState({
        organization_name: '',
        organization_industry: '',
        organization_email: '',
        org_admin_id: '',
        org_admin_name: '',
        org_admin_email: '',
        org_admin_contact: '',
        total_hr_allocated: ''
    });
    const [adminName, setAdminName] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [adminContact, setAdminContact] = useState('');
    const [numOrgCreated, setNumOrgCreated] = useState(0);
    const [data, setData] = useState([]);
    const [orgData, setOrgData] = useState(null);
    const [editOrgData, setEditOrgData] = useState(null);
    const [editOrgId, setEditOrgId] = useState('');
    const [showPending, setShowPending] = useState(false);
    const [PendingProfiles, setPendingProfiles] = useState(null);
    const [ifAccept, setIfAccept] = useState([]);
    const [showDemo, setShowDemo] = useState(false);
    const [DemoProfiles, setDemoProfiles] = useState(null);
    const [viewDemoDetails, setViewDemoDetails] = useState(false);
    const [demoOrgData, setDemoOrgData] = useState(false);
    const [TotalHRCount, setTotalHRCount] = useState(null);
    const [TicketsRaised, setTicketsRaised] = useState(null);
    const [showTickets, setShowTickets] = useState(false);
    const [TicketDetails, setTicketDetails] = useState(null);
    const [viewTicket, setViewTicket] = useState(false);
    const [ticketRemark, setTicketRemark] = useState('');
    const [interviewCount, setInterviewCount] = useState(0);

    useEffect(() => {
        const updateDashboardData = async () => {
            await displayConvAIAdminData();
            await getDashCounts();
            await getOrgAdminsData();
            await getPendingProfilesData();
            await getDemoProfilesData();
            await fetchRaisedTicketsData();
        };
        updateDashboardData();
    }, []);

    const getDashCounts = async () => {
        try {
            const response = await axios.get(`${host}/get-dashboard-counts/${admin_id}`);
            if(response){
                setTotalHRCount(response.data.total_hr);
                setInterviewCount(response.data.total_interviews_count);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const displayConvAIAdminData = async () => {
        try {
            const response = await axios.get(`${host}/get_convai_admin_details/${admin_id}`);
            if(response){
                // console.log(response.data);
                setAdminName(response.data.admin_name);
                setAdminEmail(response.data.admin_email);
                setAdminContact(response.data.admin_contact);
                setNumOrgCreated(response.data.org_created);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const getOrgAdminsData = async () => {
        try {
            const response = await axios.get(`${host}/getAllOrgAdmins/${admin_id}`);
            if(response){
                setData(response.data);
            }                
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const getPendingProfilesData = async () => {
        try {
            const response = await axios.get(`${host}/org-admins/${admin_id}`);
            if(response){
                setPendingProfiles(response.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const getDemoProfilesData = async () => {
        try {
            const response = await axios.get(`${host}/get-demo-requests`);
            if(response){
                setDemoProfiles(response.data);  
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchRaisedTicketsData = async () => {
        try {
            const response = await axios.get(`${host}/get-raised-tickets/${admin_id}`);
            if(response){
                setTicketsRaised(response.data);  
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value
        });
    };

    const handleTicketRemarkChange = (e) => {
        setTicketRemark(e.target.value);
    }
    
    const handleAddCompany = () => {
        setFormVisible(true);
    }

    const hideForm = () => {
        setFormVisible(false);
    }

    const handleViewOrgDetails = () => {
        setViewOrgDetails(true);
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${host}/create_new_org_admin/${admin_id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setAlertMessage("New Organization Added Successfully!");
            setAlertVisible(true);
            setFormData({
                organization_name: '',
                organization_industry: '',
                organization_email: '',
                org_admin_id: '',
                org_admin_name: '',
                org_admin_email: '',
                org_admin_contact: '',
                total_hr_allocated: ''
            });
            setTimeout(() => {
                setAlertVisible(false);
                setFormVisible(false);
            }, 1200);
            await getDashCounts();
            await getOrgAdminsData();
        } catch (error) {
            console.error("Error: ", error);
        }
    }

    const handleFormUpdate = async (e) => {
        e.preventDefault();
        // console.log(formData);
        try {
            await axios.post(`${host}/update_org_data/${editOrgId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setAlertMessage("Organization Data Successfully Updated!");
            setAlertVisible(true);
            setTimeout(() => {
                setAlertVisible(false);
                setEditForm(false);
            }, 1200);
            setFormData({
                organization_name: '',
                organization_industry: '',
                organization_email: '',
                org_admin_id: '',
                org_admin_name: '',
                org_admin_email: '',
                org_admin_contact: '',
                total_hr_allocated: ''
            });
        } catch (error) {
            console.error("Error: ", error);
        }
    }

    const showOrgData = async (org_id) => {
        try {
            const response = await axios.get(`${host}/getCompanyData/${org_id}`);
            if(response){
                setOrgData(response.data);
                setViewOrgDetails(true);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const toggleEditForm = async (org_id) => {
        setEditOrgId(org_id);
        if(editForm){
            setEditForm(false);
        }
        else{
            try {
                const response = await axios.get(`${host}/getCompanyData/${org_id}`);
                if(response){
                    setEditOrgData(response.data);
                    setEditForm(true);
                    setFormData({
                        organization_name: response.data.organization_name,
                        organization_industry: response.data.organization_industry,
                        organization_email: response.data.organization_email,
                        org_admin_id: response.data.org_admin_id,
                        org_admin_name: response.data.org_admin_name,
                        org_admin_email: response.data.org_admin_email,
                        org_admin_contact: response.data.org_admin_contact,
                        total_hr_allocated: response.data.total_hr_allocated
                    });
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    }

    const handleDeleteOrg = async (e) => {
        e.preventDefault();
        alert("Confirm Delete Operation");
        try {
            const response = await axios.get(`${host}/deleteCompanyData/${editOrgId}`);
            if(response){
                setAlertMessage("Data Deleted Successfully!");
                await getDashCounts();
                await getOrgAdminsData();
                setAlertVisible(true);
                setTimeout(() => {
                    setAlertVisible(false);
                    setEditForm(false);
                }, 1300);                
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleLogout = async () => {
        await signOut();
        console.log("Candidate logged out");
        navigate("/admin/login");
    }

    const hideOrgDetails = () => {
        setViewOrgDetails(false);
    }

    const toggleShowPending = () => {
        if(showDemo){
            setShowDemo(false);
        }
        if(showTickets){
            setShowTickets(false);  
        }
        if(showPending){
            setShowPending(false);
        }
        else{
            setShowPending(true);
        }
    }

    const toggleShowDemo = () => {
        if(showPending){
            setShowPending(false);
        }
        if(showTickets){
            setShowTickets(false);  
        }
        if(showDemo){
            setShowDemo(false);  
        } else{
            setShowDemo(true);
        }
    }

    const toggleShowTickets = () => {
        if(showDemo){
            setShowDemo(false);
        }
        if(showPending){
            setShowPending(false);
        }
        if(showTickets){
            setShowTickets(false);  
        }
        else{
            setShowTickets(true);
        }
    }

    const handleAccept = async (index) => {
        const id = prompt("Enter HR ID"); 
        try {
            const result = await axios.post(`${host}/store-hr-data/${id}`, PendingProfiles[index]);     
            if(result){
                toggleShowPending();
                await getPendingProfilesData();
            }       
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleReject = async (index) => {
        // console.log(PendingProfiles[index].hremail);
        const email = PendingProfiles[index].hremail;
        alert("Confirm Delete?");
        try {
            const result = await axios.post(`${host}/delete-pending-hr-data/${email}`);   
            if(result){
                toggleShowPending();
                await getPendingProfilesData();
            }          
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const showDemoDetails = async (demo_id) => {
        if(viewDemoDetails){
            setViewDemoDetails(false);
        }
        else{
            setViewDemoDetails(true);
            try {
                const result = await axios.get(`${host}/demo-org-data/${demo_id}`); 
                if(result){
                    setDemoOrgData(result.data);   
                }          
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    }

    const handleDemoAccept = async (demo_id) => {
        try {
            const result = await axios.get(`${host}/delete-demo-org-data/${demo_id}`); 
            alert("Request accepted");
            toggleShowDemo();   
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleDemoReject = async (demo_id) => {
        alert("Request rejected");
        try {
            const result = await axios.get(`${host}/delete-demo-org-data/${demo_id}`);     
            alert("Request rejected");
            toggleShowDemo();       
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const showTicketDetails = (index) => {
        console.log(TicketsRaised[index]);
        setTicketDetails(TicketsRaised[index]);
        toggleShowTickets();
        setViewTicket(true);
    }

    const hideTicket = () => {
        setViewTicket(false);
    }

    const updateTicketStatus = async (ticketId) => {
        // console.log(ticketId);
        if(ticketRemark !== ""){
            try {
                const result = await axios.post(`${host}/update-ticket-status/${ticketId}/${ticketRemark}`); 
                if(result){
                    alert("Status updated successfully.");
                    fetchRaisedTicketsData();
                    setViewTicket(false);
                }         
            } catch (error) {
                console.error("Error:", error);
            }
        }
        else{
            alert("Fill out remark first")
        }   
    }

    return (
        <>
        {alertVisible && <CustomALert message={alertMessage} />}

        {/* Form on Add Organization */}
        {formVisible &&
        <div className='AddCompanyForm'>
            <form>
                <img src='/reject.png' alt='Logo' onClick={hideForm}></img> 
                <p>Fill The Details</p>
                <div className='formFields'>
                <label>Organization Name</label>
                <input name='organization_name' placeholder='' value={formData.organization_name} onChange={handleFormChange} ></input>
                <label>Organization Industry</label>
                <input name='organization_industry' placeholder='' value={formData.organization_industry} onChange={handleFormChange} ></input>
                <label>Organization Email</label>
                <input name='organization_email' type='email' placeholder='' value={formData.organization_email} onChange={handleFormChange} ></input>
                <label>Organization Admin ID</label>
                <input name='org_admin_id' placeholder='' value={formData.org_admin_id} onChange={handleFormChange} ></input>
                <label>Organization Admin Name</label>
                <input name='org_admin_name' placeholder='' value={formData.org_admin_name} onChange={handleFormChange} ></input>
                <label>Organization Admin Email</label>
                <input name='org_admin_email' type='email' placeholder='' value={formData.org_admin_email} onChange={handleFormChange} ></input>
                <label>Organization Admin Contact Number</label>
                <input name='org_admin_contact' type='number' placeholder='' value={formData.org_admin_contact} onChange={handleFormChange} ></input>
                <label>Set Number Of HRs</label>
                <input name='total_hr_allocated' type='number' placeholder='' value={formData.total_hr_allocated} onChange={handleFormChange} ></input>
                {/* <label>User Group : Company_Admin</label> */}
                {/* <input name='user_group' value={formData.user_group} ></input> */}
                <button onClick={handleFormSubmit}>Submit</button>
                </div>
            </form>
        </div>
        }

        {editForm && editOrgData &&
        <div className='AddCompanyForm'>
            <form>
                <img src='/reject.png' alt='Logo' onClick={toggleEditForm}></img> 
                <p>Edit Details</p>
                <div className='formFields'>
                <label>Organization Name</label>
                <input name='organization_name' placeholder={editOrgData.organization_name} value={formData.organization_name} onChange={handleFormChange} ></input>
                <label>Organization Industry</label>
                <input name='organization_industry' placeholder={editOrgData.organization_industry} value={formData.organization_industry} onChange={handleFormChange} ></input>
                <label>Organization Email</label>
                <input name='organization_email' type='email' placeholder={editOrgData.organization_email} value={formData.organization_email} onChange={handleFormChange} ></input>
                <label>Organization Admin ID</label>
                <input name='org_admin_id' placeholder='' value={editOrgData.org_admin_id} onChange={handleFormChange} ></input>
                <label>Organization Admin Name</label>
                <input name='org_admin_name' placeholder={editOrgData.org_admin_name} value={formData.org_admin_name} onChange={handleFormChange} ></input>
                <label>Organization Admin Email</label>
                <input name='org_admin_email' type='email' placeholder={editOrgData.org_admin_email} value={formData.org_admin_email} onChange={handleFormChange} ></input>
                <label>Organization Admin Contact Number</label>
                <input name='org_admin_contact' type='number' placeholder={editOrgData.org_admin_contact} value={formData.org_admin_contact} onChange={handleFormChange} ></input>
                <label>Set Number Of HRs</label>
                <input name='total_hr_allocated' type='number' placeholder={editOrgData.total_hr_allocated} value={formData.total_hr_allocated} onChange={handleFormChange} ></input>
                {/* <label>User Group : Company_Admin</label> */}
                {/* <input name='user_group' value={formData.user_group} ></input> */}
                <button onClick={handleFormUpdate}>Update</button>
                <button id='deleteOrg' onClick={handleDeleteOrg}>Delete Organization</button>
                </div>
            </form>
        </div>
        }

        {viewOrgDetails &&
        <div className='orgDetails'>
            {orgData &&
            <div className='orgDetailsContent'>
                <img src='/reject.png' alt='Logo' id='hideLogo' onClick={hideOrgDetails}></img>
                <h2>Organization Details</h2>
                <hr></hr>
                <p><strong>Organization Name: </strong>{orgData.organization_name}</p>
                <p><strong>Organization Type: </strong>{orgData.organization_industry}</p>
                <p><strong>Organization Email: </strong>{orgData.organization_email}</p>
                <p><strong>Admin ID: </strong>{orgData.org_admin_id}</p>
                <p><strong>Admin Name: </strong>{orgData.org_admin_name}</p>
                <p><strong>Admin Email: </strong>{orgData.org_admin_email}</p>
                <p><strong>Admin Contact: </strong>{orgData.org_admin_contact}</p>
                <p><strong>Number of HR Allocated: </strong>{orgData.total_hr_allocated}</p>
            </div>
            }
        </div>
        }

        {showPending &&
        <div className='PendingProfiles'>
            <div className='PendingProfilesList'>
                {PendingProfiles && PendingProfiles.map((item, index) => (
                    <ul key={index}>
                        {/* <li>{index + 1}</li> */}
                        <li><strong>HR Name: </strong>{item.hrName}</li>
                        <li><strong>HR Email: </strong>{item.hremail}</li>
                        <li><strong>Request from: </strong>{item.request_by}</li>                        
                        <div className='pendingProfileBtns'>
                            <button id='acceptPending' onClick={() => handleAccept(index)}>Accept</button>
                            <button id='rejectPending' onClick={() => handleReject(index)}>Reject</button>
                        </div>
                    </ul>
                ))}
                <p><strong>Pending Requests Count:</strong> {PendingProfiles ? PendingProfiles.length : 0}</p>
            </div>
        </div>
        }

        {/* Demo Profiles  */}
        {showDemo &&
        <div className='PendingProfiles'>
            <div className='PendingProfilesList'>
                {DemoProfiles && DemoProfiles.map((item, index) => (
                    <ul key={index}>
                        {/* <li>{index + 1}</li> */}
                        <li><strong>Name: </strong>{item.name}</li>
                        <li><strong>Email: </strong>{item.email}</li>
                        <li><strong>Job Role: </strong>{item.jobRole}</li>                        
                        <div className='pendingProfileBtns'>
                            <button id='viewPending' onClick={() => showDemoDetails(item._id)}>View</button>
                            <button id='acceptPending' onClick={() => handleDemoAccept(item._id)}>Accept</button>
                            <button id='rejectPending' onClick={() => handleDemoReject(item._id)}>Reject</button>
                        </div>
                        {/* <hr></hr> */}
                    </ul>
                ))}
                <p><strong>Demo Requests Count:</strong> {DemoProfiles ? DemoProfiles.length : 0}</p>
            </div>
        </div>
        }

        {viewDemoDetails &&
        <div className='orgDetails'>
            {demoOrgData &&
            <div className='orgDetailsContent'>
                <img src='/reject.png' alt='Logo' id='hideLogo' onClick={showDemoDetails}></img>
                <h2>Demo Request Details</h2>
                <hr></hr>
                <p><strong>Name: </strong>{demoOrgData.name}</p>
                <p><strong>Phone: </strong>{demoOrgData.phone}</p>
                <p><strong>Email: </strong>{demoOrgData.email}</p>
                <p><strong>Employee Size: </strong>{demoOrgData.employeeSize}</p>
                <p><strong>Job Function: </strong>{demoOrgData.jobFunction}</p>
                <p><strong>Job Role: </strong>{demoOrgData.jobRole}</p>
                <p><strong>Is Using ATS: </strong>{demoOrgData.isUsingATS ? "Yes" : "No"}</p>
                <p><strong>Is Recruitment Consultancy: </strong>{demoOrgData.isRecruitmentConsultancy ? "Yes" : "No"}</p>
                <p><strong>Message: </strong>{demoOrgData.message}</p>
            </div>
            }
        </div>
        }

        {/* Raised Tickets  */}
        {showTickets &&
        <div className='PendingProfiles'>
            <div className='PendingProfilesList'>
                {TicketsRaised && TicketsRaised.map((item, index) => (
                    <ul key={index}>
                        {/* <li>{index + 1}</li> */}
                        <li><strong>Title: </strong>{item.title}</li>
                        <li><strong>From: </strong>{item.orgadminName}</li>
                        <li><strong>Date: </strong>{item.createdDate}</li>                        
                        <div className='pendingProfileBtns'>
                            <button id='viewPending' onClick={() => showTicketDetails(index)}>View</button>
                        </div>
                        {/* <hr></hr> */}
                    </ul>
                ))}
                <p><strong>Tickets Count:</strong> {TicketsRaised ? TicketsRaised.length : 0}</p>
            </div>
        </div>
        }

        {viewTicket &&
        <div className='orgDetails'>
            {TicketDetails &&
            <div className='orgDetailsContent ticketDetails'>
                <img src='/reject.png' alt='Logo' id='hideLogo' onClick={hideTicket}></img>
                <h2>Ticket Details</h2>
                <hr></hr>
                <p><strong>Ticket Title: </strong>{TicketDetails.title}</p>
                <p><strong>Description: </strong><pre>{TicketDetails.description}</pre></p>
                <p><strong>Raised by Admin: </strong>{TicketDetails.orgadminName}</p>
                <p><strong>Admin Email: </strong>{TicketDetails.orgadminEmail}</p>
                <p><strong>Date: </strong>{TicketDetails.createdDate}</p>
                <label><strong>Add Remark:</strong></label>
                <textarea id='ticketRemark' value={ticketRemark} onChange={handleTicketRemarkChange} required></textarea>
                <button onClick={() => updateTicketStatus(TicketDetails._id)}>Update Status</button>
            </div>
            }
        </div>
        }

        <div className='ConvaiAdminPage'>
            <div className='ConvaiAdminSection1'>
                <div className='ConvaiAdminHeader'>
                    <div className='headerLogoAndWelcome'>
                        <h1>GenieHire AI</h1>
                    </div> 
                    <div className='headerIcons'>
                        <div className='superscriptNum forTickets'>{TicketsRaised ? TicketsRaised.length : 0}</div>
                        <div className='superscriptNum forPending'>{PendingProfiles ? PendingProfiles.length : 0}</div>
                        <div className='superscriptNum forDemo'>{DemoProfiles ? DemoProfiles.length : 0}</div>

                        <div className='PendingIcon'>
                        <img src='/ticket.png' alt='Logo' className='AdminHeaderICons' onClick={toggleShowTickets}></img>
                        <p>Tickets<br></br>Raised</p>
                        </div>

                        <div className='PendingIcon'>
                        <img src='/notification.png' alt='Logo' className='AdminHeaderICons' onClick={toggleShowDemo}></img>
                        <p>Demo<br></br>Requests</p>
                        </div>

                        <div className='PendingIcon'>
                        <img src='/pending.png' alt='Logo' className='AdminHeaderICons' onClick={toggleShowPending}></img>
                        <p>Pending<br></br>Requests</p>
                        </div>

                        <div className='PendingIcon'>
                        <img src='/logout_logo.png' alt='Logo' className='AdminHeaderICons' onClick={handleLogout}></img>
                        <p>Logout</p>
                        </div>
                    </div>
                </div>
                
                <div className='ConvaiAdminDetails'>
                    <div className='AdminDetails'>
                        <h2>{adminName}</h2>
                        <p>{adminEmail}</p>
                        <p>{adminContact}</p>
                        <p><strong>Admin</strong></p>
                        <button onClick={handleAddCompany}>Add Organization</button>
                    </div>
                    <div className='SmartCards'>
                        <div className='SmartCard Card1'>
                            <div className='RoundDisplay'><div className='Round2'>{data ? data.length : 0}</div></div>
                            <p>Active Organizations</p>
                        </div>
                        <div className='SmartCard Card2'>
                            <div className='RoundDisplay'><div className='Round2'>{TotalHRCount ? TotalHRCount : 0}</div></div>
                            <p>Active HRs</p>
                        </div>
                        <div className='SmartCard Card3'>
                            <div className='RoundDisplay'><div className='Round2'>{interviewCount}</div></div>
                            <p>Total Interviews Conducted</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='ConvaiAdminSection2'>
                <table>
                    <tr>
                        <th>Sr</th>
                        <th>Admin Name</th>
                        <th>Admin Contact</th>
                        <th>HRs Allocated</th>
                        <th>Action</th>
                    </tr>
                    {data && data.map((item, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td id='orgId' onClick={() => showOrgData(item.organization_id)}>{item.org_admin_name}</td>
                        <td>{item.org_admin_contact}</td>
                        <td>{item.total_hr_allocated}</td>
                        <td><img id='editimage' src='/edit.png' alt='Logo' onClick={() => toggleEditForm(item.organization_id)}></img> </td>
                    </tr>
                    ))}
                </table>
            </div>
        </div>
        </>
    );
};

export default GenieHireAdminDashboard;
import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

function InterviewResult({ questions, answers, scores, totalScore, remarks, audioUrls, adminId, interviewId, interviewStartDateTime }) {
  const [showDetails, setShowDetails] = useState(false);
  const dateObj = new Date(interviewStartDateTime);
  const [audioPlaying, setAudioPlaying] = useState(false);
  const [audioIndex, setAudioIndex] = useState(null);

  const date = dateObj.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).replace(/\//g, '-');
  
  const time = dateObj.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
  
  const hasData = questions.length > 0 && answers.length > 0 && scores.length > 0 && remarks.length > 0 && audioUrls.length > 0;

  if (!hasData) {
    return null;
  }

  const toggleDetails = () => {
    setShowDetails(prevState => !prevState);
  };

  const downloadPDF = () => {
    const url = `/interviewreport/${adminId}/${interviewId}`;
    window.open(url, '_blank');
  };

  const toggleAudio = (index) => {
    const audio = document.getElementById(`audio-${index}`);
  
    if (!audio) {
      console.error(`Audio element not found for index ${index}`);
      return;
    }
  
    if (!audio.src || audio.src === "") {
      console.error(`No supported sources found for audio element at index ${index}`);
      return;
    }

    const handlePlayError = (error) => {
      console.error(`Error playing audio: ${error}`);
      alert(`Failed to play audio. The file may be corrupt or unsupported.`);
      setAudioPlaying(false);
      setAudioIndex(null);
    };
  
    if (audioPlaying && audioIndex === index) {
      audio.pause();
      audio.currentTime = 0;
      setAudioPlaying(false);
      setAudioIndex(null);
    } else {
      if (audioPlaying && audioIndex !== null) {
        const currentAudio = document.getElementById(`audio-${audioIndex}`);
        currentAudio.pause();
        currentAudio.currentTime = 0;
      }
  
      audio.play().catch(handlePlayError);
  
      setAudioPlaying(true);
      setAudioIndex(index);
  
      audio.addEventListener('ended', () => {
        setAudioPlaying(false);
        setAudioIndex(null);
      });
    }
  };

  return (
    <div className="MCQTestResult">
      <div className='mcqResultHeader'>
        <h2>
          <span onClick={toggleDetails} className="dropdownArrow">
            {showDetails ? <FaChevronUp /> : <FaChevronDown />}
          </span>
          Interview Report
        </h2>
        <div className='scoreAndDownload'>
          <div id='totalscore'>
            Total Score : {totalScore}
            &emsp;
            {interviewStartDateTime ? <>Date: {date}</> : null}
              &emsp;
              {interviewStartDateTime ? <>Time: {time}</> : null}
          </div>
          <button onClick={downloadPDF} id='downloadMCQReportBtn'>Download Interview Report</button>
        </div>
      </div>

      {showDetails && (
        <div className="MCQuestionsList">
          {questions.map((question, index) => {
            const score = scores[index];
            const scoreClass = score >= 50 ? 'high-score' : 'low-score';
            return (
              <div className={`question-item ${scoreClass}`} key={index}>
                <div className="question-text">Question {index + 1}: <span className="value">{question}</span></div>
                <div className="answer-text">Candidate's Answer: <span className="value">{answers[index]}</span></div>
                <div className="score">Score: <span className="value">{score}</span></div>
                <div className="answer-text">Remark: <span className="value">{remarks[index]}</span></div>
                <button className="audio-button" onClick={() => toggleAudio(index)}>
                  {audioPlaying && audioIndex === index ? (
                    <img src='/stop_audio.png' alt="Stop Audio" style={{ width: '25px', height: '25px' }} />
                  ) : (
                    <img src='/play_audio.png' alt="Play Audio" style={{ width: '25px', height: '25px' }} />
                  )}
                </button>
                <audio id={`audio-${index}`} src={audioUrls[index]} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default InterviewResult;
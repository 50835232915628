import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../../css/CompanyAdmin.css';
import host from '../../global';
import { getCurrentUser } from 'aws-amplify/auth';
import Header from './header';
import Sidebar from './sidebar';
import { Authenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from 'aws-amplify/auth';

const CheckReport = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const {orgadminId} = useParams();
  const [filters, setFilters] = useState({
    hrnames: [],
    jobPositions: [],
    mcqStatuses: [],
    codingStatuses: [],
    interviewStatuses: []
  });
  const [allFilters, setAllFilters] = useState({
    hrnames: [],
    jobPositions: [],
    mcqStatuses: [],
    codingStatuses: [],
    interviewStatuses: []
  });
  const [candidates, setCandidates] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [areCandidatesLoading,setAreCandidatesLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
      const checkSession = async () => {
        try {
          const session = await fetchAuthSession();
          const { accessToken } = session.tokens ?? {};
          const groups = accessToken.payload['cognito:groups'] ?? [];
          const { userId } = await getCurrentUser();
          
          if (groups.includes('Company_Admin') && userId === orgadminId) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        } catch (error) {
          setIsAuthenticated(false);
        } finally {
          setIsLoading(false);
        }
      };
      
      checkSession();
      }, []);

  useEffect(() => {
    async function fetchCandidates() {
      try {
        setAreCandidatesLoading(true)
        const { userId } = await getCurrentUser();
        const response = await axios.get(`${host}/get_candidates/${userId}`);
        setCandidates(response.data);

        const hrnames = [...new Set(response.data.map(c => c.hrname))];
        const jobPositions = [...new Set(response.data.map(c => c.jdposition))];
        const mcqStatuses = [...new Set(response.data.map(c => c.mcq_status))];
        const codingStatuses = [...new Set(response.data.map(c => c.coding_status))];
        const interviewStatuses = [...new Set(response.data.map(c => c.interview_status))];

        setAllFilters({
          hrnames,
          jobPositions,
          mcqStatuses,
          codingStatuses,
          interviewStatuses
        });
        setAreCandidatesLoading(false)
      } catch (error) {
        console.error('Error fetching candidates:', error);
        if (error.message === 'UserUnAuthenticatedException') {
          navigate('/admin/login');
        }
      }
    }
    fetchCandidates();
  }, [navigate]);

  const handleFilterChange = (e) => {
    const { name, value, checked } = e.target;
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };
      if (checked) {
        newFilters[name] = [...newFilters[name], value];
      } else {
        newFilters[name] = newFilters[name].filter(item => item !== value);
      }
      return newFilters;
    });
  };

  const applyFilters = (candidate) => {
    return (
      candidate.candidate_name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (filters.hrnames.length === 0 || filters.hrnames.includes(candidate.hrname)) &&
      (filters.jobPositions.length === 0 || filters.jobPositions.includes(candidate.jdposition)) &&
      (filters.mcqStatuses.length === 0 || filters.mcqStatuses.includes(candidate.mcq_status)) &&
      (filters.codingStatuses.length === 0 || filters.codingStatuses.includes(candidate.coding_status)) &&
      (filters.interviewStatuses.length === 0 || filters.interviewStatuses.includes(candidate.interview_status))
    );
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); 
  const paginatedCandidates = candidates
  .filter(applyFilters)
  .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = Math.ceil(candidates.filter(applyFilters).length / itemsPerPage);
  
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };
  
  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleViewCandidate = (interviewId, hrId) => {
    navigate(`/org-admin/evaluationpage/${hrId}/${interviewId}`);
  };

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handleApplyFilters = () => {
    setIsPopupOpen(false);
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    setSearchQuery('');
    setFilters({
      hrnames: [],
      jobPositions: [],
      mcqStatuses: [],
      codingStatuses: [],
      interviewStatuses: []
    });
  };  

  if (isLoading) {
    return <div className="loader"></div>;
  }

  if (!isAuthenticated) {
    return (
      <div className="AuthenticationMessageContainer">
        <div className="AuthenticationMessage">
      <p>
        You are not Authorized to access this Page!
      </p>
      <button onClick={() => navigate('/admin/login')}> Login Page</button>
      </div>
      </div>
    );
  }

  return (
    <Authenticator>
      <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} orgadminId={orgadminId}/>
      <div className="company-admin-container">
        <Sidebar isSidebarOpen={isSidebarOpen} orgadminId={orgadminId}/>
        <div className={`content ${isSidebarOpen ? 'sidebarIsOpen' : ''}`}>
        { areCandidatesLoading ? (
            <div className="loader"></div>
          ):(
            <>
          <h1>Check Report</h1>
          <div className="search-container" style={{marginTop:'20px'}}>
            <input
              type="text"
              placeholder="Search by candidate name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button className="filter-button" onClick={handlePopupOpen}>Filter</button>
          </div>

          {isPopupOpen && (
            <div className="filter-popup">
              <div className="filter-popup-content">
                <span className="close" onClick={handlePopupClose}>&times;</span>
                <div className="filter-group">
                  <h3>Filter by HR Name</h3>
                  {allFilters.hrnames.map(hrname => (
                    <label key={hrname}>
                      {hrname}
                      <input
                        type="checkbox"
                        name="hrnames"
                        value={hrname}
                        checked={filters.hrnames.includes(hrname)}
                        onChange={handleFilterChange}
                      />
                    </label>
                  ))}
                </div>
                <div className="filter-group">
                  <h3>Filter by Job Position</h3>
                  {allFilters.jobPositions.map(position => (
                    <label key={position}>
                      {position}
                      <input
                        type="checkbox"
                        name="jobPositions"
                        value={position}
                        checked={filters.jobPositions.includes(position)}
                        onChange={handleFilterChange}
                      />
                    </label>
                  ))}
                </div>
                <div className="filter-group">
                  <h3>Filter by MCQ Status</h3>
                  {allFilters.mcqStatuses.map(status => (
                    <label key={status}>
                      {status}
                      <input
                        type="checkbox"
                        name="mcqStatuses"
                        value={status}
                        checked={filters.mcqStatuses.includes(status)}
                        onChange={handleFilterChange}
                      />
                    </label>
                  ))}
                </div>
                <div className="filter-group">
                  <h3>Filter by Coding Status</h3>
                  {allFilters.codingStatuses.map(status => (
                    <label key={status}>
                      {status}
                      <input
                        type="checkbox"
                        name="codingStatuses"
                        value={status}
                        checked={filters.codingStatuses.includes(status)}
                        onChange={handleFilterChange}
                      />
                    </label>
                  ))}
                </div>
                <div className="filter-group">
                  <h3>Filter by Interview Status</h3>
                  {allFilters.interviewStatuses.map(status => (
                    <label key={status}>
                      {status}
                      <input
                        type="checkbox"
                        name="interviewStatuses"
                        value={status}
                        checked={filters.interviewStatuses.includes(status)}
                        onChange={handleFilterChange}
                      />
                    </label>
                  ))}
                </div>
                <div className='filterBtns'>
                  <button className="reset-filters-button" onClick={handleResetFilters}>Reset</button>
                  <button className="apply-filters-button" onClick={handleApplyFilters}>Apply</button>
                </div>
              </div>
            </div>
          )}
          <div className="candidate-list">
            {paginatedCandidates.map((candidate) => (
              <div key={candidate.interview_id} className="candidate-card">
                <div className="candidate-header">
                  <h3>{candidate.candidate_name}</h3>
                  {(candidate.mcq_status === 'Complete' || candidate.mcq_status === 'Passed' ||
                    candidate.coding_status === 'Passed' || candidate.coding_status === 'Complete' ||
                    candidate.interview_status === 'Complete' || candidate.interview_status === 'Passed') && (
                    <button
                      className="view-button"
                      onClick={() => handleViewCandidate(candidate.interview_id, candidate.hr_id)}
                    >
                      View Report
                    </button>
                  )}
                </div>
                <div className="candidate-details">
                  <p><strong>Job Position:</strong> {candidate.jdposition}</p>
                  <p><strong>HR:</strong> {candidate.hrname}</p>
                  {(candidate.mcq_status != "NA") && <p><strong>MCQ Status:</strong> {candidate.mcq_status}</p>}
                  {(candidate.coding_status != "NA") && <p><span><strong>Coding Status:</strong> {candidate.coding_status}</span></p>}
                  <p><strong>Interview Status:</strong> {candidate.interview_status}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="pagination-controls">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>Previous</button>
            <span>Page {currentPage} of {totalPages}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
          </div>
          </>
          )}
        </div>
      </div>
    </Authenticator>
  );
};

export default CheckReport;
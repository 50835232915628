import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../css/TimeFrameSelector.css';

const TimeFrameSelector = ({ onDateRangeChange }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [highlightedRange, setHighlightedRange] = useState([]);

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    setSelectedStartDate(today);
    setHighlightedRange([today, null]);
  }, []);

  const handleDateChange = (date) => {
    if (Array.isArray(date)) {
      const [start, end] = date;
      if (start && end && start <= end) {
        setSelectedStartDate(start);
        setSelectedEndDate(end);
        setHighlightedRange([start, end]);
      } else if (start && !end) {
        setSelectedStartDate(start);
        setHighlightedRange([start, null]);
      }
    }
  };

  const handleApply = () => {
    if (selectedStartDate && selectedEndDate) {
      onDateRangeChange({ startDate: selectedStartDate, endDate: selectedEndDate });
      setShowCalendar(false);
      console.log(selectedStartDate , selectedEndDate)
    }
  };

  const handleCancel = () => {
    resetCalendar();
  };

  const resetCalendar = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    setSelectedStartDate(today);
    setSelectedEndDate(null);
    setHighlightedRange([today, null]);
    setShowCalendar(false);
  };

  const formatDate = (date) => date ? date.toDateString() : '';

  return (
    <div className="time-frame-selector">
      <button
        className="toggle-button"
        onClick={() => setShowCalendar(!showCalendar)}
        aria-expanded={showCalendar}
        aria-controls="calendar-container"
        aria-label={showCalendar ? 'Close Calendar' : 'Open Calendar'}
      >
        {selectedStartDate && selectedEndDate
          ? `Time Frame: ${formatDate(selectedStartDate)} to ${formatDate(selectedEndDate)}`
          : 'Select Time Frame For Test'}
      </button>

      {showCalendar && (
        <div id="calendar-container" className="calendar-container">
          <Calendar
            selectRange
            onChange={handleDateChange}
            value={[selectedStartDate, selectedEndDate]}
            tileClassName={({ date }) => {
              if (highlightedRange.length === 2) {
                const [start, end] = highlightedRange;
                if (date >= start && date <= end && date.toDateString() !== new Date().toDateString()) {
                  return 'highlighted';
                }
              }
              return null;
            }}
          />
          <div className="calendar-buttons">
            {selectedStartDate && selectedEndDate && (
              <button onClick={handleApply} className="apply-button">Apply</button>
            )}
            <button onClick={handleCancel} className="cancel-button">Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeFrameSelector;
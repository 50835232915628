import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../../css/evaluationPage.css';
import host from '../../global';

const InterviewReport = () => {

  const { admin_id, interview_id } = useParams();
  const [candidateName, setCandidateName] = useState('');
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [scores, setScores] = useState([]);
  const [remarks, setRemark] = useState([]);
  const [interviewStartDateTime, setInterviewStartDateTime] = useState([]);
  const [finalRemark, setFinalRemark] = useState('');
  const [totalScore, setTotalScore] = useState(0);
  const [contact, setContact] = useState(0);
  const [numberOfQuestion, setNumberOfQuestion] = useState(0);
  const [percentInterviewScore, setPercentInterviewScore] = useState(0);

  useEffect(() => {
    const getNumberOfQuestions = async () => {
      try {
        const response = await axios.post(`${host}/numberofquestions/${interview_id}`);
        setNumberOfQuestion(response.data);
      } catch (error) {
        console.error('Error:', error.message);
      }
    };

    const getcandidatedetails = async () => {
      try {
        const response = await axios.get(`${host}/getcandidatedetails/${interview_id}`);
        setCandidateName(response.data.candidate_name);
        setContact(response.data.contact);
      } catch (error) {
        console.error('Error:', error.message);
      }
    };
    getcandidatedetails();
    getNumberOfQuestions();
  }, [interview_id]);

  useEffect(() => {
    const fetchEvaluationResults = async () => {
      try {
        const response = await axios.get(`${host}/evaluation/${interview_id}`);
        const { questions, answers, scores, remarks, audio_urls, startDateTime } = response.data;
        console.log(scores);
        
        const filteredScores = scores
          .map(score => parseFloat(score))
          .filter(score => !isNaN(score));

        setQuestions(questions);
        setAnswers(answers);
        setScores(filteredScores);
        setRemark(remarks);
        setInterviewStartDateTime(startDateTime);

        const sumOfScores = filteredScores.reduce((acc, score) => acc + score, 0);
        setTotalScore(sumOfScores);

        const averageScore = filteredScores.length > 0 ? sumOfScores / filteredScores.length : 0;
        setPercentInterviewScore(parseInt(averageScore));
      } catch (error) {
        console.error('Error fetching evaluation results:', error);
      }
    };
    fetchEvaluationResults();
  }, [admin_id, interview_id, numberOfQuestion]);

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 2500);
  }, []);

  return (
    <div className="reportPage">
    <div id='reportContent'>
      <header className="title">Interview Report</header>
      <div className='candidate-details'>
        <p><strong>Name: {candidateName}</strong></p>
        <p><strong>Contact: {contact}</strong></p>
        <p><strong>{interviewStartDateTime}</strong></p>
        <p><strong>Total Score: {percentInterviewScore}%</strong></p>
      </div>

      <div className='InterviewReportData'>
        {questions.map((question, index) => {
          const score = scores[index];
          return (
            <div key={index} className="InterviewReportQuestion">
              <p><strong>Question {index + 1}:</strong> {question}</p>
              <p><strong>Candidate's Answer:</strong> {answers[index]}</p>
              <p><strong>Score:</strong> {score}</p>
              <p><strong>Remark:</strong> {remarks[index]}</p>
            </div>
          );
        })}
      </div>

    </div>
    </div>
  );
}

export default InterviewReport;
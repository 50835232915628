import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../css/CompanyAdmin.css';
// import '../css/checkResume.css';
import host from '../../global';
import { useNavigate, useParams } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import HeaderBar from './header';
import Sidebar from './sidebar';
import { getCurrentUser } from '@aws-amplify/auth';
import CustomAlert from '../customalter';
import { fetchUserAttributes } from 'aws-amplify/auth';
import TimeFrameSelector from './TimeFrameSelector'; // Import TimeFrameSelector
import { fetchAuthSession } from 'aws-amplify/auth';

const CheckResumeOrgAdmin = () => {
  const [jobPositions, setJobPositions] = useState([]);
  const [selectedJobPosition, setSelectedJobPosition] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [resume, setResume] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [responseReport, setResponseReport] = useState('');
  const [responseScore, setResponseScore] = useState(0);
  const [interviewID, setInterviewID] = useState('');
  const [resumeName, setResumeName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailID, setEmailID] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isConfirmClicked, setIsConfirmClicked] = useState(false);
  const [resumeContent, setResumeContent] = useState('');
  const [isInterviewIDVisible, setIsInterviewIDVisible] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [selectedInterviewType, setSelectedInterviewType] = useState('');
  const [numberOfQuestion, setNumberOfQuestion] = useState(0);
  const [selectedSet, setSelectedSet] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for Sidebar
  const [codingJudgingCriteria, setCodingJudgingCriteria] = useState([]);
  const [selectedCodingSet, setSelectedCodingSet] = useState('');
  const [timeFrame, setTimeFrame] = useState({ startDate: new Date(), endDate: new Date(new Date().setDate(new Date().getDate() + 7)) });
  const [MCQTimeLimit, setMCQTimeLimit] = useState(0);
  const [CodingTimeLimit, setCodingTimeLimit] = useState(0);
  const [hrEmail, setHrEmail] = useState('');
  const navigate = useNavigate();
  const { hr_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const session = await fetchAuthSession();
        const { accessToken } = session.tokens ?? {};
        const groups = accessToken.payload['cognito:groups'] ?? [];
        const { userId } = await getCurrentUser();

        if (groups.includes('HR') && userId === hr_id) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkSession();
  }, []);

  useEffect(() => {
    const getUserAttributes = async () => {
      const attributes = await fetchUserAttributes();
      setHrEmail(attributes['email']);
      console.log(hrEmail);
    };
    const fetchJobPositions = async () => {
      try {
        const response = await axios.get(`${host}/getjdpositions/${hr_id}`);
        setJobPositions(response.data);
      } catch (error) {
        console.error('Error fetching job positions:', error);
      }
    };
    getUserAttributes();
    fetchJobPositions();
  }, [hr_id, hrEmail]);

  useEffect(() => {
    async function checkAuth() {
      try {
        await getCurrentUser();
      } catch (error) {
        navigate('/admin/login');
      }
    }
    checkAuth();
  }, [navigate]);

  const handleNotify = () => {
    setIsVisible(!isVisible);
  };

  const handleEmailChange = (e) => {
    setEmailID(e.target.value);
  };

  const handleJobPositionChange = async (e) => {
    const selectedPosition = e.target.value;
    setSelectedJobPosition(selectedPosition);
    setJobDescription('');
    setErrorMessage('');
    setResponseReport('');
    setResponseScore(0);
    setInterviewID('');
    setIsSubmitting(false);
    setEmailID('');
    setIsVisible(false);
    setIsCheckboxChecked(false);
    setIsConfirmClicked(false);
    setResumeContent('');
    setIsInterviewIDVisible(false);
    try {
      const response = await axios.get(`${host}/${hr_id}/getjd?job_position=${selectedPosition}`);
      if (response) {
        setJobDescription(response.data.description);
        setCodingTimeLimit(response.data.coding_time_limit);
        setMCQTimeLimit(response.data.mcq_time_limit);
        setNumberOfQuestion(response.data.number_of_questions);
        setSelectedInterviewType(response.data.interview_type);
        setSelectedCodingSet(response.data.Coding_set);
        setSelectedSet(response.data.mcq_set);
        setCodingJudgingCriteria(response.data.Coding_Judge_Crieteria);
      }
    } catch (error) {
      console.error('Error fetching job description:', error);
      setJobDescription('');
    }
  };

  const handleResumeChange = (e) => {
    const file = e.target.files[0];
    if (file && !['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.type)) {
      setErrorMessage('Only PDF or document files are allowed.');
    } else {
      setErrorMessage('');
      setResume(file);
      setResumeName(file.name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedJobPosition || !jobDescription) {
      setErrorMessage('Please select a job position and load the job description.');
      return;
    }

    if (!resume) {
      setErrorMessage('Please upload a resume.');
      return;
    }

    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('job_role', selectedJobPosition);
    formData.append('job_description', jobDescription);
    formData.append('file', resume);

    try {
      const response = await axios.post(`${host}/upload/${hr_id}/${selectedInterviewType}/${numberOfQuestion}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setResponseReport(response.data.report);
      setResponseScore(response.data.score);
      if (response.data.score >= 70) {
        setIsInterviewIDVisible(true);
      }
      setInterviewID(response.data.int_id);
      setResumeContent(response.data.resume_content);
    } catch (error) {
      console.error('Error uploading resume:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleConfirm = async () => {
    setIsConfirmClicked(true);

    const formData = new FormData();
    formData.append('job_role', selectedJobPosition);
    formData.append('job_description', jobDescription);
    formData.append('hr_id', hr_id);
    formData.append('resume_content', resumeContent);
    formData.append('score', responseScore);
    formData.append('interview_type', selectedInterviewType);
    formData.append('number_of_questions', numberOfQuestion);

    try {
      const response = await axios.post(`${host}/storedata`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setInterviewID(response.data.int_id);
      setIsInterviewIDVisible(true);
      setIsCheckboxChecked(true);
    } catch (error) {
      console.error('Error storing data in MongoDB:', error);
    }
  };

  const sendNotification = async (e) => {
    e.preventDefault();

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailID) {
      setAlertMessage("Please enter an email ID");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 2000);
      return;
    }
    if (!emailPattern.test(emailID)) {
      setAlertMessage("Please enter a valid email ID");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 2000);
      return;
    }

    try {
      await axios.post(`${host}/addemails/${interviewID}/${hrEmail}/${emailID}/${selectedSet}/${codingJudgingCriteria}/${selectedCodingSet}/${MCQTimeLimit}/${CodingTimeLimit}/${timeFrame.startDate.toISOString()}/${timeFrame.endDate.toISOString()}`);

      await axios.post(`${host}/notifycandidate/${emailID}/${interviewID}`);

      setAlertMessage("Notification sent to candidate");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
      }, 2000);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
    if (!e.target.checked) {
      setIsVisible(false);
    }
  };

  const handleTimeFrameChange = (timeFrame) => {
    setTimeFrame(timeFrame);
  };

  if (isLoading) {
    return <div className="loader"></div>;
  }

  if (!isAuthenticated) {
    return (
      <div className="AuthenticationMessageContainer">
        <div className="AuthenticationMessage">
          <p>
            You are not Authorized to access this Page!
          </p>
          <button onClick={() => navigate('/admin/login')}> Login Page</button>
        </div>
      </div>
    );
  }

  return (
    <Authenticator>
      <HeaderBar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} hr_id={hr_id} />
      <div className="company-admin-container">
        <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} hr_id={hr_id} />
        <div className={`content ${isSidebarOpen ? 'sidebarIsOpen' : ''}`}>

          {alertVisible && <CustomAlert message={alertMessage} />}
          <div className="maincontent">
            <div className={`${!responseReport ? 'showFull' : 'showHalf'}`}>
              <form onSubmit={handleSubmit}>
                <select value={selectedJobPosition} onChange={handleJobPositionChange}>
                  <option value="">Select Job Position</option>
                  {jobPositions.map((position, index) => (
                    <option key={index} value={position}>{position}</option>
                  ))}
                </select>

                <div id='textareaDiv'>
                  <textarea
                    placeholder="Job Description"
                    value={jobDescription}
                    onChange={(e) => setJobDescription(e.target.value)}
                  />
                </div>

                <div className="file-control">
                  <label className="file-upload-button">
                    <span>Upload Resume</span>
                    <input type="file" accept=".pdf,.doc,.docx" onChange={handleResumeChange} />
                  </label>
                  {resumeName && <p>{resumeName}</p>}
                </div>

                <div className="button-container">
                  {!isSubmitting ? (
                    <button id="submitBtnAdmin" type="submit">Submit</button>
                  ) : (
                    <div className="loading-animation">Loading...</div>
                  )}
                </div>
                {errorMessage && <p className="error">{errorMessage}</p>}
              </form>
            </div>

            {responseReport && (
              <div className="response-report">
                <h2>Summary:</h2>
                <p>{responseReport}</p>
                <p>Score: {responseScore}</p>
                {(responseScore >= 70 || isInterviewIDVisible) && (
                  <p>Interview ID: {interviewID}</p>
                )}
                {responseScore < 70 && (
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      checked={isCheckboxChecked}
                      onChange={handleCheckboxChange}
                      disabled={isConfirmClicked} // Disable checkbox if confirm button is clicked
                    />
                    <label id='checkboxLabel'>
                      The Candidate's Resume is not fit for the Job Role, Would you still like to interview this candidate?
                    </label>
                  </div>
                )}
                {(responseScore >= 70 || isCheckboxChecked) && (
                  <>
                    {isConfirmClicked || responseScore >= 70 ? (
                      <TimeFrameSelector
                        onDateRangeChange={handleTimeFrameChange}
                      />
                    ) : null}
                    <div className='notify'>
                      {responseScore < 70 && !isConfirmClicked ? (
                        <div id='notifyCandidateBtn' onClick={handleConfirm}>Confirm</div>
                      ) : (
                        <div>
                          <div id='notifyCandidateBtn' onClick={handleNotify}>Notify Candidate</div>
                          {isVisible && (
                            <form id='notifyForm' onSubmit={sendNotification}>
                              <input
                                value={emailID}
                                type='email'
                                placeholder='Enter Email'
                                onChange={handleEmailChange}
                              />
                              <button type="submit">Send</button>
                            </form>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Authenticator>
  );
};

export default CheckResumeOrgAdmin;
import React from 'react';

const CustomALert = (props) => {
  return (
    <div>
        <div className='alertPage'>
            <div className='alertMsg'>
                <div>{props.message}</div>
            </div>
        </div>
    </div>
  );
};

export default CustomALert;
import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

function MCQResult({ MCQTestResultData, adminId, interviewId }) {
  const [showDetails, setShowDetails] = useState(false);

  const downloadPDF = () => {
    const url = `/mcqreport/${adminId}/${interviewId}`;
    window.open(url, '_blank');
  };

  const toggleDetails = () => {
    setShowDetails(prevState => !prevState);
  };
  const dateObj = new Date(MCQTestResultData.startDateTime);

  const date = dateObj.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).replace(/\//g, '-');

  const time = dateObj.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });

  return (
    <div className="MCQTestResult">
      {MCQTestResultData && (
        <div className="mcqResultHeader">
          <h2>
            <span onClick={toggleDetails} className="dropdownArrow">
              {showDetails ? <FaChevronUp /> : <FaChevronDown />}
            </span>
            MCQ Test Report
          </h2>
          <div className="scoreAndDownload">
            <div id="totalscore">
            Total Score: {MCQTestResultData.total_score}
            &emsp;
              {MCQTestResultData.time_taken ? <>Time Taken: {MCQTestResultData.time_taken}</> : null}
              &emsp;
              {MCQTestResultData.startDateTime ? <>Date: {date}</> : null}
              &emsp;
              {MCQTestResultData.startDateTime ? <>Time: {time}</> : null}
            </div>
            <button onClick={downloadPDF} id="downloadMCQReportBtn">Download MCQ Report</button>
          </div>
        </div>
      )}

      {showDetails && (
        <div className="MCQuestionsList">
          {MCQTestResultData ? (
            Object.keys(MCQTestResultData.data).map((questionId) => {
              const score = MCQTestResultData.data[questionId].score;
              const scoreClass = score === 1 ? 'correct' : 'incorrect';
              return (
                <div key={questionId} className={`MCQResultquestions ${scoreClass}`}>
                  <p>
                    <span style={{ fontWeight: 'bold' }}>Question: {Number(questionId) + 1}:</span>{' '}
                    <pre id="mcqOnReport">{MCQTestResultData.data[questionId].question}</pre>
                  </p>
                  <ul>
                    <li>{MCQTestResultData.data[questionId].option1}</li>
                    <li>{MCQTestResultData.data[questionId].option2}</li>
                    <li>{MCQTestResultData.data[questionId].option3}</li>
                    <li>{MCQTestResultData.data[questionId].option4}</li>
                  </ul>
                  <p>
                    <span style={{ fontWeight: 'bold' }}>Correct Answer:</span>{' '}
                    {MCQTestResultData.data[questionId].answer}
                  </p>
                  <p>
                    <span style={{ fontWeight: 'bold' }}>Selected Answer:</span>{' '}
                    {MCQTestResultData.data[questionId].given_answer}
                  </p>
                  <p>
                    <span style={{ fontWeight: 'bold' }}>Score:</span>{' '}
                    {MCQTestResultData.data[questionId].score}
                  </p>
                </div>
              );
            })
          ) : (
            <p>...</p>
          )}
        </div>
      )}
    </div>
  );
}

export default MCQResult;